import { useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import useLocalStorageState from 'use-local-storage-state';

// :: Context
import AppContext from '../../contexts/AppContext';

const Logout = () => {
  const navigate = useNavigate();
  const [, , { removeItem }] = useLocalStorageState('cms.user');
  const { updateAppContext } = useContext(AppContext);

  const handleRemoveSpaceContext = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      space: undefined,
      spaceSlug: undefined,
    }));
  }, [updateAppContext]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'logout',
      },
    });

    removeItem();
    handleRemoveSpaceContext();
    window.dataLayer.push(function () {
      this.reset();
    });

    navigate('/login');
    if (FlotiqPlugins.getLoadedPluginsIds().length) window.location.reload();
  }, [navigate, handleRemoveSpaceContext, removeItem]);

  return <></>;
};

export default Logout;
