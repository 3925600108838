import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// :: Components
import UserRolesForm from '../../../form/UserRolesForm/UserRolesForm';
import Loader from '../../../components/Loader/Loader';

// :: Lib
import { getTestProps } from '../../../lib/helpers';
import { getUser, getUserRoles } from '../../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../../lib/flotiq-client/response-errors';

// :: Hooks
import useToken from '../../../hooks/useToken';
import useOnce from '../../../hooks/useOnce';

const ROLES_PARAMS = {
  order_by: 'name',
  order_direction: 'asc',
};

const UserRolesItem = ({
  spaceId,
  spaceName,
  userId,
  filterUserRoles,
  handleRolesUpdate,
  testId,
}) => {
  const token = useToken();
  const { t } = useTranslation();
  const [user, setUser] = useState();
  const [userRoles, setUserRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getUserData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { body, status } = await getUser(token, undefined, {
        id: userId,
        space: spaceId,
      });

      checkResponseStatus(body, status);
      setUser(body);
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(t('UserForm.FetchError'));
      }
    }
    setIsLoading(false);
  }, [t, token, spaceId, userId]);

  useOnce(getUserData);

  const getUserRolesData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { body, status } = await getUserRoles(token, undefined, {
        ...ROLES_PARAMS,
        space: spaceId,
      });
      checkResponseStatus(body, status);
      setUserRoles(body.data);
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(t('UserRoles.CouldntFetch'));
      }
    }
    setIsLoading(false);
  }, [t, token, spaceId]);

  useOnce(getUserRolesData);

  return (
    <>
      {isLoading && (
        <div
          className={twMerge(
            'flex justify-center items-center',
            'rounded-lg py-2 dark:bg-slate-950 bg-white',
          )}
          {...getTestProps(testId, 'loader')}
        >
          <Loader size="tiny" type="spinner-grid" />
        </div>
      )}

      {!isLoading && user?.headlessRoles && userRoles?.length > 0 && (
        <UserRolesForm
          roles={user?.headlessRoles?.map((role) => role.id)}
          onSubmit={(roles) => handleRolesUpdate(roles, spaceId, user)}
          rolesOptions={userRoles?.map((role) => ({
            value: role.id,
            label: role.name,
          }))}
          filterRoles={(query) => filterUserRoles(query, spaceId)}
          spaceName={spaceName}
          additionalClasses="py-5 rounded-lg bg-white dark:bg-slate-950 h-fit px-4"
          {...getTestProps(testId, 'roles-form', 'testId')}
        />
      )}
    </>
  );
};

export default UserRolesItem;

UserRolesItem.propTypes = {
  /**
   * User roles test id
   */
  testId: PropTypes.string,
  /**
   * User roles space id
   */
  spaceId: PropTypes.string,
  /**
   * User roles space name
   */
  spaceName: PropTypes.string,
  /**
   * User roles user id
   */
  userId: PropTypes.string,
  /**
   * User roles filter callback
   */
  filterUserRoles: PropTypes.func,
  /**
   * User roles on submit handler
   */
  handleRolesUpdate: PropTypes.func,
};

UserRolesItem.defaultProps = {
  spaceId: '',
  spaceName: '',
  userId: '',
  filterUserRoles: undefined,
  handleRolesUpdate: undefined,
  testId: '',
};
