import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { Table, Checkbox } from 'rsuite';
import { twMerge } from 'tailwind-merge';
import { deepReadKeyValue, getTestProps } from '../../lib/helpers';

// :: Components
import HeaderFilter from './HeaderFilter';

// :: Icons
import { CloseIcon } from '../../images/shapes';

const { Column, HeaderCell, Cell } = Table;

const DraggableHeaderCell = ({
  children,
  onDrop,
  accessor,
  notDraggable,
  testId,
  onDraggingStateChange,
  hasFilters,
  filterInputType,
  ...rest
}) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'column',
    canDrop: () => !notDraggable,
    hover: (item, monitor) => {
      if (monitor.canDrop() && !monitor.didDrop() && !monitor.isOver()) {
        onDrop(item.accessor, accessor);
      }
    },
  });

  const [{ labelIsDragging, columnIsDragging }, drag] = useDrag({
    item: { accessor },
    type: 'column',
    canDrag: () => !notDraggable,
    collect: (monitor) => ({
      labelIsDragging: monitor.isDragging(),
      columnIsDragging: monitor.getItem()?.accessor === accessor,
    }),
  });

  drag(drop(ref));

  useEffect(() => {
    onDraggingStateChange &&
      onDraggingStateChange({ accessor, isDragging: columnIsDragging });
  }, [accessor, columnIsDragging, onDraggingStateChange]);

  return (
    <HeaderCell
      className={twMerge(
        'font-semibold text-indigo-950 dark:text-white text-sm lg:text-base h-full',
        hasFilters && 'include-filters',
      )}
      {...rest}
    >
      <div
        className={twMerge(
          'flex h-full items-center w-full p-2',
          onDrop && !notDraggable && 'cursor-grab',
          labelIsDragging && '!cursor-move',
          onDrop && columnIsDragging && 'rs-dragged',
        )}
        ref={ref}
        {...getTestProps(testId, `draggable-${accessor}`)}
      >
        {children}
      </div>
    </HeaderCell>
  );
};

const DroppableCell = ({ onDrop, accessor, notDraggable, ...rest }) => {
  const [, drop] = useDrop({
    accept: 'column',
    canDrop: () => !notDraggable,
    hover: (item, monitor) => {
      if (monitor.canDrop() && !monitor.didDrop() && !monitor.isOver()) {
        onDrop(item.accessor, accessor);
      }
    },
  });

  return <Cell ref={drop} {...rest} />;
};

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props}>
    <div className="flex items-center h-full w-full py-2 select-none	">
      <Checkbox
        value={rowData?.[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some((item) => item === rowData?.[dataKey])}
      />
    </div>
  </Cell>
);

const DataGrid = ({
  columns,
  hasSelectColumn,
  isLoading,
  data,
  additionalClasses,
  onSortColumn,
  sort,
  sortOrder,
  checkedRows,
  onCheckRows,
  tableHeight,
  autoHeight,
  fillHeight,
  rowHeight,
  showHeader,
  headerHeight,
  statusBar,
  noDataInfoText,
  loadingDataInfoText,
  loadingIcon,
  onDrop,
  testId,
  onResize,
  hasFilters,
  disableFilters,
  onFilter,
  removableColumns,
  sortableColumns,
  onRemoveColumn,
  filters,
  contentType,
}) => {
  const [gridLoading, setGridLoading] = useState(isLoading);
  const [extraOptions, setExtraOptions] = useState([]);

  useEffect(() => {
    if (!isLoading) setTimeout(() => setGridLoading(isLoading), 50);
    else setGridLoading(isLoading);
  }, [isLoading]);

  const [checkedKeys, setCheckedKeys] = useState(checkedRows || []);

  useEffect(
    () => Array.isArray(checkedRows) && setCheckedKeys(checkedRows),
    [checkedRows],
  );

  const [draggedCol, setDraggedCol] = useReducer(
    (oldCol, { accessor, isDragging }) => {
      if (!isDragging && oldCol === accessor) return null;
      if (isDragging) return accessor;

      return oldCol;
    },
  );

  /**
   * Find common keys between data ids and checked keys
   */
  const dataCheckedKeys = useMemo(() => {
    const checkedDictionary = checkedKeys.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
    return data.map((row) => row.id).filter((id) => checkedDictionary[id]);
  }, [data, checkedKeys]);

  const checked = useMemo(
    () => dataCheckedKeys.length === data.length,
    [data, dataCheckedKeys],
  );

  const indeterminate = useMemo(
    () => dataCheckedKeys.length > 0 && dataCheckedKeys.length < data.length,
    [data, dataCheckedKeys],
  );

  const handleMultiSelect = useCallback(
    (value) => {
      const lasKey = checkedKeys[checkedKeys.length - 1];
      let keys = [];

      if (!lasKey) {
        keys = [...checkedKeys, value];
      } else {
        const indexes = [];

        data.forEach((element, key) => {
          [lasKey, value].includes(element.id) && indexes.push(key);
        });

        keys = [
          ...checkedKeys,
          ...data
            .slice(indexes[0], indexes[1] + 1)
            .map((element) => element.id),
        ];
      }
      keys = [...new Set(keys)];
      setCheckedKeys(keys);
      onCheckRows && onCheckRows(keys);
    },
    [checkedKeys, data, onCheckRows],
  );

  const handleCheckAll = useCallback(
    (_value, isChecked) => {
      const keys = isChecked
        ? [...new Set([...checkedKeys, ...data.map((item) => item.id)])]
        : checkedKeys.filter(
            (checked) => data.findIndex((item) => item.id === checked) < 0,
          );
      setCheckedKeys(keys);
      onCheckRows && onCheckRows(keys);
    },
    [data, onCheckRows, checkedKeys],
  );

  const handleCheck = useCallback(
    (value, isChecked, event) => {
      if (isChecked && event.nativeEvent.shiftKey) {
        handleMultiSelect(value);
        return;
      }

      const keys = isChecked
        ? [...checkedKeys, value]
        : checkedKeys.filter((item) => item !== value);
      setCheckedKeys(keys);
      onCheckRows && onCheckRows(keys);
    },
    [checkedKeys, handleMultiSelect, onCheckRows],
  );

  const [currentSort, setCurrentSort] = useState(sort);
  const [currentOrder, setCurrentOrder] = useState(sortOrder);
  const [currentFilters, setCurrentFilters] = useState(filters);

  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);

  const handleSortColumn = useCallback(
    (column, type) => {
      let newSortType = type;
      let newSortColumn = column;

      if (currentSort === column && type === 'asc')
        newSortType = newSortColumn = null;
      else if (newSortColumn && !newSortType) {
        newSortType = 'asc';
      }

      setCurrentSort(newSortColumn);
      setCurrentOrder(newSortType);
      onSortColumn && onSortColumn(newSortColumn, newSortType);
    },
    [onSortColumn, currentSort],
  );

  useEffect(() => {
    setCurrentSort(sort);
    setCurrentOrder(sortOrder);
  }, [sort, sortOrder]);

  const handleFilters = useCallback(
    (id, value, filterType, label) => {
      setCurrentFilters((prevState) => {
        let updatedFilter = { ...prevState };

        const isArray = Array.isArray(value);
        if ((isArray && value.length > 0) || (!isArray && value)) {
          updatedFilter[id] = { type: filterType, value };
        } else {
          delete updatedFilter[id];
        }

        if (label) {
          setExtraOptions([{ value, label }]);
        }

        onFilter?.(updatedFilter);
        return updatedFilter;
      });
    },
    [onFilter],
  );

  return (
    <>
      <Table
        className={twMerge(
          'w-full !overflow-visible font-semibold',
          'text-indigo-950 dark:text-gray-200 border border-gray dark:border-slate-800 rounded-t-lg !overflow-hidden',
          statusBar ? 'rounded-b-none' : 'rounded-b-lg',
          additionalClasses,
        )}
        showHeader={showHeader}
        height={tableHeight}
        fillHeight={fillHeight}
        autoHeight={autoHeight}
        rowHeight={rowHeight}
        headerHeight={hasFilters ? headerHeight + 60 : headerHeight}
        wordWrap={false}
        data={data}
        sortColumn={currentSort}
        sortType={currentOrder}
        onSortColumn={handleSortColumn}
        loading={gridLoading}
        renderEmpty={() => (
          <span className="flex items-center justify-center w-full h-full">
            {noDataInfoText}
          </span>
        )}
        renderLoading={() => (
          <div
            className={twMerge(
              'absolute flex items-center flex-col',
              'justify-center w-full h-full bg-white/90 dark:bg-gray-900/90 z-10',
            )}
            role="status"
          >
            {loadingIcon}
            <span className="mt-2">{loadingDataInfoText}</span>
          </div>
        )}
        {...getTestProps(testId, 'table')}
      >
        {hasSelectColumn && (
          <Column width={50} flexGrow={0} fixed="left">
            <HeaderCell className="cell-column">
              <div
                className={twMerge(
                  'flex h-full justify-center',
                  !hasFilters && 'items-center',
                )}
              >
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={handleCheckAll}
                  disabled={isLoading || !data.length > 0}
                  id="header-checkbox"
                  {...getTestProps(testId, 'header-checkbox')}
                />
                {hasFilters && <HeaderFilter />}
              </div>
            </HeaderCell>
            <CheckCell
              dataKey="id"
              checkedKeys={checkedKeys}
              onChange={handleCheck}
              className="cell-column"
            />
          </Column>
        )}

        {columns.map(
          (column, idx) =>
            !column.hide && (
              <Column
                key={column.accessor}
                fullText={true}
                width={column.width}
                minWidth={column.minWidth}
                sortable={sortableColumns ? column.sortable : false}
                style={{
                  justifyContent: column.justify
                    ? column.justify
                    : 'flex-start',
                  padding: '0px',
                }}
                {...(!column.resizable && {
                  flexGrow: column.flexGrow !== undefined ? column.flexGrow : 1,
                })}
                resizable={column.resizable}
                onResize={onResize}
                fixed={column.fixed}
                align={column.align}
                {...getTestProps(testId, `${idx}-column`)}
              >
                <DraggableHeaderCell
                  onDrop={onDrop}
                  accessor={column.accessor}
                  notDraggable={onDrop ? column.notDraggable : true}
                  testId={testId}
                  onDraggingStateChange={setDraggedCol}
                  hasFilters={hasFilters}
                >
                  <div
                    className={twMerge('truncate', column.removable && 'pr-5')}
                  >
                    {column.label}
                  </div>
                  {hasFilters && (
                    <HeaderFilter
                      id={column.accessor}
                      filterInputType={column.filterInputType}
                      options={column.filterInputOptions}
                      handleFilters={handleFilters}
                      value={currentFilters[column.accessor]?.value}
                      currentFilters={currentFilters}
                      dropdownLeftPosition={columns.length / 2 > idx}
                      validation={column.filterValidation}
                      extraOptions={extraOptions}
                      disabled={disableFilters}
                      contentType={contentType}
                      filterDatasourceFetch={column.filterDatasourceFetch}
                      multiple={column.multiple}
                      {...getTestProps(testId, `header-filter`, 'testId')}
                    />
                  )}

                  {column.removable && removableColumns && (
                    <div
                      className={twMerge(
                        'w-4 h-4 flex justify-center',
                        'items-center absolute top-5 right-3 cursor-pointer',
                        'hover:!bg-gray rounded-full group',
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveColumn(column.accessor);
                      }}
                      {...getTestProps(
                        testId,
                        `remove-column-${column.accessor}`,
                      )}
                    >
                      <CloseIcon className="w-2 h-2 text-blue group-hover:text-black" />
                    </div>
                  )}
                </DraggableHeaderCell>
                {onDrop ? (
                  <DroppableCell
                    className={twMerge(
                      'text-sm lg:text-base font-normal',
                      draggedCol === column.accessor
                        ? 'rs-dragged !border-b-0'
                        : '',
                    )}
                    dataKey={column.accessor}
                    accessor={column.accessor}
                    notDraggable={column.notDraggable}
                    onDrop={onDrop}
                  >
                    {(rowData) => (
                      <div
                        className="p-2 truncate"
                        {...getTestProps(
                          testId,
                          `droppable-row-${column.accessor}`,
                        )}
                      >
                        {column.render(
                          deepReadKeyValue(column.accessor, rowData),
                          rowData,
                        )}
                      </div>
                    )}
                  </DroppableCell>
                ) : (
                  <Cell
                    dataKey={column.accessor}
                    className="text-sm lg:text-base font-normal"
                  >
                    {(rowData) => (
                      <div className="p-2 truncate">
                        {column.render(
                          deepReadKeyValue(column.accessor, rowData),
                          rowData,
                        )}
                      </div>
                    )}
                  </Cell>
                )}
              </Column>
            ),
        )}
      </Table>
      {statusBar && (
        <div {...getTestProps(testId, 'status-bar')}>{statusBar}</div>
      )}
    </>
  );
};

DataGrid.propTypes = {
  /**
   * DataGrid data
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Additional classes for button
   */
  additionalClasses: PropTypes.string,
  /**
   * Array of column definitions
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      sortable: PropTypes.bool,
      render: PropTypes.func,
      additionalClasses: PropTypes.string,
      flexGrow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      justify: PropTypes.string,
    }),
  ).isRequired,
  /**
   * Show/Hide select column
   */
  hasSelectColumn: PropTypes.bool,
  /**
   * Sorting callback
   */
  onSortColumn: PropTypes.func,
  /**
   * Waiting for data state
   */
  isLoading: PropTypes.bool,
  /**
   * Initial column for sorting
   */
  sort: PropTypes.string,
  /**
   * Initial sort type
   */
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  /**
   * Function firing on checking row checkboxes (keys: array) => void
   */
  onCheckRows: PropTypes.func,
  /**
   * DataGrid Height
   */
  tableHeight: PropTypes.number,
  /**
   * The height of the DataGrid will be automatically expanded according
   * to the number of data rows, and no vertical scroll bar will appear.
   */
  autoHeight: PropTypes.bool,
  /**
   * If table should be expanded to full height of the parent container
   */
  fillHeight: PropTypes.bool,
  /**
   * DataGrid row height
   */
  rowHeight: PropTypes.number,
  /**
   * DataGrid option to handle only header for multiple table instance
   * - remove empty and loading information below header,
   * - reduce table height to header height
   */
  headerOnly: PropTypes.bool,
  /**
   * If header shoudl be visible
   */
  showHeader: PropTypes.bool,
  /**
   * Header Height
   */
  headerHeight: PropTypes.number,
  /**
   * DataGrid status bar under table
   */
  statusBar: PropTypes.node,
  /**
   * DataGrid no data info text
   */
  noDataInfoText: PropTypes.string,
  /**
   * DataGrid loading data info text
   */
  loadingDataInfoText: PropTypes.string,
  /**
   * Loading data icon
   */
  loadingIcon: PropTypes.node,
  /**
   * Data grid testid
   */
  testId: PropTypes.string,
  /**
   * Data grid resize handler
   */
  onResize: PropTypes.func,
  /**
   * Data grid get remove column button on header label.
   * On click will trigger onRemoveColumn handler that pass field 'accessor'
   */
  removableColumns: PropTypes.bool,
  /**
   * Data grid show/hide sort options in header column.
   */
  sortableColumns: PropTypes.bool,
  /**
   * Data grid remove column handler.
   */
  onRemoveColumn: PropTypes.func,
  /**
   * Disabled header filters
   */
  disableFilters: PropTypes.bool,
};

DataGrid.defaultProps = {
  data: [],
  onSortColumn: null,
  isLoading: false,
  sort: '',
  onCheckRows: null,
  checkedRows: [],
  hasSelectColumn: false,
  tableHeight: 300,
  autoHeight: true,
  fillHeight: false,
  additionalClasses: '',
  headerOnly: false,
  showHeader: true,
  sortOrder: 'asc',
  headerHeight: 56,
  noDataInfoText: 'No table data available',
  loadingDataInfoText: '',
  testId: '',
  onResize: null,
  hasFilters: false,
  filters: {},
  removableColumns: false,
  sortableColumns: true,
  disableFilters: false,
};

export default DataGrid;
