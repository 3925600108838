import { useCallback, useContext, useMemo, useId } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import slug from 'slug';
import { twMerge } from 'tailwind-merge';
import Card from '../../../components/Card/Card';
import { ICONS } from '../../../lib/ctdIcons';
import Tooltip from '../../../components/Tooltip/Tooltip';
import {
  ArrowLeftThinIcon,
  QuestionMarkCircleIcon,
} from '../../../images/shapes';
import {
  findErrors,
  formatErrorToString,
  getTestProps,
} from '../../../lib/helpers';
import {
  ModalInstanceContext,
  useModals,
} from '../../../contexts/ModalContext';
import { EDITABLE } from '../PropertiesSettings/propertiesFields';
import { parseInputType } from '../parseProperty/parseProperty';
import Button from '../../../components/Button/Button';
import PropertiesSettings from '../PropertiesSettings/PropertiesSettings';
import Input from '../../../components/Input/Input';
import ListProperties from '../../ContentTypeForm/ListProperties/ListProperties';
import ContentTypeFormContext from '../../../contexts/ContentTypeFormContext';
import ListPropertiesContext from '../../../contexts/ListPropertiesContext';

const TRANSLATIONS_KEYS = {
  text: { key: 'Text' },
  textarea: { key: 'Textarea' },
  textMarkdown: { key: 'Markdown' },
  email: { key: 'Email' },
  richtext: { key: 'Richtext' },
  number: { key: 'Number' },
  radio: { key: 'Radio' },
  checkbox: { key: 'Checkbox' },
  select: { key: 'Select' },
  datasource: { key: 'Relation' },
  object: { key: 'List', tooltipKey: 'List' },
  geo: { key: 'Geo' },
  media: { key: 'Media' },
  dateTime: { key: 'DateTime' },
  block: { key: 'Block' },
};

const PropertyCard = ({ inputType, onClick, propertyErrors, testId }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const onCardClick = useCallback(
    () => onClick(inputType, formik),
    [onClick, inputType, formik],
  );

  const cardLabel = useMemo(
    () => (
      <p className="text-indigo-950 dark:text-white text-lg font-medium">
        {t(`PropertyForm.Labels.${TRANSLATIONS_KEYS[inputType].key}`)}
      </p>
    ),
    [t, inputType],
  );

  return (
    <div>
      <Card
        onClick={onClick ? onCardClick : null}
        thumbnail={ICONS[inputType]}
        body={
          <div className="flex flex-row text-slate-400 h-full justify-between items-center mt-2">
            <div className="dark:text-white">
              {TRANSLATIONS_KEYS[inputType].tooltipKey ? (
                <Tooltip
                  tooltip={t(
                    `PropertyForm.Tooltips.${TRANSLATIONS_KEYS[inputType].key}`,
                  )}
                  tooltipPlacement="topLeft"
                  phoneTooltipPlacement="topLeft"
                  additionalClasses="w-fit inline-flex"
                >
                  {cardLabel}
                  <QuestionMarkCircleIcon className="text-blue w-3 ml-1" />
                </Tooltip>
              ) : (
                cardLabel
              )}
              <div className="mt-1 dark:text-slate-400">
                {t(
                  `PropertyForm.Descriptions.${TRANSLATIONS_KEYS[inputType].key}`,
                )}
              </div>
            </div>
            <div className="flex flex-col xs:flex-row items-center gap-2">
              <div className="inline-flex items-center gap-2"></div>
            </div>
          </div>
        }
        darkenOnHover={false}
        additionalContainerClasses={twMerge(
          'p-4 bg-slate-50 dark:bg-slate-800 w-full h-full overflow-visible',
          propertyErrors?.schema?.type && 'border border-red',
        )}
        additionalImageContainerClasses="w-fit h-fit rounded"
        additionalBodyClasses="w-full"
        additionalThumbnailClasses="w-fit h-fit"
        {...getTestProps(testId, `${inputType}`, 'testId')}
      />
      {propertyErrors?.schema?.type && (
        <div
          className="text-red text-sm mb-2 mt-1"
          {...getTestProps(testId, 'schema-type-error')}
        >
          {formatErrorToString(propertyErrors?.schema?.type)}
        </div>
      )}
    </div>
  );
};

const PropertyModalFormContent = ({ property, testId, parentInputType }) => {
  const formId = useId();
  const { t } = useTranslation();
  const modal = useModals();
  const modalInstance = useContext(ModalInstanceContext);
  const formik = useFormikContext();
  const { setDirty, propertiesErrors } = useContext(ContentTypeFormContext);
  const { isNestedList } = useContext(ListPropertiesContext);

  const propertyErrors = useMemo(
    () => propertiesErrors[formik.values.key],
    [formik.values.key, propertiesErrors],
  );

  const generateSlug = useCallback((formik) => {
    if (!formik.values.config?.label) return '';
    if (formik.values.key) return formik.values.key;
    formik.setFieldValue('key', slug(formik.values.config?.label, '_'));
  }, []);

  const onPropertyCardClick = useCallback(
    async (inputType, formik) => {
      if (EDITABLE.indexOf(inputType) < 0) {
        alert('Not implemented');
        return;
      }

      const newProperty = parseInputType(inputType, formik.values);
      formik.setValues(newProperty);
      setDirty(true);
    },
    [setDirty],
  );

  const renderPropertiesCards = useCallback(
    (formik) => (
      <div>
        {formik.touched.schema?.type && formik.errors.schema?.type && (
          <div
            className="text-red text-sm mb-2"
            {...getTestProps(testId, 'schema-type-error')}
          >
            {formik.errors.schema?.type}
          </div>
        )}
        <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 gap-3 pb-5">
          {Object.keys(TRANSLATIONS_KEYS).map((inputType) => {
            if (isNestedList && inputType === 'object') return null;
            return (
              <PropertyCard
                key={inputType}
                inputType={inputType}
                onClick={onPropertyCardClick}
                testId={testId}
              />
            );
          })}
        </div>
      </div>
    ),
    [isNestedList, onPropertyCardClick, testId],
  );

  const handleTypeChange = useCallback(
    async (formik) => {
      if (property) {
        const result = await modal.confirmation(
          t('PropertyForm.ChangeTypeConfirm'),
        );
        if (!result) return;
      }
      formik.setFieldValue('schema.type', '');
      formik.setFieldValue('config.inputType', '');
      setDirty(true);
    },
    [property, modal, setDirty, t],
  );

  const onCancel = useCallback(
    () => modalInstance.resolve(null),
    [modalInstance],
  );

  const renderPropertySettings = useCallback(
    (inputType, formik) => {
      const isMedia =
        formik.values.config?.validation?.relationContenttype === '_media';
      return (
        <>
          <div>
            <div className="text-sm text-slate-400 mb-1">
              {t('PropertyForm.PropertyType')}
            </div>
            <PropertyCard
              inputType={isMedia ? 'media' : inputType}
              propertyErrors={propertyErrors}
              testId={testId}
            />
            <Button
              onClick={() => handleTypeChange(formik)}
              iconImage={
                <ArrowLeftThinIcon className="w-2.5 text-gray-700 dark:text-blue stroke-2" />
              }
              buttonColor="borderless"
              noPaddings
              buttonSize="xs"
              iconPosition="start"
              additionalClasses="text-sm mt-4"
              {...getTestProps(testId, 'change-type', 'testId')}
            >
              {t('PropertyForm.ChangePropertyType')}
            </Button>

            {inputType === 'object' && (
              <ListProperties
                listName={formik.values.key}
                listPropertiesItems={formik.values.config.items}
                listSchemaItems={formik.values.schema.items}
                parentInputType={
                  parentInputType
                    ? `${parentInputType}_${inputType}`
                    : inputType
                }
                isOpen={true}
                additionalClasses="px-0"
                {...getTestProps(testId, 'properties', 'testId')}
              />
            )}
          </div>
          <PropertiesSettings
            inputType={inputType}
            propertyErrors={propertyErrors}
            isMedia={isMedia}
            testId={testId}
          />
        </>
      );
    },
    [t, propertyErrors, testId, handleTypeChange, parentInputType],
  );

  const inputType = useMemo(
    () => formik.values.config?.inputType,
    [formik.values.config?.inputType],
  );

  return (
    <form
      id={formId}
      className="space-y-2 md:space-y-4 pb-5"
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <div className="flex flex-col sm:flex-row gap-2 md:gap-10">
        <Input
          name="config.label"
          value={formik.values.config?.label}
          onChange={formik.handleChange}
          onBlur={(event) => {
            formik.handleBlur(event);
            generateSlug(formik);
          }}
          error={findErrors('config.label', propertyErrors, formik)}
          label={t('PropertyForm.Label')}
          required
          {...getTestProps(testId, 'label', 'testId')}
        />
        <Input
          name="key"
          value={formik.values.key}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.key
              ? formik.status?.errors?.key || formik.errors.key
              : null
          }
          label={t('PropertyForm.Key')}
          required
          {...getTestProps(testId, 'key', 'testId')}
        />
      </div>
      {inputType
        ? renderPropertySettings(inputType, formik)
        : renderPropertiesCards(formik)}
      <div
        className="w-full fixed left-0 bottom-0 flex items-center justify-center p-3 space-x-5
        border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900"
      >
        <Button
          onClick={onCancel}
          buttonSize="sm"
          buttonColor="grayBordered"
          {...getTestProps(testId, 'cancel', 'testId')}
        >
          {t('Global.Cancel')}
        </Button>
        <Button
          buttonSize="sm"
          type="submit"
          form={formId}
          {...getTestProps(testId, 'save', 'testId')}
        >
          {t('Global.SaveChanges')}
        </Button>
      </div>
    </form>
  );
};

export default PropertyModalFormContent;

PropertyModalFormContent.propTypes = {
  /**
   * Property object to edit
   */
  property: PropTypes.object,
  /**
   * Test id for property modal form
   */
  testId: PropTypes.string,
};

PropertyModalFormContent.defaultProps = {
  property: {},
  testId: '',
};
