import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const StatusPill = ({
  status,
  color,
  onClick,
  link,
  containerClasses,
  testId,
}) => {
  const colorClasses = {
    blue: 'bg-blue-300 dark:border-blue-300 dark:bg-[#0B1E39] dark:text-blue-300',
    orange:
      'bg-amber-100 dark:border-amber-100 dark:bg-[#41382A] dark:text-amber-100',
    lime: 'bg-lime-100 dark:border-lime-100 dark:bg-[#2E3B1D] dark:text-lime-100',
    red: 'bg-red-300 dark:border-red-300 dark:bg-[#401925] dark:text-red-300',
    green:
      'bg-lime-300 dark:border-lime-300 dark:bg-[#2E3B1D] dark:text-lime-300',
    draft:
      'bg-slate-400 dark:border-slate-400 dark:bg-[#333349] dark:text-slate-400',
    review: 'bg-orange dark:border-orange dark:bg-[#41382A] dark:text-orange',
    public: 'bg-lime dark:border-lime dark:bg-[#2E3B1D] dark:text-lime',
    archive: 'bg-red dark:border-red dark:bg-[#401925] dark:text-red',
  };

  const Pill = useMemo(() => {
    if (link) return Link;
    if (onClick) return 'button';
    return 'div';
  }, [link, onClick]);

  return (
    <Pill
      className={twMerge(
        `w-fit max-w-full ${colorClasses[color] || colorClasses.blue}`,
        'rounded-md py-1.5 px-3 h-7 text-sm font-normal text-gray-700 !leading-tight',
        'focus:no-underline active:no-underline hover:no-underline',
        'focus:text-gray-700 active:text-gray-700 hover:text-gray-700 dark:border',
        (onClick || link) &&
          'cursor-pointer transition-shadow hover:drop-shadow-md',
        containerClasses,
      )}
      onClick={onClick}
      {...(link ? { to: link } : {})}
      {...getTestProps(testId, 'container')}
    >
      <span className="align-middle">{status}</span>
    </Pill>
  );
};

StatusPill.propTypes = {
  /**
   * Status of the pill
   */
  status: PropTypes.node.isRequired,
  /**
   * Color of the pill
   */
  color: PropTypes.oneOf([
    'blue',
    'orange',
    'lime',
    'red',
    'green',
    'draft',
    'review',
    'public',
    'archive',
  ]),
  /**
   * On click callback
   */
  onClick: PropTypes.func,
  /**
   * Pill container additional classes
   */
  containerClasses: PropTypes.string,
  /**
   * Pill test id
   */
  testId: PropTypes.string,
};

StatusPill.defaultProps = {
  color: 'blue',
  containerClasses: '',
  testId: '',
};

export default StatusPill;
