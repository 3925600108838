import { useCallback, useContext, useId, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import { useTranslation } from 'react-i18next';
import { useContentObject, useContentType } from '../../../hooks/api';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import { ModalInstanceContext } from '../../../contexts/ModalContext';
import ContentObjectForm from '../../../form/ContentObjectForm/ContentObjectForm';
import Loader from '../../Loader/Loader';
import {
  handleCTOErrors,
  updateCTO,
} from '../../../lib/flotiq-client/api-helpers';
import { getTestProps } from '../../../lib/helpers';
import { batchPatchContentObject } from '../../../lib/flotiq-client';
import useToken from '../../../hooks/useToken';
import useSpace from '../../../hooks/useSpace';
import { checkResponseStatus } from '../../../lib/flotiq-client/response-errors';
import { toast } from 'react-hot-toast';
import TagManager from 'react-gtm-module';

const EditObjectContentModal = ({
  contentTypeName,
  id,
  ids,
  testId,
  isPatchable,
}) => {
  const jwt = useToken();
  const { space } = useSpace();
  const formId = useId();
  const { t } = useTranslation();
  const modalInstance = useContext(ModalInstanceContext);
  const [isSaving, setIsSaving] = useState(false);
  const [overriddenFields, setOverriddenFields] = useState({});

  const onClose = useCallback(
    (values) => modalInstance.resolve(values),
    [modalInstance],
  );

  const {
    entity: contentObject,
    isLoading: contentObjectIsLoading,
    updateEntity: updateContentObject,
    errors: contentObjectErrors,
  } = useContentObject(contentTypeName, id);

  const {
    entity: contentType,
    errors: contentTypeErrors,
    isLoading: contentTypeLoading,
  } = useContentType(contentTypeName);

  useApiErrorsToast(contentTypeErrors);
  useApiErrorsToast(contentObjectErrors);

  const updateObject = useCallback(
    async (values) => {
      setIsSaving(true);
      if (isPatchable) {
        const valuesToOverride = {};
        Object.keys(overriddenFields).forEach((key) => {
          if (overriddenFields[key]) {
            valuesToOverride[key] = values[key];
          }
        });
        const batchValues = [];
        ids.forEach((_id) => {
          batchValues.push({
            id: _id,
            ...valuesToOverride,
          });
        });
        try {
          const { status, body } = await batchPatchContentObject(
            jwt,
            space,
            contentTypeName,
            batchValues,
          );
          checkResponseStatus(body, status);
          toast.success(
            t('ContentForm.SavedMultiple', {
              count: body.batch_success_count,
            }),
          );

          TagManager.dataLayer({
            dataLayer: {
              event: 'mass_co_change',
            },
          });

          setIsSaving(false);
          onClose(body);

          return [body, {}];
        } catch (error) {
          setIsSaving(false);
          const [formikValues, hasErrors] = handleCTOErrors(
            error,
            valuesToOverride,
            t,
          );
          if (!hasErrors) onClose(formikValues);
          return formikValues;
        }
      } else {
        const [formikValues, hasErrors] = await updateCTO(
          values,
          contentTypeName,
          updateContentObject,
          t,
        );
        setIsSaving(false);
        if (!hasErrors) onClose(formikValues);
        return formikValues;
      }
    },
    [
      isPatchable,
      ids,
      overriddenFields,
      jwt,
      space,
      contentTypeName,
      onClose,
      updateContentObject,
      t,
    ],
  );

  return (
    <>
      {(contentObjectIsLoading && !isPatchable) || contentTypeLoading ? (
        <Loader size="big" type="spinner-grid" />
      ) : (
        <ContentObjectForm
          contentType={contentType}
          contentObject={contentObject || {}}
          onSubmit={updateObject}
          disabled={isSaving}
          formId={formId}
          hasInitialData
          testId={testId}
          isPatchable={isPatchable}
          isEditing={isPatchable || !!id}
          overriddenFields={overriddenFields}
          setOverriddenFields={(checked, key) =>
            setOverriddenFields((prev) => ({ ...prev, [key]: checked }))
          }
          editedCount={ids?.length}
        />
      )}
      <div
        className="w-full fixed left-0 bottom-0 flex items-center justify-center p-3 space-x-5
        border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900"
      >
        <Button
          buttonSize="sm"
          buttonColor="grayBordered"
          onClick={onClose}
          disabled={isSaving}
          {...getTestProps(testId, 'cancel', 'testId')}
        >
          {t('Global.Cancel')}
        </Button>
        <Button
          buttonSize="sm"
          type="submit"
          form={formId}
          disabled={isSaving}
          {...getTestProps(testId, 'save', 'testId')}
        >
          {t('Global.SaveChanges')}
        </Button>
      </div>
    </>
  );
};

export default EditObjectContentModal;

EditObjectContentModal.propTypes = {
  /**
   * Content object type name
   */
  contentTypeName: PropTypes.string.isRequired,
  /**
   * Content object id
   */
  id: PropTypes.string,
  /**
   * Edit modal test id
   */
  testId: PropTypes.string,
  /**
   * Should the form have switches for enabling fields
   */
  isPatchable: PropTypes.bool,
};

EditObjectContentModal.defaultProps = {
  testId: '',
};
