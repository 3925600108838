import { useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// :: Context
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

// :: Component Inner
import HomeStructure from './HomeStructure';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import useDarkMode from '../../hooks/useDarkMode';
import { useUser } from '../../hooks/api';

// :: Images
import { FourSquaresColor, FourSquaresColorWhite } from '../../images/shapes';

const HomeSlim = ({ testId }) => {
  const { t } = useTranslation();
  const { appContext, updateAppContext } = useContext(AppContext);
  const { isAdmin } = useContext(UserContext);
  const navigate = useNavigate();
  const [darkMode] = useDarkMode();

  const { entity: userData } = useUser(appContext?.user?.id);

  const handleSpaceRedirect = useCallback(() => {
    if (userData?.defaultSpace?.slug) {
      navigate(`/s/${userData.defaultSpace.slug}`);
    }
  }, [navigate, userData]);

  useOnce(handleSpaceRedirect);

  const SIDEBAR_MENU_ITEMS = useMemo(
    () => [
      {
        key: 'dashboard',
        icon: darkMode ? (
          <FourSquaresColorWhite className="w-6 min-w-6" />
        ) : (
          <FourSquaresColor className="w-6 min-w-6" />
        ),
        title: t('Global.Dashboard'),
        link: `/`,
      },
    ],
    [darkMode, t],
  );

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      topBar: {
        buttons: [
          {
            label: t('Global.Documentation'),
            color: 'blue',
            key: 'Documentation',
            link: process.env.REACT_APP_DOCUMENTATION,
            target: '_blank',
            rel: 'noreferrer',
          },
        ],
        heading: t('Global.Dashboard'),
      },
      breadcrumbs: [],
      page: 'dashboard',
      sidebarMenuItems: SIDEBAR_MENU_ITEMS,
      id: 'dashboardSlim',
    }));
  }, [t, SIDEBAR_MENU_ITEMS, updateAppContext]);

  useOnce(handlePageUpdate);

  return (
    <HomeStructure
      description={
        isAdmin ? (
          <Trans i18nKey={'Global.WelcomeDashboardNoSpaceAdmin'} />
        ) : (
          <Trans i18nKey={'Global.WelcomeDashboardNoSpace'} />
        )
      }
      testId={testId}
      welcomeImage
    />
  );
};

export default HomeSlim;

HomeSlim.propTypes = {
  /**
   * Test id for home slim page
   */
  testId: PropTypes.string,
};

HomeSlim.defaultProps = {
  testId: '',
};
