import { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const Heading = ({
  level,
  children,
  anchor,
  alignment,
  color,
  hasPaddings,
  additionalClasses,
  title,
  testId,
}) => {
  const safeLevel = Math.min(Math.max(level, 1), 6);
  const HeaderToRender = `h${safeLevel}`;
  const sizeClass = {
    1: ['text-5xl font-bold', hasPaddings && 'pt-6 pb-3'],
    2: ['text-4xl font-bold', hasPaddings && 'pt-5 pb-2.5'],
    3: ['text-3xl font-bold', hasPaddings && 'pt-4 pb-2'],
    4: ['text-2xl font-bold', hasPaddings && 'pt-3 pb-1.5'],
    5: ['text-xl font-bold', hasPaddings && 'pt-2 pb-1'],
    6: ['text-lg font-bold', hasPaddings && 'pt-1'],
  };

  const alignmentClass = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
  };

  const colorClass = {
    black: 'text-black',
    blue: 'text-blue',
    darkBlue: 'text-indigo-950',
    red: 'text-red',
    white: 'text-white',
  };

  return isValidElement(children) ? (
    <HeaderToRender
      className={twMerge(
        sizeClass[safeLevel],
        alignmentClass[alignment],
        colorClass[color],
        additionalClasses,
      )}
      id={anchor}
      title={title}
      {...getTestProps(testId)}
    >
      {children}
    </HeaderToRender>
  ) : (
    <HeaderToRender
      className={twMerge(
        'w-full',
        sizeClass[safeLevel],
        alignmentClass[alignment],
        colorClass[color],
        additionalClasses,
      )}
      dangerouslySetInnerHTML={{ __html: children }}
      id={anchor}
      title={title}
      {...getTestProps(testId)}
    />
  );
};

Heading.propTypes = {
  /**
   * Level of header
   */
  level: PropTypes.number,
  /**
   * Header get predefined paddings
   */
  hasPaddings: PropTypes.bool,
  /**
   * Header contents
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Header anchor
   */
  anchor: PropTypes.string,
  /**
   * Header alignment
   */
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  /**
   * Header color
   */
  color: PropTypes.oneOf(['black', 'blue', 'darkBlue', 'red', 'white']),
  /**
   * Additional classes for header (all levels)
   */
  additionalClasses: PropTypes.string,
  /**
   * Heading Title on mouse over used for long text
   */
  title: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

Heading.defaultProps = {
  level: 1,
  anchor: '',
  alignment: 'left',
  color: 'darkBlue',
  hasPaddings: true,
  additionalClasses: '',
  title: '',
  testId: '',
};

export default Heading;
