import * as yup from 'yup';

const addSchemaToGeoInputs = (validator, key, t) => {
  const max = key === 'lon' ? 180 : 90;
  const transKey = key === 'lon' ? 'Longitude' : 'Latitude';
  const message = t('ContentForm.Errors.Coordinates', {
    coordinate: t(`ContentForm.${transKey}`),
    max,
    min: -max,
  });
  validator = validator.min(-max, message);
  validator = validator.max(max, message);
  return validator;
};

const createErrors = (context, errors, idx, propName, t, propSubName = '') => {
  [idx, idx + 1].forEach((currentIdx) =>
    errors.push(
      context.createError({
        path: `${context.path}[${currentIdx}].${
          !propSubName ? propName : `${propName}.${propSubName}`
        }`,
        message: t('ContentForm.Errors.Unique'),
      }),
    ),
  );
};

const getUniquenessForObject = (
  context,
  element,
  propName,
  currentValue,
  idx,
  errors,
  t,
) => {
  let notUniqueSubFields = [];
  Object.keys(element[propName]).forEach((propSubName) => {
    if (
      element[propName][propSubName] ===
      currentValue[idx + 1][propName][propSubName]
    ) {
      notUniqueSubFields.push(propSubName);
    }
  });
  if (
    notUniqueSubFields.length &&
    notUniqueSubFields.length === Object.keys(element[propName]).length
  ) {
    notUniqueSubFields.forEach((subField) =>
      createErrors(context, errors, idx, propName, t, subField),
    );
  }
};

const getUniquenessErrors = (context, value, uniqueFields, t) => {
  const errors = [];
  value.forEach((element, idx, currentValue) => {
    if (idx + 1 >= currentValue.length) return;
    Object.keys(element).forEach((propName) => {
      if (!uniqueFields[propName] || !currentValue[idx + 1][propName]) return;
      if (typeof element[propName] === 'object') {
        getUniquenessForObject(
          context,
          element,
          propName,
          currentValue,
          idx,
          errors,
          t,
        );
      } else {
        if (element[propName] === currentValue[idx + 1][propName]) {
          createErrors(context, errors, idx, propName, t);
        }
      }
    });
  });
  return errors;
};

const generateArrayObjectsShape = (
  arraySchema,
  validator,
  arrayProperties,
  t,
  isPatchable,
) => {
  if (arraySchema.items?.properties) {
    validator = validator.of(
      yup
        .object()
        .shape(
          generateYupShapeForCto(
            arraySchema.items.properties,
            t,
            arraySchema.items.required,
            arrayProperties?.items?.propertiesConfig
              ? arrayProperties.items.propertiesConfig
              : {},
            undefined,
            isPatchable,
          ),
        ),
    );
  } else if (arrayProperties.validation?.relationContenttype) {
    validator = validator.of(
      yup.object().shape({
        internal: yup.bool(),
        dataUrl: yup
          .string()
          .matches(
            new RegExp(
              `^.*${arrayProperties.validation.relationContenttype}.*$`,
            ),
            t('ContentForm.Errors.DataUrl'),
          ),
      }),
    );
  }
  return validator;
};

const generateArrayShape = (
  validator,
  arraySchema,
  arrayProperties,
  t,
  isPatchable,
) => {
  if (arraySchema.minItems && !isPatchable) {
    validator = validator.min(
      arraySchema.minItems,
      t('ContentForm.Errors.MinLength'),
    );
  }
  if (
    arrayProperties?.validation &&
    !arrayProperties.validation.relationMultiple
  ) {
    validator = validator.max(1, t('ContentForm.Errors.MaxLength'));
  }
  validator = generateArrayObjectsShape(
    arraySchema,
    validator,
    arrayProperties,
    t,
    isPatchable,
  );
  if (arrayProperties?.inputType === 'object') {
    const properties = arrayProperties.items.propertiesConfig;
    const uniqueFields = Object.keys(
      arrayProperties.items.propertiesConfig,
    ).reduce((acc, propName) => {
      acc[propName] = properties[propName].unique || false;
      return acc;
    }, {});

    validator = validator.test({
      name: 'list unique',
      message: t('ContentForm.Errors.Unique'),
      test: (value, context) => {
        if (!value || value.length <= 1) return true;
        const errors = getUniquenessErrors(context, value, uniqueFields, t);
        return errors.length ? new yup.ValidationError(errors) : true;
      },
    });
  }
  return validator;
};

const generateShapeFromProperties = (fieldProperties, validator, t) => {
  if (fieldProperties.inputType === 'email') {
    validator = validator.email(t('ContentForm.Errors.Email'));
  }
  if (['select', 'radio'].indexOf(fieldProperties.inputType) > -1) {
    const optionKeys =
      (fieldProperties.useOptionsWithLabels
        ? fieldProperties.optionsWithLabels?.map((option) => option.value)
        : fieldProperties.options) || [];

    if (fieldProperties.isMultiple) {
      validator = validator.test({
        name: 'options',
        message: t('ContentForm.Errors.OptionNotFound'),
        test: (value) =>
          !(value || []).find((option) => !optionKeys.includes(option)),
      });
    } else {
      validator = validator.oneOf(
        optionKeys,
        t('ContentForm.Errors.OptionNotFound'),
      );
    }
  }
  if (fieldProperties.inputType === 'richtext') {
    validator = validator.test({
      name: 'plainText',
      message: t('ContentForm.Errors.Required'),
      test: (value) => {
        return value !== '<p><br></p>';
      },
    });
  }
  return validator;
};

const generateShapeFromSchema = (
  fieldSchema,
  fieldProperties,
  validator,
  t,
  isPatchable,
) => {
  if (fieldSchema.type === 'object') {
    validator = validator.shape(
      generateYupShapeForCto(
        fieldSchema.properties,
        t,
        fieldSchema.required,
        {},
        fieldProperties?.inputType === 'geo',
        isPatchable,
      ),
    );
  }
  if (fieldSchema.pattern) {
    validator = validator.matches(
      fieldSchema.pattern,
      t('ContentForm.Errors.Pattern', { pattern: fieldSchema.pattern }),
    );
  }
  if (fieldSchema.type === 'array') {
    validator = generateArrayShape(
      validator,
      fieldSchema,
      fieldProperties,
      t,
      isPatchable,
    );
  }
  return validator;
};

export const generateYupShapeForCto = (
  schema,
  t,
  required = {},
  properties = {},
  isGeo = false,
  isPatchable = false,
) => {
  const requiredFields = isPatchable
    ? []
    : Array.isArray(required)
    ? required.reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    : required;
  return Object.keys(schema).reduce((prop, key) => {
    let validator = yup[schema?.[key]?.type]?.();
    if (properties[key]) {
      validator = generateShapeFromProperties(properties[key], validator, t);
    }
    if (schema[key]) {
      validator = generateShapeFromSchema(
        schema[key],
        properties[key],
        validator,
        t,
        isPatchable,
      );
    }
    if (isGeo) {
      validator = addSchemaToGeoInputs(validator, key, t);
    }
    if (requiredFields[key]) {
      validator = validator.required(t('ContentForm.Errors.Required'));
    }
    prop[key] = validator;
    return prop;
  }, {});
};
