import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import moment from 'moment';

// :: Components
import Panel from '../Panel/Panel';
import DateContainer from '../DateContainer/DateContainer';
import Heading from '../Heading/Heading';
import Loader from '../Loader/Loader';
import Button from '../Button/Button';

// :: Inner Components
import LogModalRow from './modal/LogModalRow';
import LogModalCodePreview from './modal/LogModalCodePreview';
import LogModalHeaders from './modal/LogModalHeaders';

// :: Contexts
import { useModals } from '../../contexts/ModalContext';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Images
import { InformationSignIcon } from '../../images/shapes';

const ContentObjectLogs = ({
  logs,
  loading,
  onRefresh,
  additionalClasses,
  testId,
}) => {
  const { t } = useTranslation();
  const modal = useModals();

  const statusIcon = useCallback((status, response) => {
    return (
      <div
        className={twMerge(
          'rounded-full h-5 w-5',
          status === 'done' && response !== 404 ? 'bg-lime' : 'bg-red',
        )}
      />
    );
  }, []);

  const statusText = useCallback(
    (reason, status, response) => {
      if (status === 'done') {
        return (
          <>
            <span className="mr-2">{response.status}</span>
            <span className="text-base">{response.statusText}</span>
          </>
        );
      }

      switch (reason) {
        case 'error.unreachable_url':
          return t('Webhooks.Errors.UnreachableURL');
        case 'error.general':
          return t('Webhooks.Errors.General');
        case 'error.fetching':
          return t('Webhooks.Errors.Fetching');
        default:
          return t('Webhooks.Errors.Error');
      }
    },
    [t],
  );

  const handleClickLog = useCallback(
    (log) => {
      const logModalHead = [
        {
          id: 'status',
          label: statusIcon(log?.status, log?.response?.status),
          value: statusText(log?.reason, log?.status, log?.response),
        },
        {
          id: 'url',
          label: 'URL:',
          value: log.url,
        },
        {
          id: 'created-at',
          label: `${t('Webhooks.LogModal.CreatedAt')}:`,
          value: moment(log?.createdAt).format('LLL'),
        },
        {
          id: 'action',
          label: `${t('Webhooks.LogModal.Action')}:`,
          value: log?.action,
        },
        {
          id: 'content-type-name',
          label: `${t('Webhooks.LogModal.ContentTypeName')}:`,
          value: log?.contentTypeName,
        },
      ];

      if (log?.reason) {
        logModalHead.push({
          id: 'reason',
          label: `${t('Webhooks.LogModal.Reason')}:`,
          value: log?.reason,
        });
      }

      const logModalRequest = [
        {
          id: 'request-headers',
          label: t('Webhooks.Headers'),
          value: (
            <LogModalHeaders
              headers={log?.request?.headers}
              {...getTestProps(testId, 'head-req', 'testId')}
            />
          ),
          colDirection: true,
        },
        {
          id: 'request-payload',
          label: t('Webhooks.Payload'),
          value: (
            <LogModalCodePreview
              code={log?.request?.payload}
              {...getTestProps(testId, 'code-preview-payload', 'testId')}
            />
          ),
          colDirection: true,
        },
      ];

      const logModalResponse = [
        {
          id: 'response-http-code',
          label: t('Webhooks.CodeHTTP'),
          value: log?.response?.status,
          colDirection: true,
        },
        {
          id: 'response-http-status',
          label: t('Webhooks.StatusHTTP'),
          value: log?.response?.statusText,
          colDirection: true,
        },
        {
          id: 'response-headers',
          label: t('Webhooks.Headers'),
          value: (
            <LogModalHeaders
              headers={log?.response?.headers}
              {...getTestProps(testId, 'head-res', 'testId')}
            />
          ),
          colDirection: true,
        },
        {
          id: 'response-body',
          label: t('Webhooks.Body'),
          value: (
            <LogModalCodePreview
              code={log?.response?.body}
              {...getTestProps(testId, 'code-preview-body', 'testId')}
            />
          ),
          colDirection: true,
        },
      ];

      modal.info(
        t('Webhooks.LogModal.Title'),
        <div className="flex">
          <div className="flex flex-col w-full">
            {logModalHead.map((el) => (
              <LogModalRow
                key={el.id}
                label={el.label}
                value={el.value}
                colDirection={el.colDirection}
                {...getTestProps(testId, `ml-head-${el.id}`, 'testId')}
              />
            ))}

            {log?.request && (
              <>
                <div className="h-0 border-b border-slate-200 w-full mb-2 mt-4" />

                <Heading level={4}>{t('Webhooks.RequestData')}</Heading>

                {logModalRequest.map((el) => (
                  <LogModalRow
                    key={el.id}
                    label={el.label}
                    value={el.value}
                    colDirection={el.colDirection}
                    {...getTestProps(testId, `ml-req-${el.id}`, 'testId')}
                  />
                ))}
              </>
            )}

            {log?.response && (
              <>
                <div className="h-0 border-b border-slate-200 w-full mb-2 mt-4" />

                <Heading level={4}>{t('Webhooks.ResponseData')}</Heading>

                {logModalResponse.map((el) => (
                  <LogModalRow
                    key={el.id}
                    label={el.label}
                    value={el.value}
                    colDirection={el.colDirection}
                    {...getTestProps(testId, `ml-res-${el.id}`, 'testId')}
                  />
                ))}
              </>
            )}
          </div>
        </div>,
        'modal-logs',
        { size: 'lg' },
      );
    },
    [modal, statusIcon, testId, statusText, t],
  );

  return (
    <Panel
      title={t('Webhooks.Logs')}
      isCollapsable={false}
      additionalContainerClasses={twMerge('py-5 px-4', additionalClasses)}
      additionalTitleClasses="text-lg mb-1"
      additionalChildrenClasses={'pt-2'}
      open
      testId={testId}
    >
      <Button
        onClick={onRefresh}
        additionalClasses={'absolute top-4 right-5'}
        buttonColor="gray"
        buttonSize="xs"
        disabled={loading}
        {...getTestProps(testId, `button-log-refresh`, 'testId')}
      >
        {t('Webhooks.Refresh')}
      </Button>

      <div
        className={twMerge(
          'flex flex-col space-y-1.5 max-h-[340px] overflow-y-overlay scrollbar-sm',
          'before:absolute before:inset-0 before:border-l before:border-slate-200 before:pointer-events-none',
          'before:mx-[2.7rem] before:md:mx-[2.2rem] before:mt-16 before:mb-5',
        )}
        {...getTestProps(testId, 'container')}
      >
        {loading && (
          <div
            className="flex justify-center items-center h-12"
            {...getTestProps(testId, 'loading')}
          >
            <Loader type="spinner-grid" size="small" className="w-5" />
          </div>
        )}

        {!loading && !logs.length && (
          <div className="p-3 pl-10">{t('Webhooks.LogsNoData')}</div>
        )}

        {!loading &&
          logs.map((log) => (
            <div
              key={log.id}
              className={twMerge(
                'inline-flex items-center justify-between px-3 md:pl-2.5 md:pr-3 py-2',
                'cursor-pointer group',
              )}
              onClick={() => handleClickLog(log)}
              {...getTestProps(testId, `row-item-${log.id}`)}
            >
              <div className="inline-flex items-center space-x-5 md:space-x-2 xl:space-x-5">
                <div className="w-fit">
                  <div
                    className="relative h-[18px] w-[18px] bg-gray rounded-full
                outline outline-4 outline-white group-hover:outline-none"
                  >
                    <div
                      className="w-fit"
                      {...getTestProps(testId, 'status-icon')}
                    >
                      {statusIcon(log?.status, log?.response?.status)}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col justify-start">
                  <DateContainer
                    date={new Date(log?.createdAt)}
                    isSemibold={false}
                    additionalClasses="text-sm"
                    testId={testId}
                  />
                  <div className="flex items-center justify-center">
                    <p
                      className="font-semibold text-sm leading-tight
                      "
                      {...getTestProps(testId, 'status-text')}
                    >
                      {statusText(log.reason, log.status, log.response)}
                    </p>
                    <InformationSignIcon className="w-4 text-slate-400 ml-2" />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </Panel>
  );
};

export default ContentObjectLogs;

ContentObjectLogs.propTypes = {
  /**
   * Content object logs array
   */
  logs: PropTypes.array,
  /**
   * If logs are loading
   */
  loading: PropTypes.bool,
  /**
   * On refresh click callback
   */
  onRefresh: PropTypes.func,
  /**
   * Additionl panel container classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id for informations container
   */
  testId: PropTypes.string,
};

ContentObjectLogs.defaultProps = {
  loading: false,
  logs: [],
  additionalClasses: '',
  testId: '',
};
