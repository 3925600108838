import { getLocalStorage } from '../../utils/localStorage';
import { generateRedirectUrl } from '../helpers';
import {
  makeBodilessQuery,
  makeUnauthenticatedJSONQuery,
} from './base-request';

const _postLogin = makeUnauthenticatedJSONQuery('login_check');
const _postRegister = makeUnauthenticatedJSONQuery(
  'register?redirectUri={{redirectUri}}&plan={{plan}}',
);
const _postRegisterAppSumo = makeUnauthenticatedJSONQuery(
  'integrations/appsumo/activate/{{appSumoToken}}',
);
const _getAccountActivation = makeBodilessQuery('activate/{{activationToken}}');
const _resetPasswordRequest = makeUnauthenticatedJSONQuery(
  'resetPasswordRequest?redirectUri={{redirectUri}}',
  'POST',
);
const _resetPassword = makeUnauthenticatedJSONQuery('resetPassword', 'POST');
const _refreshToken = makeUnauthenticatedJSONQuery('token/refresh', 'POST');
const _unsubscribe = makeUnauthenticatedJSONQuery(
  'unsubscribe/{{email}}/{{token}}',
  'POST',
);

/**
 * Acquire JWT and user data via username & password
 *
 * @param {string} username
 * @param {string} password
 */
export const postLogin = (username, password) =>
  _postLogin({
    _username: username,
    _password: password,
  });

/**
 * Create user account
 *
 * @param {Record<string, string>} registerFormData
 */
export const postRegister = (registerFormData) =>
  _postRegister(registerFormData);

/**
 * Create user account via AppSumo integration
 *
 * @param {string} activationToken activation token extracted from base64 token passed from AppSumo
 */
export const postRegisterAppSumo = (activationToken, registerFormData) =>
  _postRegisterAppSumo({
    appSumoToken: activationToken,
    ...registerFormData,
  });

/**
 * Activate user account with a valid token
 *
 * @param {string} activationToken activation token extracted from base64 token passed from AppSumo
 */
export const getAccountActivation = (activationToken) =>
  _getAccountActivation(null, null, {
    activationToken,
  });

/**
 * Send password reset request
 *
 * @param {string} email for which request should be sent
 */
export const resetPasswordRequest = (email) =>
  _resetPasswordRequest({
    email,
    sendEmail: true,
    redirectUri: generateRedirectUrl('/change-password'),
  });

/**
 * Send password reset request
 *
 * @param {Record<string, string>} resetPasswordFormData that contains plainPassword, plainPasswordRepeat and token
 */
export const resetPassword = (resetPasswordFormData) =>
  _resetPassword(resetPasswordFormData);

/**
 * Send refresh token request
 */
export const refreshToken = () => {
  const refresh_token = getLocalStorage('cms.user', true)?.refresh_token;
  return _refreshToken({ refresh_token });
};

/**
 * Unsubscribe from emails
 * @param email
 * @param token
 * @returns {Promise<Response>}
 */
export const unsubscribe = (email, token) => {
  return _unsubscribe({ email, token });
};
