import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

// :: Components
import { SectionMenuItem } from './SectionMenuItem.js';
import PinContent from './PinContent';

// :: Lib
import { getTestProps } from '../../lib/helpers.js';

const MenuChildItem = ({
  link,
  title,
  onClick,
  testId,
  pined,
  parent,
  pinContentCallback,
  hidePin,
  elementId,
  additionalClasses,
}) => {
  const itemContainerClasses = twMerge(
    `pl-14 py-2 w-full h-full dark:text-white cursor-pointer pr-4 `,
    title.length > 30 && 'line-clamp-2 pt-1 leading-normal',
    additionalClasses,
  );
  const itemTitle = title.length > 60 ? title : undefined;

  return (
    <>
      {link ? (
        <Link
          className={itemContainerClasses}
          to={link}
          onClick={onClick}
          title={itemTitle}
          {...getTestProps(testId, `link-${title}`)}
        >
          {title}
        </Link>
      ) : (
        <div
          className={itemContainerClasses}
          onClick={onClick}
          title={itemTitle}
          {...getTestProps(testId, `div-${title}`)}
        >
          {title}
        </div>
      )}
      {!hidePin && (
        <PinContent
          parent={parent}
          title={title}
          pinContentCallback={pinContentCallback}
          pined={pined}
          testId={testId}
          elementId={elementId}
        />
      )}
    </>
  );
};

export const SectionMenuItemChild = forwardRef(
  (
    {
      parent,
      items,
      selected,
      selectedSeparator,
      show,
      handleParentOpen,
      additionalMenuItemChildClasses,
      additionalMenuItemChildContainerClasses,
      additionalMenuItemChildInnerClasses,
      pinContentCallback,
      hidePin,
      onClick,
      testId,
    },
    ref,
  ) => {
    const itemRef = useRef();

    const handleClick = (handler) => {
      onClick?.();

      handler?.();
    };

    return (
      <ul
        ref={ref}
        className={twMerge(
          'max-h-fit overflow-hidden',
          !show ? 'h-0' : 'h-full pb-2',
          additionalMenuItemChildContainerClasses,
        )}
        {...getTestProps(testId, `container-${parent}`)}
      >
        {items?.map((el) => {
          const isSelected = selected?.startsWith(
            parent + selectedSeparator + el.key + selectedSeparator,
          );
          if (el.children?.length > 0) {
            return (
              <li key={el.key}>
                <SectionMenuItem
                  parent={parent}
                  menuItems={[el]}
                  selected={selected}
                  selectedSeparator={selectedSeparator}
                  isOpen={show}
                  pinContentCallback={pinContentCallback}
                  hidePin={hidePin || el.hidePin}
                  handleParentOpen={handleParentOpen}
                  openItem={isSelected ? el.key : ''}
                  additionalMenuItemClasses="py-2 flex text-base items-center pl-6 mb-0"
                  additionalMenuItemIconClasses="w-3 h-3"
                  additionalMenuItemChildClasses={twMerge(
                    'pl-2 dark:text-white',
                    el.additionalClasses,
                  )}
                  {...getTestProps(testId, 'menu', 'testId')}
                />
              </li>
            );
          }

          return (
            <li
              key={el.key}
              ref={itemRef}
              className={twMerge(
                'relative flex text-base items-center group',
                'hover:bg-gradient-menu-hover',
                selected === parent + selectedSeparator + el.key &&
                  'shadow-[-4px_0px_0px_0px_#0083FC_inset] bg-gradient-menu-active',
                additionalMenuItemChildClasses,
                el.additionalClasses,
              )}
              {...getTestProps(testId, el.title)}
            >
              <MenuChildItem
                link={el.link}
                title={el.title}
                onClick={() => handleClick(el.onClick)}
                pined={el?.pined}
                parent={parent}
                testId={testId}
                pinContentCallback={pinContentCallback}
                hidePin={hidePin || el.hidePin}
                elementId={el.id}
                additionalClasses={additionalMenuItemChildInnerClasses}
              />
            </li>
          );
        })}
      </ul>
    );
  },
);

SectionMenuItemChild.propTypes = {
  /**
   * Parent test id anchor
   */
  parent: PropTypes.string,
  /**
   * Items to show
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  /**
   *Selected name
   */
  selected: PropTypes.any,
  /**
   * Separator that is used to validate correct selection from `select` if we include there separator
   */
  selectedSeparator: PropTypes.string,
  /**
   * If we show/hide children menu item
   */
  show: PropTypes.bool,
  /**
   * Handler that will work only for show: false to trigger func to be able to open parent
   */
  handleParentOpen: PropTypes.func,
  /**
   * If pin should be hidden for children
   */
  hidePin: PropTypes.bool,
  /**
   * Additional Menu Item Child Classes
   */
  additionalMenuItemChildClasses: PropTypes.string,
  /**
   * Additional Menu Item Child Container Classes
   */
  additionalMenuItemChildContainerClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

SectionMenuItemChild.defaultProps = {
  parent: '',
  selected: '',
  selectedSeparator: '/',
  show: false,
  hidePin: false,
  additionalMenuItemChildClasses: '',
  additionalMenuItemChildContainerClasses: '',
  additionalMenuItemChildInnerClasses: '',
  testId: '',
};
