import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

// :: Component
import Heading from '../Heading/Heading';
import Button from '../Button/Button';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Icons
import { CloseIcon } from '../../images/shapes';

const AnnouncementComponent = ({
  additionalClasses,
  announcement,
  handleClose,
  hidden,
  hasCloseButton,
  testId,
}) => {
  if (!announcement) return null;

  return (
    <div
      style={{ background: 'linear-gradient(179deg, #00d4c0, #009ff0)' }}
      className={twMerge(
        'flex w-full justify-center items-center fixed z-30',
        hidden && 'hidden',
        additionalClasses,
      )}
      {...getTestProps(testId, 'container')}
    >
      <Heading
        additionalClasses={'!text-lg sm:!text-2xl !py-2 text-center'}
        level={4}
        color={'white'}
        {...getTestProps(testId, 'heading', 'testId')}
      >
          { announcement }
      </Heading>

      {hasCloseButton && (
        <div
          className={twMerge(
            'flex items-center h-full',
            'absolute top-0 right-0 ',
          )}
        >
          <Button
            iconImage={
              <CloseIcon className="text-white hover:opacity-50 w-4 sm:w-5" />
            }
            buttonColor="borderless"
            noPaddings
            onClick={handleClose}
            {...getTestProps(testId, 'close-button-announcement', 'testId')}
          />
        </div>
      )}
    </div>
  );
};

AnnouncementComponent.propTypes = {
  /**
   * Has close button
   */
  hasCloseButton: PropTypes.bool,
  /**
   * Additional classes for pagination box
   */
  additionalClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
  /**
   * Hide component
   */
  hidden: PropTypes.bool,
  /**
   * Announcement do display
   */
  announcement: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * Handle Close button
   */
  handleClose: PropTypes.func,
};

AnnouncementComponent.defaultProps = {
  additionalClasses: '',
  announcement: '',
  handleClose: undefined,
  hasCloseButton: false,
  hidden: false,
  testId: '',
};

export default AnnouncementComponent;
