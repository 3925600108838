import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

// :: Component
import Tooltip from '../../Tooltip/Tooltip';

const InformationRow = ({ title, icon, label, value, isCompact, testId }) => {
  const titleElement = useMemo(() => {
    if (isCompact) return null;
    return title;
  }, [title, isCompact]);

  return (
    <div
      className={'flex items-center text-indigo-950'}
      title={titleElement}
      {...getTestProps(testId, `container`)}
    >
      <Tooltip
        tooltip={`${label} ${value}`}
        tooltipPlacement={'leftCenter'}
        phoneTooltipPlacement={'leftCenter'}
        additionalTooltipClasses={twMerge('hidden', isCompact && 'lg:block')}
        {...getTestProps(testId, `tootltip`, 'testId')}
      >
        <div className="w-fit" {...getTestProps(testId, `row-icon`)}>
          {icon}
        </div>
      </Tooltip>

      <span
        className={twMerge(isCompact && 'lg:hidden')}
        {...getTestProps(testId, `row-label`)}
      >
        {label}
      </span>

      <span
        className={twMerge(
          isCompact && 'lg:hidden',
          'font-semibold mx-1 truncate',
        )}
        {...getTestProps(testId, `row-value`)}
      >
        {value}
      </span>
    </div>
  );
};

export default InformationRow;

InformationRow.propTypes = {
  /**
   * Title to show on hover on element instead of tootltip
   */
  title: PropTypes.string,
  /**
   * Icon to show before label
   */
  icon: PropTypes.node,
  /**
   * Label
   */
  label: PropTypes.string,
  /**
   * Value
   */
  value: PropTypes.string,
  /**
   * Component in compacted mode. No text, only icon with tooltip.
   */
  isCompact: PropTypes.bool,
  /**
   * Test id for information row component
   */
  testId: PropTypes.string,
};

InformationRow.defaultProps = {
  title: '',
  icon: undefined,
  label: '',
  value: '',
  isCompact: false,
  testId: '',
};
