import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

const RightSidebar = ({ children, additionalClasses }) => (
  <div
    className={twMerge(
      'grid md:grid-cols-2 xl:grid-cols-1 px-5 md:px-7 py-7 border-l',
      'gap-5 xl:gap-0 xl:space-y-5 w-full dark:border-slate-800',
      additionalClasses,
    )}
  >
    {children}
  </div>
);

export default RightSidebar;

RightSidebar.propTypes = {
  /**
   * Additional classes to add to the wrapper
   */
  additionalClasses: PropTypes.string,
  /*
   ** Sidebar children
   */
  children: PropTypes.any,
};

RightSidebar.defaultProps = {
  children: undefined,
  additionalClasses: '',
};
