import { twMerge } from 'tailwind-merge';
import { defaultRenderer } from '../DataGrid/DataGridCell/cellRenderer';
import { AddVariantIcon, ArrowUpRightIcon, TagIcon } from '../../images/shapes';
import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';
import {
  getDataSize,
  getDefaultImageProperties,
  getTestProps,
  isImagePreviewSupported,
} from '../../lib/helpers';
import Button from '../Button/Button';
import DateContainer from '../DateContainer/DateContainer';
import StatusPill from '../StatusPill/StatusPill';
import LinkButton from '../LinkButton/LinkButton';

export const getDefinedColumns = (
  gridOptionsByID,
  onTagEdit,
  onTagClick,
  onVariantCreate,
  canUpdate,
  t,
  testId,
) => ({
  preview: {
    accessor: 'preview',
    label: t('Media.Preview'),
    width: gridOptionsByID?.['preview']?.width,
    render: (_, mediaData) => {
      const showImage = isImagePreviewSupported(mediaData.extension);
      return (
        <div key={mediaData.id} className="ml-3">
          {mediaData.type !== 'image' || !showImage ? (
            <div className="flex items-center justify-center h-full w-full">
              <div className="flex items-center justify-center rounded-full h-12 w-12 bg-blue-300 dark:text-indigo-950">
                {mediaData.extension || ''}
              </div>
            </div>
          ) : (
            <img
              src={getMediaUrl(mediaData, 0, 50)}
              alt={mediaData.fileName}
              className="w-full h-full max-h-[50px] object-center object-cover"
              {...getDefaultImageProperties()}
            />
          )}
        </div>
      );
    },
    sortable: false,
    resizable: true,
    baseWidth: 150,
  },
  name: {
    accessor: 'fileName',
    label: t('Media.Name'),
    width: gridOptionsByID?.['fileName']?.width,
    render: (fileName, mediaData) =>
      defaultRenderer(
        <div className="flex gap-1 items-center">
          <LinkButton
            link={getMediaUrl(mediaData)}
            target="_blank"
            rel="noreferrer"
            iconImage={<ArrowUpRightIcon className="hover:opacity-50 w-5" />}
            buttonColor="borderless"
            buttonSize="xs"
            iconColor="blue"
            iconPosition="start"
            additionalClasses={
              '!p-2 text-indigo-950 text-sm lg:text-base 2xl:text-base font-normal max-w-full focus:hover:underline'
            }
            additionalChildrenClasses="truncate"
            {...getTestProps(testId, `${mediaData.id}-open-file`, 'testId')}
          >
            {fileName}
          </LinkButton>
        </div>,
      ),
    sortable: true,
    resizable: true,
  },
  dimensions: {
    accessor: 'dimensions',
    label: t('Media.Dimensions'),
    width: gridOptionsByID?.['dimensions']?.width,
    render: (_, mediaData) =>
      defaultRenderer(
        `${mediaData.width || 'N/D'} x ${mediaData.height || 'N/D'} px`,
      ),
    sortable: false,
    resizable: true,
    baseWidth: 150,
  },
  size: {
    accessor: 'size',
    label: t('Media.Size'),
    width: gridOptionsByID?.['size']?.width,
    render: (_, mediaData) => defaultRenderer(getDataSize(mediaData.size)),
    sortable: true,
    resizable: true,
    baseWidth: 120,
  },
  createdAt: {
    accessor: 'internal.createdAt',
    label: t('Media.UploadDate'),
    width: gridOptionsByID?.['internal.createdAt']?.width,
    render: (createdAt) => (
      <DateContainer date={createdAt} isSemibold={false} />
    ),
    sortable: true,
    resizable: true,
    showByDefault: true,
    baseWidth: 150,
  },
  tags: {
    accessor: 'tags',
    label: t('Media.Tags'),
    width: gridOptionsByID?.['tags']?.width,
    render: (tags, mediaData) => (
      <div className="w-full truncate">
        {onTagEdit && (
          <Button
            onClick={() => onTagEdit(mediaData)}
            iconImage={<TagIcon className="w-5" />}
            buttonColor="borderless"
            disabled={!canUpdate}
            additionalClasses="inline-block align-middle w-fit !p-2 hover:opacity-50"
            {...getTestProps(testId, `${mediaData.id}-edit-tags`, 'testId')}
          />
        )}
        {tags
          ?.filter((tag) => tag)
          .map((tag) => (
            <StatusPill
              key={tag.id}
              status={tag.name}
              containerClasses={twMerge(
                'text-xs lg:text-sm lg:py-1.5 lg:px-3 lg:h-7 py-0.5 px-1 h-fit truncate ml-1',
                '!bg-gray-200 dark:!bg-gray-900 dark:text-slate-400 dark:border-slate-400',
              )}
              onClick={() => onTagClick(tag.id, tag.name)}
              {...getTestProps(testId, `${mediaData.id}-${tag.id}`, 'testId')}
            />
          ))}
      </div>
    ),
    sortable: false,
    resizable: true,
  },
  variants: {
    accessor: 'variants',
    label: t('Media.Variants'),
    width: gridOptionsByID?.['variants']?.width,
    render: (variants, mediaData) => (
      <div className="w-full truncate">
        <Button
          onClick={() => onVariantCreate(mediaData)}
          iconImage={<AddVariantIcon className="w-4 text-slate-400" />}
          buttonColor="borderless"
          disabled={!canUpdate}
          additionalClasses="inline-block align-middle w-fit !p-2 hover:opacity-50"
        />
        {variants?.length || 0}
      </div>
    ),
    sortable: false,
    resizable: true,
    baseWidth: 120,
  },
  extension: {
    accessor: 'extension',
    label: t('Media.Extension'),
    width: gridOptionsByID?.['extension']?.width,
    render: (extension) => defaultRenderer(extension),
    sortable: true,
    resizable: true,
    baseWidth: 120,
  },
  type: {
    accessor: 'type',
    label: t('Media.Type'),
    width: gridOptionsByID?.['type']?.width,
    render: (type) => defaultRenderer(type),
    sortable: true,
    resizable: true,
    baseWidth: 100,
  },
});
