import { useMemo, useEffect, useState } from 'react';
import Pagination from '../Pagination/Pagination';
import { getTestProps } from '../../lib/helpers';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';
import Heading from '../Heading/Heading';
import { useTranslation } from 'react-i18next';
import Panel from '../Panel/Panel';
import { twMerge } from 'tailwind-merge';
import { ListViewIcon, TilesViewlIcon, WarningIcon } from '../../images/shapes';
import ResultsPerPageDropdown from '../Pagination/ResultsPerPageDropdown';
import Button from '../Button/Button';

const RESULTS_PER_PAGE = [12, 24, 30, 42, 60];

const MediaGrid = ({
  data,
  pagination,
  page,
  handlePageChange,
  isLoading,
  handleNoData,
  errors,
  filtersNode,
  activeFilters,
  areFiltersApplied,
  selectedMediaActionBarNode,
  newMedia,
  selectedMedia,
  renderMedia,
  hasPanel,
  emptyFilterText,
  resultsPerPage,
  setResultsPerPage,
  gridView,
  additionalGridContainerClasses,
  additionalGridClasses,
  testId,
}) => {
  const { t } = useTranslation();
  const [firstLoading, setFirstLoading] = useState(true);
  const [isGridView, setIsGridView] = useState(false);

  useEffect(() => {
    if (!isLoading) setFirstLoading(false);
  }, [isLoading]);

  const handleEmptyResult = useMemo(() => {
    if (data.length > 0 && !errors && !isLoading) return '';
    if (isLoading) {
      return (
        <Loader
          size={'big'}
          type={'spinner-grid'}
          {...getTestProps(testId, 'media-loading', 'testId')}
        />
      );
    }
    if (areFiltersApplied || errors)
      return (
        <Heading level={2} additionalClasses="dark:text-white">
          <div
            className={'inline-flex items-center'}
            {...getTestProps(testId, 'media-error-message-container')}
          >
            <WarningIcon className="text-red h-10 mr-3" />
            {errors ? t('Media.OnErrorMessage') : emptyFilterText}
          </div>
        </Heading>
      );
    return handleNoData;
  }, [
    errors,
    handleNoData,
    areFiltersApplied,
    isLoading,
    data,
    emptyFilterText,
    testId,
    t,
  ]);

  const filtersElement = useMemo(() => {
    if (filtersNode) {
      return hasPanel ? (
        <Panel
          title={
            <div>
              {Object.keys(activeFilters).length > 0 ? (
                <>
                  {t('Media.ActiveFiltersHeader')}
                  {': '}
                  <div className="block sm:inline-block">
                    {Object.keys(activeFilters).map((key, index) => (
                      <span key={key} className="font-normal">
                        {activeFilters[key]}
                        {index !== Object.keys(activeFilters).length - 1 &&
                          ', '}
                      </span>
                    ))}
                  </div>
                </>
              ) : (
                t('Media.FiltersHeader')
              )}
            </div>
          }
          additionalContainerClasses="mt-1 !py-3 !px-4 lg:!py-5 z-[11] select-none"
          additionalChildrenContainerClasses={
            'absolute w-full left-0  bg-white dark:bg-slate-950 px-4 pb-3 lg:pb-5 rounded-lg shadow-md'
          }
          additionalIconClasses="!top-1/2 -mt-1"
          testId={testId}
        >
          {filtersNode}
        </Panel>
      ) : (
        <div className="mt-2 mb-2">{filtersNode}</div>
      );
    }
  }, [filtersNode, hasPanel, t, activeFilters, testId]);

  const mediaLayout = useMemo(() => {
    if (isGridView) return gridView;
    return (
      <div className="relative">
        {isLoading && (
          <div className="absolute inset-0 w-full h-full z-10">
            <div
              className="w-full h-full bg-white opacity-80
              bg-blend-multiply dark:bg-gray-900/90 rounded-lg"
            />
            <div className="absolute top-12 md:top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <Loader
                size={'veryBig'}
                type={'spinner-grid'}
                {...getTestProps(testId, 'media-loading', 'testId')}
              />
            </div>
          </div>
        )}
        <div
          className={twMerge(
            'w-full h-full md:overflow-auto scrollbar-sm',
            hasPanel && 'p-2 sm:px-5 sm:pt-2 sm:pb-8',
            additionalGridContainerClasses,
          )}
        >
          <div
            className={twMerge(
              'grid gap-4 sm:gap-6 grid-cols-[repeat(auto-fill,_minmax(12rem,_1fr))]',
              'sm:grid-cols-[repeat(auto-fill,_minmax(14.9rem,_1fr))] relative',
              additionalGridClasses,
            )}
            {...getTestProps(testId, 'media-grid')}
          >
            {newMedia.map((media) => renderMedia(media, true))}
            {data.map((media) => renderMedia(media))}
          </div>
        </div>
      </div>
    );
  }, [
    isGridView,
    gridView,
    isLoading,
    testId,
    hasPanel,
    additionalGridContainerClasses,
    additionalGridClasses,
    newMedia,
    data,
    renderMedia,
  ]);

  return (
    <div className="flex flex-col h-full">
      {filtersElement}
      <div className="w-full h-full relative">
        <div
          className={twMerge(
            'flex items-start sm:items-center pb-4 md:py-6',
            selectedMedia && Object.values(selectedMedia).length > 0
              ? 'flex-col md:flex-row gap-4'
              : 'flex-row',
            selectedMediaActionBarNode ? 'justify-between' : 'justify-center',
          )}
        >
          {selectedMediaActionBarNode}
          {!errors && data.length > 0 && (
            <div
              className="flex flex-wrap sm:flex-nowrap items-center justify-end gap-2 sm:gap-4 mt-2 sm:mt-0
              space-x-3"
            >
              <Pagination
                numOfPages={pagination.total_pages || 1}
                page={page}
                onPageChange={handlePageChange}
                additionalClasses="whitespace-nowrap !p-0"
                {...getTestProps(testId, 'pagination', 'testId')}
              />
              {gridView && (
                <div className="flex gap-2">
                  <Button
                    iconImage={
                      <ListViewIcon
                        className={twMerge(
                          'hover:opacity-50 w-5',
                          !isGridView ? 'text-neutral-300' : 'text-blue',
                        )}
                      />
                    }
                    buttonColor="borderless"
                    onClick={() => setIsGridView(true)}
                    additionalClasses="w-fit !p-2"
                    additionalIconClasses="ml-0"
                    {...getTestProps(testId, 'grid-view', 'testId')}
                  />
                  <Button
                    iconImage={
                      <TilesViewlIcon
                        className={twMerge(
                          'hover:opacity-50 w-5',
                          isGridView ? 'text-neutral-300' : 'text-blue',
                        )}
                      />
                    }
                    buttonColor="borderless"
                    onClick={() => setIsGridView(false)}
                    additionalClasses="w-fit !p-2"
                    additionalIconClasses="ml-0"
                    {...getTestProps(testId, 'tiles-view', 'testId')}
                  />
                </div>
              )}
              {setResultsPerPage && (
                <ResultsPerPageDropdown
                  value={resultsPerPage}
                  setResultsPerPage={setResultsPerPage}
                  additionalButtonClasses="!pr-8 2xl:!py-1"
                  additionalClasses="!p-0 text-sm md:text-base"
                  results={RESULTS_PER_PAGE}
                  label={t('Global.ResultsPerPage')}
                />
              )}
            </div>
          )}
        </div>
        {(errors || (isLoading && firstLoading) || data.length === 0) &&
        newMedia.length === 0 ? (
          <div className="flex p-5 justify-center items-center w-full h-full">
            {handleEmptyResult}
          </div>
        ) : (
          mediaLayout
        )}
      </div>
    </div>
  );
};
export default MediaGrid;

MediaGrid.propTypes = {
  /**
   * Object with media data
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Media render callback
   */
  renderMedia: PropTypes.func.isRequired,
  /**
   * Object with pagination data
   */
  pagination: PropTypes.object.isRequired,
  /**
   * Currently displayed page number
   */
  page: PropTypes.number.isRequired,
  /**
   * Callback handling changing page
   */
  handlePageChange: PropTypes.func.isRequired,
  /**
   * Bool for displaying loading screen
   */
  isLoading: PropTypes.bool,
  /**
   * Information to display when there is no data
   */
  handleNoData: PropTypes.node,
  /**
   * Media loading errors
   */
  errors: PropTypes.any,
  /**
   * Node with filters inputs
   */
  filtersNode: PropTypes.node,
  /**
   * Node with active filters
   */
  activeFilters: PropTypes.any,
  /**
   * Bool for checking are filters applied
   */
  areFiltersApplied: PropTypes.bool,
  /**
   * Bar for handle selected media
   */
  selectedMediaActionBarNode: PropTypes.node,
  /**
   * New uploaded media
   */
  newMedia: PropTypes.array,
  /**
   * Selected media
   */
  selectedMedia: PropTypes.object,
  /**
   * If filters are packed in a panel
   */
  hasPanel: PropTypes.bool,
  /**
   * Text to show when data are empty after filtering
   */
  emptyFilterText: PropTypes.string,
  /**
   * Additional grid container classes
   */
  additionalGridContainerClasses: PropTypes.string,
  /**
   * Additional grid classes
   */
  additionalGridClasses: PropTypes.string,
  /**
   *  Test id for MediaGrid
   */
  testId: PropTypes.string,
  /**
   *  Results per page
   */
  resultsPerPage: PropTypes.any,
  /**
   *  Set results per page
   */
  setResultsPerPage: PropTypes.func,
  /**
   *  Grid view mode
   */
  gridView: PropTypes.node,
};

MediaGrid.defaultProps = {
  isLoading: false,
  handleNoData: '',
  errors: null,
  filtersNode: '',
  areFiltersApplied: false,
  selectedMediaActionBarNode: '',
  newMedia: [],
  selectedMedia: {},
  hasPanel: true,
  emptyFilterText: '',
  additionalGridContainerClasses: '',
  additionalGridClasses: '',
  testId: '',
  activeFilters: {},
};
