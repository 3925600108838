import { useContext, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ModalInstanceContext } from '../../contexts/ModalContext';

import Input from '../Input/Input';
import SearchResultTile from './SearchResultTile';
import Loader from '../Loader/Loader';

import { useContentTypes, useSearch } from '../../hooks/api';
import { useContentTypesByName } from '../../hooks/api/useContentTypesByName';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';

import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';
import { getObjectTitle, getTestProps } from '../../lib/helpers';

// :: Contexts
import useSpace from '../../hooks/useSpace';

const positiveModulo = function (value, divider) {
  const result = ((value % divider) + divider) % divider;
  return isNaN(result) ? 0 : result;
};

const GlobalSearchModal = ({ testId }) => {
  const { t } = useTranslation();
  const [selectedResultIdx, setSelectedResultIdx] = useState(0);
  const [searchPhrase, setSearchPhrase] = useState('');
  const modalInstance = useContext(ModalInstanceContext);
  const navigate = useNavigate();
  const { buildUrlWithSpace } = useSpace();
  const handleSearchChange = useCallback(
    (e) => setSearchPhrase(e.target.value),
    [],
  );

  const ctdParams = useMemo(
    () => ({
      label: searchPhrase,
      limit: 5,
    }),
    [searchPhrase],
  );
  const searchParams = useMemo(
    () => ({
      q: searchPhrase,
      limit: 10,
    }),
    [searchPhrase],
  );

  const hookOptions = useMemo(
    () => ({
      debounce: 250,
      pause: searchPhrase.length < 3,
    }),
    [searchPhrase],
  );

  const {
    data: ctdData,
    isLoading: isLoadingCtdSearch,
    error: ctdSearchErrors,
  } = useContentTypes(ctdParams, hookOptions);
  const {
    data: searchData,
    isLoading: isSearching,
    error: ctoSearchErrors,
  } = useSearch(searchParams, hookOptions);

  const objectCtdNames = useMemo(() => {
    if (!searchData?.length) return [];
    return searchData.map((object) => object?.item?.internal?.contentType);
  }, [searchData]);

  const {
    data: contentTypesByName,
    error: ctdDataErrors,
    isLoading: isLoadingCtdForResults,
  } = useContentTypesByName(objectCtdNames);

  useApiErrorsToast(ctdSearchErrors);
  useApiErrorsToast(ctoSearchErrors);
  useApiErrorsToast(ctdDataErrors);

  const filteredCtd = useMemo(() => {
    if (!ctdData?.length) return ctdData;

    return ctdData.filter((ctd) => !ctd.internal || ctd.name === '_media');
  }, [ctdData]);

  const filteredSearchResults = useMemo(() => {
    if (!searchData?.length) return searchData;
    return searchData.filter((searchResult) => {
      return (
        contentTypesByName &&
        (!contentTypesByName[searchResult.item.internal.contentType]
          ?.internal ||
          contentTypesByName[searchResult.item.internal.contentType]?.name ===
            '_media')
      );
    });
  }, [contentTypesByName, searchData]);

  const handleResultClick = useCallback(
    (type, idx) => {
      let resultUrl;
      if (type === 'ctd') {
        if (!filteredCtd[idx]) return;

        resultUrl =
          filteredCtd[idx].name === '_media'
            ? buildUrlWithSpace('media')
            : buildUrlWithSpace(
                `content-type-objects/${filteredCtd[idx].name}`,
              );
      } else if (type === 'object') {
        if (!filteredSearchResults[idx]) return;

        const typeName = filteredSearchResults[idx].item.internal.contentType;

        if (typeName === '_media') {
          window.open(getMediaUrl(filteredSearchResults[idx].item), '_blank');
        } else {
          resultUrl = buildUrlWithSpace(
            `content-type-objects/edit/${typeName}/${filteredSearchResults[idx].item.id}`,
          );
        }
      }

      if (resultUrl) navigate(resultUrl);
      modalInstance.resolve(false);
    },
    [
      filteredCtd,
      modalInstance,
      buildUrlWithSpace,
      navigate,
      filteredSearchResults,
    ],
  );
  const handleSearchKeyDown = useCallback(
    (e) => {
      if (
        ['ArrowDown', 'ArrowUp', 'Enter', 'Escape', 'Tab'].indexOf(e.key) ===
          -1 ||
        e.ctrlKey ||
        e.metaKey
      )
        return;

      if (e.shiftKey && e.key !== 'Tab') return;

      e.preventDefault();

      if (e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey))
        setSelectedResultIdx((idx) =>
          positiveModulo(
            idx + 1,
            filteredCtd.length + filteredSearchResults.length,
          ),
        );
      if (e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey))
        setSelectedResultIdx((idx) =>
          positiveModulo(
            idx - 1,
            filteredCtd.length + filteredSearchResults.length,
          ),
        );
      if (e.key === 'Enter') {
        if (selectedResultIdx < filteredCtd.length)
          handleResultClick('ctd', selectedResultIdx);
        else
          handleResultClick('object', selectedResultIdx - filteredCtd.length);
      }
      if (e.key === 'Escape') {
        modalInstance.resolve(false);
      }
    },
    [
      filteredCtd.length,
      handleResultClick,
      modalInstance,
      filteredSearchResults.length,
      selectedResultIdx,
    ],
  );

  const hasCTDResults = !!filteredCtd?.length && !isLoadingCtdSearch;
  const hasCTOResults = !!filteredSearchResults?.length && !isSearching;

  const isLoading = isSearching || isLoadingCtdSearch || isLoadingCtdForResults;
  const hasNoResults = !isLoading && !hasCTDResults && !hasCTOResults;

  return (
    <div {...getTestProps(testId)}>
      <Input
        type="search"
        value={searchPhrase}
        onChange={handleSearchChange}
        label={t('Global.Search.InputLabel')}
        placeholder={t('Global.Search.InputPlaceholder')}
        autoFocus
        tabIndex={1}
        onKeyDown={handleSearchKeyDown}
        additionalClasses={'mb-4'}
        {...getTestProps(testId, 'searchbox', 'testId')}
      />
      {isLoading && searchPhrase.length >= 3 && (
        <Loader size="small" type="spinner-grid" additionalClasses="mx-auto" />
      )}
      {hasNoResults && searchPhrase.length >= 3 && t('Global.Search.NoResults')}
      <div className="max-h-[calc(100vh-15rem)] overflow-auto mt-2">
        {hasCTDResults && (
          <div {...getTestProps(testId, 'ctd-results')}>
            {t('Global.Search.ContentTypesHeader')}:
            {filteredCtd.map((ctd, idx) => (
              <SearchResultTile
                key={ctd.id}
                selected={selectedResultIdx === idx}
                onClick={() => handleResultClick('ctd', idx)}
                ctdLabel={ctd.label || ctd.name}
                ctdName={ctd.name}
              />
            ))}
          </div>
        )}
        {hasCTOResults && (
          <div {...getTestProps(testId, 'cto-results')}>
            {t('Global.Search.ContentObjectsHeader')}:
            <div className="space-y-2">
              {filteredSearchResults.map((object, idx) => {
                const objectProperties = Object.keys(object.item)
                  .filter(
                    (key) =>
                      [
                        'internal',
                        '@timestamp',
                        'id',
                        'object_data',
                        'content_type_definition_id',
                        'organization_id',
                        'object_id',
                        'created_at',
                        'deleted_at',
                        'updated_at',
                      ].indexOf(key) === -1,
                  )
                  .filter((key) => typeof object.item[key] !== 'object')
                  .filter((key) => !!object.item[key])
                  .map((key) => `${key}: ${object.item[key]}`)
                  .join(' ● ');
                return (
                  <SearchResultTile
                    key={object.item.id}
                    title={getObjectTitle(
                      object.item,
                      contentTypesByName?.[object.item?.internal.contentType],
                    )}
                    selected={selectedResultIdx === idx + filteredCtd.length}
                    onClick={() => handleResultClick('object', idx)}
                    image={
                      object?.item?.internal?.contentType === '_media' &&
                      object.item.type === 'image'
                        ? getMediaUrl(object.item, 150)
                        : null
                    }
                    ctdName={object.item?.internal.contentType}
                    ctdLabel={
                      contentTypesByName?.[object.item?.internal.contentType]
                        ?.label || object.item?.internal.contentType
                    }
                    properties={objectProperties}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

GlobalSearchModal.propTypes = {
  /**
   * Test id for button
   */
  testId: PropTypes.string,
};
GlobalSearchModal.defaultProps = {
  testId: '',
};

export default GlobalSearchModal;
