import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import useLocalStorageState from 'use-local-storage-state';

// :: Components
import Tabs from '../../components/Tabs/Tabs';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Tooltip from '../../components/Tooltip/Tooltip';
import LinkButton from '../../components/LinkButton/LinkButton';

// :: Contexts
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import NewMediaContext from '../../contexts/NewMediaContext';

// :: Hooks
import useOnce from '../../hooks/useOnce';
import { useConstraints } from '../../hooks/api';
import useSpace from '../../hooks/useSpace';

// :: Subpages
import UserMedia from './Subpages/UserMedia';
import UnsplashMedia from './Subpages/UnsplashMedia';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import { RolePermissions } from '../../lib/rolePermissions';

// :: Images
import { HouseIcon, WarningIcon } from '../../images/shapes';

const MediaLibrary = ({ testId }) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const { appContext, updateAppContext } = useContext(AppContext);
  const { buildUrlWithSpace } = useSpace();

  const { planLimits, permissions, isAdmin } = useContext(UserContext);
  const canCreate = permissions.canCo(
    '_media',
    RolePermissions.PERMISSIONS_TYPES.CREATE,
  );

  const { onUpload, reloadMediaLimit, setReloadMediaLimit } =
    useContext(NewMediaContext);
  const [user] = useLocalStorageState('cms.user');

  const [selectedMedia, setSelectedMedia] = useState({});

  const selectMedia = useCallback(
    (media) =>
      setSelectedMedia((prev) => {
        const newSelectedMedia = { ...prev };
        if (newSelectedMedia[media.id]) delete newSelectedMedia[media.id];
        else newSelectedMedia[media.id] = media;
        return newSelectedMedia;
      }),
    [],
  );

  const clearSelection = useCallback(() => setSelectedMedia({}), []);

  const [mediaOnPageLimit, setMediaOnPageLimit] = useState(
    // We are using +`${x}` interpolation instead of +x
    // to prevent React from evaluating the entire expression
    // during a build.
    +`${process.env.REACT_APP_DEFAULT_MEDIA_LIMIT}` || 12,
  );

  const { entity: mediaSumSize, reload: reloadMediaSumSize } =
    useConstraints('media-sum-size');

  useEffect(() => {
    if (reloadMediaLimit) {
      reloadMediaSumSize();
      setReloadMediaLimit(false);
    }
  }, [reloadMediaSumSize, reloadMediaLimit, setReloadMediaLimit]);

  const handleUpload = useCallback(
    async (e) => {
      onUpload(e.target.files);
      if (inputRef.current) inputRef.current.value = null;
    },
    [onUpload],
  );

  const handleReloadMedia = useCallback(() => {
    reloadMediaSumSize();
  }, [reloadMediaSumSize]);

  const handlePageUpdate = useCallback(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      page: 'media',
      id: 'media',
      topBar: {
        heading: t('Global.MediaLibrary'),
        buttons: canCreate
          ? [
              {
                label: (
                  <>
                    <input
                      ref={inputRef}
                      type="file"
                      className="hidden"
                      onChange={handleUpload}
                      accept={user?.data?.allowedMimetypes}
                      multiple
                      {...getTestProps(testId, 'global-file-input')}
                    />
                    {t('Media.AddFile')}
                  </>
                ),
                tooltip: t('Media.AddFileTooltip'),
                tooltipPlacement: 'leftBottom',
                additionalTooltipClasses: 'hidden md:block',
                as: 'label',
                key: 'AddFile',
              },
            ]
          : [],
      },
    }));
  }, [
    updateAppContext,
    t,
    canCreate,
    handleUpload,
    user?.data?.allowedMimetypes,
    testId,
  ]);

  useOnce(handlePageUpdate);

  useEffect(() => {
    updateAppContext?.((prevState) => ({
      ...prevState,
      breadcrumbs: [
        {
          label: <HouseIcon className="w-3 text-blue" />,
          link: buildUrlWithSpace(''),
          additionalClasses: 'text-slate-400 truncate text-center',
          key: 'Dashboard',
        },
        {
          label: t('Global.MediaLibrary'),
          additionalClasses: 'text-zinc-600 truncate',
          disabled: true,
          key: 'MediaLibrary',
        },
      ],
    }));
  }, [t, buildUrlWithSpace, updateAppContext]);

  const tabsContent = useMemo(() => {
    return [
      {
        label: (
          <span className={'!text-sm sm:!text-base'}>
            {t('Media.Tabs.UploadedFiles')}
          </span>
        ),
        key: 'UserMediaTab',
        render: () => (
          <UserMedia
            mediaOnPageLimit={mediaOnPageLimit}
            setMediaOnPageLimit={setMediaOnPageLimit}
            onUpload={onUpload}
            onRemove={handleReloadMedia}
            selectedMedia={selectedMedia}
            selectMedia={selectMedia}
            clearSelection={clearSelection}
            testId={testId}
            hasGridView
          />
        ),
      },
      {
        label: (
          <span className={'!text-sm sm:!text-base'}>
            {t('Media.Tabs.UnsplashFiles')}
          </span>
        ),
        key: 'UnsplashMediaTab',
        render: () => (
          <UnsplashMedia
            mediaOnPageLimit={mediaOnPageLimit}
            setMediaOnPageLimit={setMediaOnPageLimit}
            handleReloadMedia={handleReloadMedia}
          />
        ),
      },
    ];
  }, [
    t,
    mediaOnPageLimit,
    onUpload,
    selectedMedia,
    selectMedia,
    clearSelection,
    handleReloadMedia,
    testId,
  ]);

  const handleMediaSumSize = useMemo(() => {
    if (mediaSumSize && planLimits?.file_quota) {
      return (
        <div
          className={`flex items-center justify-end w-auto px-2 sm:px-4
          ${planLimits?.file_quota > -1 ? 'md:w-[700px]' : 'md:w-[570px]'}`}
          {...getTestProps(testId, 'media-plan-usage')}
        >
          <Tooltip
            tooltip={t('Media.SpaceUsageTooltip')}
            tooltipPlacement="bottomCenter"
            phoneTooltipPlacement="bottomRight"
            additionalClasses="ml-2 md:!ml-0 mr-2 z-10"
            additionalTooltipClasses="rounded-tr mt-0"
          >
            <WarningIcon className="w-3.5 h-3.5 text-blue" />
          </Tooltip>
          <ProgressBar
            progressBarLabel={t('Media.SpaceUsage')}
            outOfCompletionValue={mediaSumSize?.data}
            outOfCompletionValueUnit={'MB'}
            outOfCompletionTotalValue={
              planLimits.file_quota > 0
                ? planLimits.file_quota
                : t('Media.SpaceUnlimited')
            }
            completionPercentValue={
              planLimits.file_quota > 0
                ? (mediaSumSize?.data / planLimits.file_quota) * 100
                : 100
            }
            barThickness={'thin'}
            hidePercentValue
            additionalLabelClasses={twMerge(
              'whitespace-nowrap !text-sm mb-0 mr-3',
            )}
            additionalCompletionValuesContainerClasses={twMerge(
              'mb-0 mr-3 whitespace-nowrap leading-none',
            )}
            additionalContainerClasses={twMerge(
              '!text-sm md:!text-base grow w-auto flex-row flex-wrap sm:flex-nowrap items-center',
            )}
            additionalBarClasses="hidden sm:block"
          />

          <div className="flex items-center justify-end">
            {planLimits.file_quota > -1 && isAdmin && (
              <LinkButton
                buttonSize="xs"
                additionalClasses={twMerge(
                  'whitespace-nowrap h-6 text-sm px-2 ml-2 md:ml-5',
                )}
                link={`/space/upgrade/${appContext?.space}`}
              >
                {t('Global.UpgradePlan')}
              </LinkButton>
            )}
          </div>
        </div>
      );
    }
  }, [
    mediaSumSize,
    planLimits?.file_quota,
    appContext?.space,
    testId,
    isAdmin,
    t,
  ]);

  return (
    <div
      className="flex items-stretch h-full w-full min-h-[calc(100vh-71px)]"
      {...getTestProps(testId, 'media-lib-container')}
    >
      <Helmet>
        <title>{t('Global.Dashboard')}</title>
      </Helmet>
      <div className="flex flex-col w-full">
        <div
          className="flex items-center justify-end h-9 w-full z-10 bg-slate-50 px-3 dark:bg-gray-900 dark:border-b
          dark:border-slate-800"
        >
          {handleMediaSumSize}
        </div>
        <div className="flex flex-col p-5 md:px-7 lg:pb-10 lg:pt-1 w-full h-full relative">
          <Tabs
            additionalContainerClasses="order-2 lg:order-none"
            tabs={tabsContent}
          />
        </div>
      </div>
    </div>
  );
};

export default MediaLibrary;

MediaLibrary.propTypes = {
  /**
   * Test id for media library page
   */
  testId: PropTypes.string,
};

MediaLibrary.defaultProps = {
  testId: '',
};
