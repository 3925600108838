import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

// :: Components
import Panel from '../Panel/Panel';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';

// :: lib
import { executeWebhooks } from '../../lib/flotiq-client';
import { getTestProps } from '../../lib/helpers';

// :: Hooks
import useToken from '../../hooks/useToken';
import useSpace from '../../hooks/useSpace';

// :: Helpers
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';

// :: Icons
import { PlayCircleIcon } from '../../images/shapes';
import { twMerge } from 'tailwind-merge';

const ContentObjectWebhooks = ({
  webhooks,
  contentObjectId,
  contentTypeDefinitionName,
  additionalClasses,
  testId,
}) => {
  const jwt = useToken();
  const { space } = useSpace();
  const { t } = useTranslation();
  const [loading, setLoading] = useState();

  const handleExecuteWebhook = useCallback(
    async (id) => {
      setLoading(id);

      try {
        const { body, status } = await executeWebhooks(jwt, space, {
          id,
          contentObjectId,
          contentTypeDefinitionName,
        });

        checkResponseStatus(body, status);

        toast.success(t('Webhooks.SendSuccess'));
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(error.message ? error.message : t('Webhooks.SendError'));
        }
      }
      setLoading();
    },
    [jwt, space, contentObjectId, contentTypeDefinitionName, t],
  );

  return (
    <Panel
      title={t('Webhooks.CustomWebhooks')}
      isCollapsable={false}
      additionalTitleClasses="text-lg"
      additionalContainerClasses={twMerge('py-5 px-4', additionalClasses)}
      open
      {...getTestProps(testId, `webhooks-container`, 'testId')}
    >
      <div className="flex items-start flex-wrap gap-3">
        {webhooks.map((el) => (
          <Button
            key={el.id}
            onClick={() => handleExecuteWebhook(el.id)}
            iconImage={
              loading === el.id ? (
                <Loader type="spinner-grid" className="w-5" />
              ) : (
                <PlayCircleIcon className="w-5 text-blue group-hover:text-white" />
              )
            }
            iconPosition="start"
            additionalClasses={'w-max group max-w-[280px]'}
            additionalChildrenClasses={
              'leading-normal truncate whitespace-nowrap'
            }
            buttonSize="xs"
            buttonColor="blueBordered"
            title={el.name}
            {...getTestProps(testId, `button-${el.id}`, 'testId')}
          >
            {el.name}
          </Button>
        ))}
      </div>
    </Panel>
  );
};

export default ContentObjectWebhooks;

ContentObjectWebhooks.propTypes = {
  /**
   *  List of webhooks
   */
  webhooks: PropTypes.any,
  /**
   *  Content Object Id
   */
  contentObjectId: PropTypes.any,
  /**
   *  Content Type Definiton Name
   */
  contentTypeDefinitionName: PropTypes.any,
  /**
   * Additional css classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id for informations container
   */
  testId: PropTypes.string,
};

ContentObjectWebhooks.defaultProps = {
  testId: '',
};
