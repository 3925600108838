import { useRef, useCallback, useState, useEffect } from 'react';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import useToken from '../useToken';
import { getContentObjects, getContentTypes } from '../../lib/flotiq-client';
import useDebounceCallback from '../useDebounceCallback';
import useSpace from '../useSpace';
import { getCtdFeaturedImage } from '../../lib/helpers';

const DEFAULT_STATE = {
  images: {},
  isLoading: true,
  errors: null,
};

const DEFAULT_OPTIONS = {};

/**
 * Get content types data from API for content types where provided only content type name
 * and return content types dictionary where the key is content type name and the value is content type data.
 * If content type was provided as an object, the fetch will be skipped.
 *
 * @param {Array<string|object>} contentTypes
 * @param {{ jwt: string, space: string, abortSignal: object, handleErrors: boolean }} fetchParams
 * @returns {Promise<Object.<string, object>>}
 */
const getContentTypesDict = async (
  contentTypes,
  { jwt, space, abortSignal, handleErrors },
) => {
  const contentTypeStrings = contentTypes.filter(
    (ctd) => typeof ctd === 'string',
  );

  let ctdResult;
  if (contentTypeStrings.length) {
    ctdResult = await getContentTypes(
      jwt,
      space,
      {
        names: contentTypeStrings,
        limit: contentTypeStrings.length,
      },
      abortSignal,
    );

    if (handleErrors) {
      checkResponseStatus(ctdResult.body, ctdResult.status);
    }
  }

  const apiContentTypesDict = (ctdResult?.body?.data || []).reduce(
    (acc, ctd) => {
      acc[ctd.name] = ctd;
      return acc;
    },
    {},
  );

  return contentTypes.reduce((acc, ctd) => {
    const contentTypeData =
      typeof ctd === 'string' ? apiContentTypesDict[ctd] || { name: ctd } : ctd;

    acc[contentTypeData.name] = {
      contentTypeData,
      featuredMediaId:
        contentTypeData?.featuredImage?.[0]?.dataUrl?.match(/[^/]+$/)?.[0],
    };
    return acc;
  }, {});
};

/**
 * Get featured images data based on content types dictionary build step before and return them as a dictionary
 * where the key is content type name and the value is media data from API
 *
 * @param {Object.<string, object>} contentTypesDict
 * @param {{ jwt: string, space: string, abortSignal: object, handleErrors: boolean }} fetchParams
 * @returns {Promise<Object.<string, object>>}
 */
const getImagesDict = async (
  contentTypesDict,
  { jwt, space, abortSignal, handleErrors },
) => {
  const imageIds = [
    ...new Set(
      Object.values(contentTypesDict).map(
        ({ featuredMediaId }) => featuredMediaId,
      ),
    ),
  ].filter((id) => id);

  let mediaReponse;
  if (imageIds.length) {
    mediaReponse = await getContentObjects(
      jwt,
      space,
      {
        contentTypeName: '_media',
        ids: imageIds,
        limit: imageIds.length,
      },
      abortSignal,
    );

    if (handleErrors) {
      checkResponseStatus(mediaReponse.body, mediaReponse.status);
    }
  }
  const imageDict = (mediaReponse?.body?.data || []).reduce((acc, media) => {
    acc[media.id] = media;
    return acc;
  }, {});

  return Object.entries(contentTypesDict).reduce(
    (acc, [contentTypeName, { featuredMediaId }]) => {
      acc[contentTypeName] = imageDict[featuredMediaId];
      return acc;
    },
    {},
  );
};

/**
 * Get featured images dictionary where the key is content type name and the value is featured media URL
 *
 * @param {string|Array<string>} contentTypes
 * @param {object} defaultOptions
 * @returns {{
 *    images: Object.<string, string>,
 *    isLoading: boolean,
 *    errors: array | null,
 *    reload: function,
 *  }|[
 *   data: Object.<string, string>,
 *   isLoading: boolean,
 *   errors: array | null,
 *   reload: function,
 * ]}
 */
export const useFeaturedImages = (
  contentTypes,
  defaultOptions = DEFAULT_OPTIONS,
) => {
  const jwt = useToken();
  const { space } = useSpace();
  const abortCtrl = useRef(null);
  const { debounce = 0 } = defaultOptions;

  const [{ images, isLoading, errors }, setResponseInfo] =
    useState(DEFAULT_STATE);

  const fetchData = useCallback(
    async (jwt, space, contentTypes, handleErrors) => {
      const abortCtrlInstance = abortCtrl.current;
      const abortSignal = abortCtrlInstance
        ? { signal: abortCtrlInstance.signal }
        : {};

      const fetchParams = { jwt, space, abortSignal, handleErrors };

      const contentTypesDict = await getContentTypesDict(
        contentTypes,
        fetchParams,
      );

      const imageDict = await getImagesDict(contentTypesDict, fetchParams);

      const images = Object.keys(contentTypesDict).reduce(
        (acc, contentTypeName) => {
          acc[contentTypeName] = getCtdFeaturedImage(
            contentTypeName,
            imageDict[contentTypeName],
          );
          return acc;
        },
        {},
      );

      if (abortCtrlInstance?.signal.aborted) return;
      abortCtrl.current = null;

      setResponseInfo({
        images,
        isLoading: false,
        errors: null,
      });
    },
    [],
  );

  const reload = useCallback(async () => {
    const { handleErrors = true, cancelUnfinishedRequests = true } =
      defaultOptions;

    const filteredTypes = contentTypes?.filter((ctd) => ctd);

    if (!jwt) return;
    if (!filteredTypes || !filteredTypes.length) {
      setResponseInfo({
        ...DEFAULT_STATE,
        isLoading: false,
      });
      return;
    }
    if (abortCtrl.current) {
      abortCtrl.current.abort();
      abortCtrl.current = null;
    }
    if (cancelUnfinishedRequests) {
      abortCtrl.current = new AbortController();
    }

    const ctdsDict = contentTypes.reduce((acc, ctd) => {
      const ctdName = typeof ctd === 'string' ? ctd : ctd.name;
      acc[ctdName] = getCtdFeaturedImage(ctdName);
      return acc;
    }, {});

    setResponseInfo({
      ...DEFAULT_STATE,
      images: ctdsDict,
    });

    try {
      await fetchData(jwt, space, contentTypes, handleErrors);
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') return;
      setResponseInfo((prevState) => ({
        ...prevState,
        ...(error.status ? { status: error.status } : {}),
        isLoading: false,
        errors: [error],
      }));
    }
  }, [defaultOptions, jwt, contentTypes, fetchData, space]);

  const debouncedReload = useDebounceCallback(reload, debounce);
  useEffect(debouncedReload, [debouncedReload]);

  const result = [images, isLoading, errors, debouncedReload];
  Object.assign(result, {
    images,
    isLoading,
    errors,
    reload: debouncedReload,
  });
  return result;
};
