import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Hooks
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import { useContentType, useContentTypes, useSearch } from '../../hooks/api';
import useDebounceCallback from '../../hooks/useDebounceCallback';
import useToken from '../../hooks/useToken';
import useSpace from '../../hooks/useSpace';
import { useFeaturedImages } from '../../hooks/api/useFeaturedImages';

// :: Components
import MediaGrid from '../../components/MediaGrid/MediaGrid';
import Input from '../../components/Input/Input';
import Heading from '../../components/Heading/Heading';
import Dropdown from '../../components/Dropdown/Dropdown';
import RelationCard from '../../components/RelationCard/RelationCard';
import NewMediaContext from '../../contexts/NewMediaContext';
import Checkbox from '../../components/Checkbox/Checkbox';

// :: Helpers
import {
  findBorderColor,
  findThumbnail,
  getMediaData,
} from '../../lib/newMediaHelpers';
import { getTestProps } from '../../lib/helpers';
import { getCtdsFromQuery } from '../../lib/flotiq-client/api-helpers';

const CTD_PARAMS = {
  limit: 20,
  order_by: 'label',
  order_direction: 'asc',
};

const AllObjects = ({
  limit,
  contentType,
  selectedObjects,
  selectObject,
  hasPanel,
  additionalGridContainerClasses,
  additionalGridClasses,
  testId,
  isTabs,
}) => {
  const jwt = useToken();
  const { space } = useSpace();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [type, setType] = useState(contentType);
  const [name, setName] = useState('');
  const [sort, setSort] = useState('_score');
  const [order, setOrder] = useState('desc');
  const { newMedia, setNewMedia } = useContext(NewMediaContext);
  const [currentCtds, setCurrentCtds] = useState([]);

  const handleTypeChange = useCallback((event) => {
    setType(event.target.value);
    setPage(1);
  }, []);

  const setNameFilter = useCallback((event) => {
    setName(event.target.value);
    setPage(1);
  }, []);

  const handleNameChange = useDebounceCallback(setNameFilter, 150);

  const params = useMemo(() => {
    return {
      q: name ? name : '*',
      order_by: sort,
      order_direction: order,
      limit,
      page,
      ...(contentType || type ? { content_type: [contentType || type] } : {}),
    };
  }, [name, sort, order, limit, page, contentType, type]);

  const { data, errors, isLoading, pagination } = useSearch(params);

  useEffect(() => {
    if (data.length) {
      const ctds = data
        .map(({ item }) => item?.internal?.contentType)
        .filter((contentType) => contentType);
      setCurrentCtds(ctds);
    }
  }, [data]);

  useEffect(() => {
    if (isLoading) {
      setNewMedia?.([]);
    }
  }, [isLoading, setNewMedia]);

  const ctdHookOptions = useMemo(
    () => ({
      pause: !!contentType,
    }),
    [contentType],
  );

  const {
    data: contentTypes,
    isLoading: contentTypesAreLoading,
    errors: contentTypesErrors,
  } = useContentTypes(CTD_PARAMS, ctdHookOptions);

  const typeHookOptions = useMemo(
    () => ({
      pause: !contentType,
    }),
    [contentType],
  );

  const {
    entity: ctd,
    isLoading: ctdIsLoading,
    errors: ctdErrors,
  } = useContentType(contentType, null, typeHookOptions);

  const [featuredImages, featuredImagesLoading] =
    useFeaturedImages(currentCtds);

  useApiErrorsToast(errors);
  useApiErrorsToast(contentTypesErrors);
  useApiErrorsToast(ctdErrors);

  const ctdOptions = useMemo(() => {
    if (contentType)
      return [{ label: ctd ? ctd.label : contentType, value: contentType }];
    return contentTypes
      .filter((entity) => !entity.internal || entity.name === '_media')
      .map((entity) => ({ label: entity.label, value: entity.name }));
  }, [contentTypes, ctd, contentType]);

  const filterCtd = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);
      const newCtds = await getCtdsFromQuery(
        jwt,
        space,
        { ...CTD_PARAMS, label: query },
        t,
      );
      setIsLoading(false);
      return newCtds
        .filter((ctd) => !ctd.internal || ctd.name === '_media')
        .map((ctd) => ({ label: ctd.label, value: ctd.name }));
    },
    [jwt, t, space],
  );

  const sortOptions = useMemo(
    () => [
      {
        value: '_score-desc',
        label: t('ContentForm.Relation.Relevance'),
      },
      {
        value: 'internal.createdAt-desc',
        label: t('ContentForm.Relation.DateCreated'),
      },
      {
        value: 'internal.updatedAt-desc',
        label: t('ContentForm.Relation.DateUpdated'),
      },
      {
        value: 'internal.objectTitle.keyword-asc',
        label: t('ContentForm.Relation.TitleAsc'),
      },
      {
        value: 'internal.objectTitle.keyword-desc',
        label: t('ContentForm.Relation.TitleDesc'),
      },
    ],
    [t],
  );

  const handleSortChange = useCallback((event) => {
    const [sort, order] = (event.target.value || '-').split('-');
    setSort(sort);
    setOrder(order);
    setPage(1);
  }, []);

  const filtersNode = useMemo(() => {
    return (
      <div className="flex flex-col xs:flex-row xs:flex-wrap sm:flex-nowrap gap-2">
        <Dropdown
          options={ctdOptions}
          value={type}
          onChange={handleTypeChange}
          placeholder={
            contentType ? contentType : t('ContentForm.Relation.FilterByType')
          }
          label={t('ContentForm.Relation.FilterByType')}
          filterCallback={filterCtd}
          debounceTime={500}
          disabled={!!contentType}
          additionalClasses="w-full xs:w-min sm:max-w-52 grow sm:min-w-40 md:min-w-52"
          nullable
          {...getTestProps(testId, 'ctds', 'testId')}
        />
        <Input
          placeholder={t('ContentForm.Relation.SearchBy')}
          type="search"
          onChange={handleNameChange}
          value={name}
          label={t('ContentForm.Relation.Search')}
          additionalClasses="order-last sm:order-none"
          {...getTestProps(testId, 'name', 'testId')}
        />
        <Dropdown
          options={sortOptions}
          value={`${sort}-${order}`}
          onChange={handleSortChange}
          label={t('ContentForm.Relation.SortBy')}
          additionalClasses="min-w-[170px]"
          {...getTestProps(testId, 'sort', 'testId')}
        />
      </div>
    );
  }, [
    ctdOptions,
    type,
    handleTypeChange,
    contentType,
    t,
    filterCtd,
    testId,
    handleNameChange,
    name,
    sortOptions,
    sort,
    order,
    handleSortChange,
  ]);

  const findTitleField = useCallback(
    (type) => {
      if (type === '_media') return 'fileName';
      const contentType = contentTypes.find((ctd) => ctd.name === type);
      if (contentType) {
        const def = contentType.metaDefinition;
        const firstTextField = def.order.find(
          (field) => def.propertiesConfig[field].inputType === 'text',
        );
        return firstTextField || '';
      }
    },
    [contentTypes],
  );

  const renderMedia = useCallback(
    (data, isNew = false) => {
      const {
        card,
        errors = '',
        isLoaded = true,
      } = isNew ? getMediaData(data) : { card: data.item };
      const type = isNew ? '_media' : card.internal.contentType;
      return (
        <RelationCard
          key={card.id}
          relation={card}
          relationType={type}
          featuredImageUrl={featuredImages[type]}
          featuredImageLoading={featuredImagesLoading}
          titleField={findTitleField(type)}
          onClick={!isLoaded || errors ? null : selectObject}
          imageOverlay={
            errors || !isLoaded ? (
              ''
            ) : (
              <div className="p-3">
                <Checkbox
                  additionalContainerClasses="group-hover:block"
                  additionalCheckboxClasses={twMerge(
                    'border-0 group-hover:block group-hover:border dark:block',
                    'checked:disabled:bg-blue checked:disabled:border-blue disabled:cursor-pointer bg-transparent',
                  )}
                  checked={!!selectedObjects[card.id]}
                  circular
                  {...getTestProps(testId, 'cto-card', 'testId')}
                />
              </div>
            )
          }
          thumbnail={findThumbnail(errors, isLoaded, true, testId)}
          additionalContainerClasses={findBorderColor(errors, isLoaded, isNew)}
          testId={testId}
        />
      );
    },
    [
      featuredImages,
      featuredImagesLoading,
      findTitleField,
      selectObject,
      selectedObjects,
      testId,
    ],
  );

  const gridIsLoading = useMemo(() => {
    if (contentType) return isLoading || ctdIsLoading;
    return isLoading || contentTypesAreLoading;
  }, [contentType, isLoading, ctdIsLoading, contentTypesAreLoading]);

  return (
    <>
      <MediaGrid
        page={page}
        data={data}
        renderMedia={renderMedia}
        isLoading={gridIsLoading}
        errors={errors}
        pagination={pagination}
        handlePageChange={setPage}
        areFiltersApplied={!!(type || name)}
        filtersNode={filtersNode}
        handleNoData={
          <div className="flex justify-center">
            <Heading level={2} color="blue">
              {t('ObjectsOfType.NotFound')}
            </Heading>
          </div>
        }
        hasPanel={hasPanel}
        additionalGridContainerClasses={twMerge(
          isTabs
            ? 'md:max-h-[calc(100vh-28rem)]'
            : 'md:max-h-[calc(100vh-24rem)]',
          additionalGridContainerClasses,
        )}
        additionalGridClasses={additionalGridClasses}
        newMedia={contentType ? [] : newMedia}
        emptyFilterText={t('ObjectsOfType.NotFound')}
        testId={testId}
      />
    </>
  );
};

export default AllObjects;

AllObjects.propTypes = {
  /**
   * Limit of an element on page
   */
  limit: PropTypes.number,
  /**
   * Content type name
   */
  contentType: PropTypes.string,
  /**
   * Selected objects
   */
  selectedObjects: PropTypes.object,
  /**
   * Callback for selecting object
   */
  selectObject: PropTypes.func,
  /**
   * If object filters are in panel
   */
  hasPanel: PropTypes.bool,
  /**
   * Additional grid container classes
   */
  additionalGridContainerClasses: PropTypes.string,
  /**
   * Additional grid classes
   */
  additionalGridClasses: PropTypes.string,
  /**
   * Page test id
   */
  testId: PropTypes.string,
};

AllObjects.defaultProps = {
  limit: 16,
  contentType: '',
  selectedObjects: {},
  selectObject: /* istanbul ignore next */ () => null,
  hasPanel: false,
  additionalGridContainerClasses: '',
  additionalGridClasses: '',
  testId: '',
  isTabs: false,
};
