import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Component
import Dropdown from '../../../components/Dropdown/Dropdown';
import Loader from '../../../components/Loader/Loader';
import Tooltip from '../../../components/Tooltip/Tooltip';

// :: Images
import { InformationCircleIcon } from '../../../images/shapes';

// :: Helpers
import { capitalizeText, getTestProps } from '../../../lib/helpers';

const StatusPanel = ({
  workflowConfig,
  onChange,
  status,
  loading,
  additionalClasses,
  additionalTitleClasses,
  testId,
}) => {
  const { t } = useTranslation();

  const currentOptions = useMemo(() => {
    if (!workflowConfig) {
      return [];
    }
    const knownColors = {
      draft: 'bg-slate-400',
      review: 'bg-orange',
      public: 'bg-lime',
      archive: 'bg-red',
    };

    const enabledTransitions = workflowConfig.enabled_transitions;
    return Object.values(workflowConfig.workflow.places).map((place) => {
      const isEnabled = enabledTransitions.some(
        (enabledTransition) => enabledTransition.tos.indexOf(place) !== -1,
      );
      const label = capitalizeText(place);
      return {
        value: place,
        label: t(`ObjectStatus.Option${label}`, label),
        circleColor: knownColors[place] || 'bg-silver',
        disabled: !isEnabled,
        disabledAdditionClass: 'text-slate-400 bg-transparent',
      };
    });
  }, [t, workflowConfig]);

  return (
    <div
      className={twMerge(
        'flex flex-wrap flex-row md:flex-col lg:flex-row',
        'rounded-lg bg-white dark:bg-slate-950 relative',
        'py-2 px-4 !mt-0',
        additionalClasses,
      )}
      {...getTestProps(testId, `container`)}
    >
      <div className="flex items-center mr-5 py-2">
        <span
          className={twMerge(
            'text-black dark:text-white mr-2 font-bold text-[22px] md:text-xl lg:text-[22px]',
            additionalTitleClasses,
          )}
          {...getTestProps(testId, `label`)}
        >
          {t('ObjectStatus.ObjectStatus')}
        </span>

        <Tooltip
          tooltip={
            <Trans
              i18nKey="ObjectStatus.InformationStatusPanel"
              default={
                `<span>This object is using the '{{name}}' workflow.</span>` +
                `<span>Visit</span> <i>link</i> <span>to learn more.</span>`
              }
              values={{
                name: workflowConfig?.workflow.name,
              }}
              components={{
                i: (
                  <a
                    href={process.env.REACT_APP_DOCUMENTATION_WORKFLOW}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa fa-phone" aria-hidden="true"></i>
                  </a>
                ),
                span: <strong className="font-light" />,
              }}
            ></Trans>
          }
          tooltipPlacement={'topCenter'}
          phoneTooltipPlacement={'bottomCenter'}
          additionalClasses="order-20"
          additionalTooltipClasses="!max-w-[150px] !translate-x-[-50%] !translate-y-[8px]"
          {...getTestProps(testId, `tooltip`, 'testId')}
        >
          <InformationCircleIcon
            className={'h-6 w-6 text-black dark:text-blue'}
          />
        </Tooltip>
      </div>

      <div className="relative py-2 w-full">
        {loading && (
          <Loader
            type="spinner-grid"
            size="tiny"
            additionalClasses="absolute top-2 left-1/2 z-10"
            {...getTestProps(testId, `loader`, 'testId')}
          />
        )}
        <Dropdown
          onChange={(e) => onChange(e.target.value)}
          options={currentOptions}
          value={status}
          additionalContainerClasses={twMerge('h-10', loading && 'opacity-10')}
          additionalClasses={'relative w-full flex flex-col'}
          additionalOptionsClasses={'text-zinc-600'}
          disabled={loading}
          {...getTestProps(testId, `select`, 'testId')}
        />
      </div>
    </div>
  );
};

export default StatusPanel;

StatusPanel.propTypes = {
  /**
   * On Change status from dropdown handler
   */
  onChange: PropTypes.func,
  /**
   * Loading to cover options on handler ongoing
   */
  loading: PropTypes.bool,
  /**
   * Status that predefine allowed and disabled options
   */
  status: PropTypes.string,
  /**
   * Additional css classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Additional CSS classes for title
   */
  additionalTitleClasses: PropTypes.string,
  /**
   * Test id for status panel
   */
  testId: PropTypes.string,
};

StatusPanel.defaultProps = {
  status: '',
  loading: false,
  additionalTitleClasses: '',
  testId: '',
  additionalClasses: '',
};
