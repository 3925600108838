import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Field, FieldArray, getIn, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import { getTestProps } from '../../lib/helpers';
import { FormRenderFieldEvent } from '../../lib/flotiq-plugins/plugin-events/FormRenderFieldEvent';
import NewMediaContext from '../../contexts/NewMediaContext';
import ContentObjectFormContext from '../../contexts/ContentObjectFormContext';
import ElementFromPlugin from '../ElementFromPlugin/ElementFromPlugin';
import ListField from '../ListField/ListField';
import Input from '../Input/Input';
import GeoInput from '../GeoInput/GeoInput';
import BlockInput from '../BlockInput/BlockInput';
import Dropdown from '../Dropdown/Dropdown';
import Switch from '../Switch/Switch';
import RelationField from '../RelationField/RelationField';
import RadioGroup from '../RadioGroup/RadioGroup';
import Textarea from '../Textarea/Textarea';
import RichText from '../RichText/RichText';
import Markdown from '../Markdown/Markdown';
import usePluginResults from '../../hooks/usePluginResults';
import { FormConfigFieldEvent } from '../../lib/flotiq-plugins/plugin-events/FormConfigFieldEvent';
import OverrideSwitch from '../OverrideSwitch/OverrideSwitch';

const findInputType = (props) => {
  switch (props.inputType) {
    case 'dateTime':
      return props.showTime ? 'datetime-local' : 'date';
    case 'number':
      return 'number';
    case 'email':
      return 'email';
    default:
      return 'text';
  }
};

/**
 * @emits FlotiqPlugins."flotiq.form.field::render"
 * @emits FlotiqPlugins."flotiq.form.field::config"
 */
const CtoCustomField = ({
  name,
  properties,
  schema,
  isRequired,
  disabled,
  label,
  ignoreHidden,
  additionalClasses,
  isFullSize,
  testId,
  ...props
}) => {
  const {
    contentType,
    initialData,
    isEditing,
    userPlugins,
    overriddenFields,
    isPatchable,
  } = useContext(ContentObjectFormContext);
  const { onUpload: onMediaUpload } = useContext(NewMediaContext);

  const formik = useFormikContext();
  const fieldLabel = label || properties.label || name;
  const isOverridden = !isPatchable || overriddenFields[name];
  const hasSwitch = isPatchable && !name.includes('].');
  const isDisabled =
    (isEditing && properties.readonly) ||
    disabled ||
    (!isOverridden && hasSwitch);

  const error = useMemo(() => {
    if (!getIn(formik.touched, name)) return null;
    const fieldError =
      getIn(formik.errors, name) ||
      getIn(formik.status?.errors, name) ||
      formik.status?.errors?.[name];
    if (properties.inputType === 'geo') {
      return !fieldError ||
        Array.isArray(fieldError) ||
        typeof fieldError === 'string'
        ? {
            global: fieldError,
            lat: formik.status?.errors?.[`${name}.lat`],
            lon: formik.status?.errors?.[`${name}.lon`],
          }
        : fieldError;
    }
    return fieldError;
  }, [
    formik.errors,
    formik.status?.errors,
    formik.touched,
    name,
    properties?.inputType,
  ]);

  useEffect(() => {
    if (
      ['geo', 'block'].indexOf(properties.inputType) > -1 &&
      Array.isArray(getIn(formik.values, name))
    ) {
      setTimeout(() => formik.setFieldValue(name, {}));
    }
  }, [formik, name, properties.inputType]);

  const FieldComponent = useMemo(() => {
    switch (properties.inputType) {
      case 'object':
        return ListField;
      case 'textarea':
        return Textarea;
      case 'geo':
        return GeoInput;
      case 'block':
        return BlockInput;
      case 'select':
        return Dropdown;
      case 'radio':
        return RadioGroup;
      case 'checkbox':
        return Switch;
      case 'datasource':
        return RelationField;
      case 'richtext':
        return RichText;
      case 'textMarkdown':
        return Markdown;
      default:
        return Input;
    }
  }, [properties.inputType]);

  const getFieldProps = useCallback(() => {
    switch (properties.inputType) {
      case 'email':
      case 'text':
      case 'dateTime': {
        return { type: findInputType(properties) };
      }
      case 'number': {
        return {
          type: findInputType(properties),
          onChange: (data) => {
            const value = data.target.value;
            const number = isNaN(Number(value)) ? value : Number(value);
            formik.setFieldValue(name, number);
          },
        };
      }
      case 'geo': {
        return {
          onChange: (value, coordinate) => {
            if (value == null) {
              const newGeo = getIn(formik.values, name);
              delete newGeo[coordinate];
              formik.setFieldValue(name, newGeo);
            } else {
              formik.setFieldValue(coordinate, value);
            }
          },
        };
      }
      case 'block': {
        return {
          toolsList: properties.blockEditorTypes,
          onChange: (data) => formik.setFieldValue(name, data),
          onBlur: () =>
            formik.handleBlur({
              target: { name },
            }),
          onMediaUpload,
          additionalClasses: 'max-w-full ' + (isFullSize ? 'w-full' : ''),
        };
      }
      case 'textMarkdown':
      case 'richtext': {
        return {
          onMediaUpload,
          onChange: (data) => formik.setFieldValue(name, data),
          onBlur: () =>
            formik.handleBlur({
              target: { name },
            }),
          additionalClasses: 'max-w-full ' + (isFullSize ? 'w-full' : ''),
        };
      }
      case 'select': {
        return {
          options: properties.useOptionsWithLabels
            ? properties.optionsWithLabels || []
            : (properties.options || []).map((option) => ({
                value: option,
                label: option,
              })),
          onChange: (_, value) => {
            if (value == null) formik.setFieldValue(name, '');
            else formik.setFieldValue(name, value);
          },
          nullable: !isRequired,
          multiple: properties.isMultiple,
        };
      }
      case 'radio': {
        return {
          options: (properties.options || []).map((option) => ({
            value: option,
            label: option,
          })),
          horizontal: true,
        };
      }
      case 'checkbox': {
        return {
          checked: getIn(formik.values, name),
          theme: 'dark',
          size: 'base',
          side: 'right',
          labelUp: true,
          additionalClasses: 'max-w-2xl !justify-items-start',
        };
      }
      case 'object': {
        return {
          itemsProps: properties.items,
          itemsSchema: schema.items,
          isEditing: isEditing,
          isFormDisabled: isDisabled,
          additionalClasses:
            'max-w-full ' + (isFullSize || isPatchable ? 'w-full' : ''),
        };
      }
      case 'datasource': {
        return {
          validation: properties.validation,
          onMediaUpload,
        };
      }
      case 'textarea': {
        return {
          additionalClasses: 'max-w-full' + (isFullSize ? 'w-full' : ''),
        };
      }
      default:
        return {};
    }
  }, [
    formik,
    isDisabled,
    isEditing,
    isFullSize,
    isPatchable,
    isRequired,
    name,
    onMediaUpload,
    properties,
    schema.items,
  ]);

  const eventParams = useMemo(
    () => ({
      name,
      value: getIn(formik.values, name),
      formik,
      required: isRequired,
      disabled: isDisabled,
      properties,
      schema,
      error,
      onMediaUpload,
      contentType,
      initialData,
      isEditing,
      userPlugins,
    }),
    [
      contentType,
      error,
      formik,
      initialData,
      isDisabled,
      isEditing,
      isRequired,
      name,
      onMediaUpload,
      properties,
      schema,
      userPlugins,
    ],
  );

  /**
   * @emits FlotiqPlugins."flotiq.form.field::render"
   */
  const pluginRenders = usePluginResults(
    'flotiq.form.field::render',
    FormRenderFieldEvent,
    eventParams,
  );

  const fieldProps = useMemo(() => {
    const fieldProps = getFieldProps();
    FlotiqPlugins.run(
      'flotiq.form.field::config',
      new FormConfigFieldEvent({ ...eventParams, config: fieldProps }),
    );
    return fieldProps;
  }, [getFieldProps, eventParams]);

  const { additionalElements, ...fieldProperties } = useMemo(
    () => fieldProps,
    [fieldProps],
  );

  if (pluginRenders?.length > 0) {
    return <ElementFromPlugin results={pluginRenders} />;
  }

  const fieldRowClasses =
    isPatchable && hasSwitch
      ? 'grid grid-cols-[fit-content(100px)_1fr] gap-2 md:gap-10'
      : '';

  if (!ignoreHidden && properties.hidden) return null;
  if (['object', 'datasource'].indexOf(properties.inputType) > -1)
    return (
      <div className={fieldRowClasses}>
        {hasSwitch && (
          <OverrideSwitch
            name={name}
            readonly={properties.readonly}
            checked={isOverridden}
            unique={properties.unique}
            testId={testId}
          />
        )}
        <div className="relative min-w-0">
          <FieldArray name={name}>
            {(arrayHelpers) => (
              <FieldComponent
                arrayHelpers={arrayHelpers}
                label={fieldLabel}
                required={isRequired && (!isPatchable || isOverridden)}
                error={error}
                helpText={properties.helpText}
                disabled={isDisabled}
                additionalClasses={additionalClasses}
                {...fieldProperties}
                {...getTestProps(testId, name, 'testId')}
              />
            )}
          </FieldArray>
          {additionalElements && (
            <ElementFromPlugin results={additionalElements} />
          )}
        </div>
      </div>
    );

  return (
    <Field name={name}>
      {({ field }) => {
        let value = field.value;
        if (['date', 'datetime-local'].includes(fieldProps?.type) && value) {
          value = moment(field.value).format(
            fieldProps.type === 'date' ? 'yyyy-MM-DD' : 'yyyy-MM-DDTHH:mm',
          );
        }
        return (
          <div className={fieldRowClasses}>
            {hasSwitch && (
              <OverrideSwitch
                name={name}
                readonly={properties.readonly}
                checked={isOverridden}
                unique={properties.unique}
                testId={testId}
              />
            )}
            <div className="relative min-w-0">
              <FieldComponent
                name={name}
                value={value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={error}
                label={fieldLabel}
                required={isRequired && isOverridden}
                disabled={isDisabled}
                helpText={properties.helpText}
                additionalClasses={additionalClasses}
                {...props}
                {...fieldProperties}
                {...getTestProps(testId, name, 'testId')}
              />
              {additionalElements && (
                <ElementFromPlugin results={additionalElements} />
              )}
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default CtoCustomField;

CtoCustomField.propTypes = {
  /**
   * Field name
   */
  name: PropTypes.string.isRequired,
  /**
   * Cto field properties
   */
  properties: PropTypes.object,
  /**
   * Cto field schema
   */
  schema: PropTypes.object,
  /**
   * If field is required
   */
  isRequired: PropTypes.bool,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Label to override from props
   */
  label: PropTypes.node,
  /**
   * If hidden property should be ignored
   */
  ignoreHidden: PropTypes.bool,
  /**
   * Form field additional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Field test id
   */
  testId: PropTypes.string,
};

CtoCustomField.defaultProps = {
  properties: {},
  schema: {},
  isRequired: false,
  disabled: false,
  additionalClasses: '',
  label: '',
  ignoreHidden: false,
  testId: '',
};
