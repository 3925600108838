import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Components
import PopoverComponent from '../Popover/Popover';
import Button from '../Button/Button';
import LinkButton from '../LinkButton/LinkButton';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Images
import { EllipsisIcon } from '../../images/shapes';

const ActionMenu = ({
  menuItems,
  rowData,
  button,
  onClick,
  actionMenuPlacement,
  additionalActionMenuClasses,
  additionalPopoverPanelClasses,
  testId,
  ...props
}) => {
  const handleOnClick = useCallback(
    (item) => {
      item.onClick && item.onClick(rowData);
      onClick?.(item);
    },
    [onClick, rowData],
  );

  const popoverContent = useMemo(
    () =>
      menuItems.map((item) => {
        const { key, icon, label, additionalClasses, ...props } = item;
        const ButtonComponent = item.link ? LinkButton : Button;

        return (
          <ButtonComponent
            key={key}
            buttonColor="borderless"
            buttonSize="sm"
            iconImage={icon}
            iconPosition="start"
            additionalClasses={twMerge(
              'cursor-pointer hover:text-blue justify-end text-sm lg:text-base font-normal',
              'hover:underline hover:bg-blue-300/25 rounded-none w-full',
              additionalClasses,
            )}
            additionalIconClasses={twMerge('mr-2')}
            onClick={() => handleOnClick(item)}
            {...props}
          >
            <span
              className="block grow text-sm lg:text-base font-normal dark:text-white"
              {...getTestProps(testId, `${key}-item`)}
            >
              {label}
            </span>
          </ButtonComponent>
        );
      }),
    [handleOnClick, menuItems, testId],
  );
  const ActionMenuButton = useMemo(() => {
    if (!button)
      return (
        <EllipsisIcon className="w-4 2xl:w-5 h-4 2xl:h-5 text-slate-400 group-hover:text-blue" />
      );
    return button;
  }, [button]);

  return (
    <PopoverComponent
      testId={testId}
      popoverPanelPlacement={actionMenuPlacement}
      additionalPopoverPanelClasses={twMerge(
        'z-30 px-0 !py-4 rounded space-y-3.5',
        additionalPopoverPanelClasses,
      )}
      content={popoverContent}
      popoverButton={ActionMenuButton}
      popoverButtonProps={props}
      additionalClasses={twMerge(additionalActionMenuClasses)}
    />
  );
};

ActionMenu.propTypes = {
  /**
   * Action menu items
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.node,
      onClick: PropTypes.func,
      icon: PropTypes.node,
      additionalClasses: PropTypes.string,
    }),
  ).isRequired,
  /**
   * Button
   */
  button: PropTypes.node,
  /**
   * On items click handler
   */ onClick: PropTypes.func,
  /**
   * Action menu test id
   */
  testId: PropTypes.string,
  /**
   * Additional CSS classes for an Action menu component
   */
  additionalActionMenuClasses: PropTypes.string,
  /**
   * Additional CSS classes for popover panel component
   */
  additionalPopoverPanelClasses: PropTypes.string,
};

ActionMenu.defaultProps = {
  testId: '',
  actionMenuPlacement: 'rightBottom',
  additionalPopoverPanelClasses: '',
};

export default ActionMenu;
