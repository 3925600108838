import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Authentication from '../../hoc/Authentication/Authentication';
import { getTestProps } from '../../lib/helpers';
import useDarkMode from '../../hooks/useDarkMode';
import { useEffect, useState } from 'react';
import { unsubscribe } from '../../lib/flotiq-client/auth-requests';

const Unsubscribe = ({ testId }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  const [texts, setTexts] = useState({});
  const [darkMode] = useDarkMode();

  useEffect(() => {
    unsubscribe(email, token).then((response) => {
      if (response.ok) {
        setTexts({
          title: t('Global.YouHaveBeenUnsubscribed'),
          firstParagraph: t('Global.WeAreSorryToSeeYouGo'),
          secondParagraph: t('Global.YouCanNowLogIn'),
        });
      } else {
        setTexts({
          title: t('Global.YouHaveNotBeenUnsubscribed'),
          firstParagraph: t('Global.IncorrectUnsubscribing'),
          secondParagraph: t('Global.YouCanNowLogIn'),
        });
      }
    });
  }, [email, token, t]);

  return (
    <>
      <Helmet>
        <title>{t('Global.Unsubscribe')}</title>
      </Helmet>
      <Authentication
        loginLayout={false}
        headerText={texts.title}
        paragraphText={
          <>
            {texts.firstParagraph}
            <br />
            {texts.secondParagraph}
            <Link to="/login" className="text-blue">
              <span> {t('Global.LogIn')}</span>
            </Link>
          </>
        }
        logoLink={process.env.REACT_APP_FLOTIQ_PAGE_URL}
        additionalClasses={twMerge(darkMode && 'dark:bg-gray-900')}
        {...getTestProps(testId, 'authentication', 'testId')}
      ></Authentication>
    </>
  );
};

export default Unsubscribe;

Unsubscribe.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

Unsubscribe.defaultProps = {
  testId: '',
};
