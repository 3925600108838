import { useCallback, useEffect, useId, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Jodit } from 'jodit-pro/es2015/jodit.min.js';
import 'jodit-pro/es5/jodit.css';
import './rich-text.css';
import { useModals } from '../../contexts/ModalContext';
import { useTranslation } from 'react-i18next';
import LinkObjectContentModal from '../RelationField/LinkObjectContentModal/LinkObjectContentModal';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';
import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';
import { mediaIcon, linkIcon } from './icons';
import { getObjectTitle, getTestProps } from '../../lib/helpers';
import FileButton from '../FileButton/FileButton';
import { getContentObject, getContentType } from '../../lib/flotiq-client';
import useToken from '../../hooks/useToken';
import useSpace from '../../hooks/useSpace';

const PARAGRAPH_DATA = {
  p: 'Normal',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  pre: 'Code',
  blockquote: 'Quote',
};

const RichText = ({
  value,
  onChange,
  onBlur,
  label,
  error,
  required,
  disabled,
  helpText,
  onMediaUpload,
  additionalClasses,
  editorConfig,
  testId,
}) => {
  const { t, i18n } = useTranslation();
  const modalId = useId();
  const modal = useModals();
  const timeoutId = useRef(null);
  const editor = useRef(null);
  const textarea = useRef(null);
  const titleCache = useRef({});
  const ctdNameCache = useRef({});
  const joditWrapperRef = useRef();

  const jwt = useToken();
  const { space } = useSpace();

  const addMediaToEditor = useCallback(async () => {
    const media = await modal({
      id: `${modalId}-add-media`,
      title: (
        <div className="flex flex-wrap justify-between gap-1">
          <div className="text-3xl">{t('Global.MediaLibrary')}</div>
          <FileButton
            onUpload={onMediaUpload}
            multiple
            additionalClasses="w-fit"
            testId={testId}
          />
        </div>
      ),
      content: (
        <LinkObjectContentModal
          relationType="_media"
          onMediaUpload={onMediaUpload}
          returnContentObject
          isMultiple={
            process.env.REACT_APP_TEXT_EDITORS_MULTIPLE_MEDIA_MODAL.split(
              ',',
            ).join(',') === 'true'
          }
          {...getTestProps(testId, 'link-modal', 'testId')}
        />
      ),
      size: '2xl',
      dialogAdditionalClasses: 'h-[calc(100vh-32px)]',
    });

    if (media && Object.keys(media).length) {
      Object.values(media).forEach((newMedia) => {
        const mediaUrl = getMediaUrl(newMedia);
        if (newMedia.type === 'image') {
          const image = document.createElement('img');
          image.setAttribute('src', mediaUrl);
          editor.current.selection.insertHTML(image);
        } else if (newMedia.mimeType?.includes('video')) {
          const video = document.createElement('video');
          video.setAttribute('src', mediaUrl);
          video.setAttribute('controls', '');
          editor.current.selection.insertHTML(video);
        } else {
          const file = document.createElement('a');
          file.setAttribute('href', mediaUrl);
          const textContent = document.createTextNode(mediaUrl);
          file.appendChild(textContent);
          editor.current.selection.insertHTML(file);
        }
      });
    }
  }, [modal, modalId, t, onMediaUpload, testId]);

  const addRelationToEditor = useCallback(async () => {
    const object = await modal({
      id: `${modalId}-add-relation`,
      title: (
        <div className="text-3xl">{t('ContentForm.Relation.LinkObject')}</div>
      ),
      content: (
        <LinkObjectContentModal
          returnContentObject
          {...getTestProps(testId, 'link-relation-modal', 'testId')}
        />
      ),
      size: '2xl',
      dialogAdditionalClasses: 'h-[calc(100vh-32px)]',
    });

    if (object && Object.keys(object).length) {
      const newObject = Object.values(object)[0];
      const ctd = newObject.internal?.contentType;
      const title = getObjectTitle(newObject);

      const span = document.createElement('span');
      span.setAttribute('data-relation-object-id', newObject.id);
      span.setAttribute('data-relation-object-type', ctd);
      span.setAttribute('data-relation-object-title', title);
      span.setAttribute(
        'data-relation-url',
        `/api/v1/content/${ctd}/${newObject.id}`,
      );

      if (!titleCache.current[ctd]) titleCache.current[ctd] = {};
      if (!titleCache.current[ctd][newObject.id])
        titleCache.current[newObject.id] = title;

      editor.current.selection.insertHTML(span);
    }
  }, [modal, modalId, t, testId]);

  const addNbspToEditor = useCallback(() => {
    editor.current.selection.insertHTML('&nbsp;');
  }, []);

  const buttons = useMemo(
    () => [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'eraser',
      '|',
      'ul',
      'ol',
      '|',
      'align',
      '|',
      'hr',
      '|',
      'link',
      '|',
      'paragraph',
      'font',
      'fontsize',
      '|',
      'brush',
      '|',
      'image',
      {
        name: 'Media library',
        icon: mediaIcon,
        tooltip: t('RichText.MediaLibrary'),
        exec: async () => {
          await addMediaToEditor();
        },
      },
      {
        name: 'Relation',
        icon: linkIcon,
        tooltip: t('RichText.Relation'),
        exec: async () => {
          await addRelationToEditor();
        },
      },
      '|',
      {
        name: '&nbsp;',
        tooltip: t('RichText.Nbsp'),
        exec: () => {
          addNbspToEditor();
        },
      },
      '|',
      'source',
      'fullsize',
    ],
    [t, addMediaToEditor, addRelationToEditor, addNbspToEditor],
  );

  const paragraphOrder = useMemo(() => {
    // x.split(',').join(',') is here to stop react from
    // evaluating this entire if during build time
    if (process.env.REACT_APP_JODIT_PARAGRAPH_ORDER.split(',').join(','))
      return process.env.REACT_APP_JODIT_PARAGRAPH_ORDER.split(',');
    return ['p', 'h1', 'h2', 'h3', 'h4', 'pre', 'blockquote'];
  }, []);

  const paragraphObject = useMemo(
    () =>
      paragraphOrder
        .filter((paragraphName) => !!PARAGRAPH_DATA[paragraphName])
        .reduce((paragraphObject, paragraphName) => {
          if (!paragraphObject[paragraphName])
            paragraphObject[paragraphName] = '';
          paragraphObject[paragraphName] = PARAGRAPH_DATA[paragraphName];
          return paragraphObject;
        }, {}),
    [paragraphOrder],
  );

  const controls = useMemo(
    () => ({
      paragraph: {
        list: Jodit.atom(paragraphObject),
      },
      font: {
        list: Jodit.atom({
          '': 'Default',
          'Arial,Helvetica,sans-serif': 'Arial',
          'Comic Sans MS, cursive': 'Comic Sans MS',
          'Courier New,Courier,monospace': 'Courier New',
          'Georgia,serif': 'Georgia',
          'Lucida Sans Unicode,Lucida Grande,sans-serif': 'Lucida Sans Unicode',
          'Tahoma,Geneva,sans-serif': 'Tahoma',
          'Times New Roman,Times,serif': 'Times New Roman',
          'Trebuchet MS,Helvetica,sans-seri': 'Trebuchet MS',
          'Verdana,Geneva,sans-serif': 'Verdana',
        }),
      },
    }),
    [paragraphObject],
  );

  const onChangeLocal = useCallback(
    (editorValue) => {
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => onChange(editorValue), 500);
    },
    [onChange],
  );

  const injectRelationTitles = useCallback(() => {
    if (
      !editor.current ||
      editor.current.isDestructed ||
      editor.current.getMode() === Jodit.constants.MODE_SOURCE
    )
      return;
    editor.current.editor
      .querySelectorAll(
        '[data-relation-object-type]:not([data-relation-object-title])',
      )
      ?.forEach((element) => {
        element.setAttribute('contenteditable', 'false');

        const type = element.getAttribute('data-relation-object-type');
        const id = element.getAttribute('data-relation-object-id');
        if (!titleCache.current[type]) titleCache.current[type] = {};
        if (!titleCache.current[type][id]) {
          titleCache.current[type][id] = getContentObject(jwt, space, {
            contentTypeName: type,
            id,
          }).then((res) => {
            if (res.ok) return getObjectTitle(res.body);
          });
        }
        titleCache.current[type][id].then((title) =>
          element.setAttribute('data-relation-object-title', title || id),
        );

        if (!ctdNameCache.current[type]) {
          ctdNameCache.current[type] = getContentType(jwt, space, {
            contentTypeName: type,
          }).then((res) => {
            if (res.ok) return res.body.label || res.body.name;
          });
        }
        ctdNameCache.current[type].then((title) =>
          element.setAttribute('data-relation-type-label', title || type),
        );
      });
  }, [jwt, space]);

  useEffect(() => {
    if (
      editor.current?.destruct &&
      editor.current?.currentPlace?.options?.readonly !== disabled
    ) {
      editor.current.destruct();
      editor.current = null;
    }
    if (!editor.current) {
      editor.current = Jodit.make(textarea.current, {
        license: process.env.REACT_APP_JODIT_LICENSE_KEY,
        toolbarSticky: true,
        toolbarStickyOffset: 63,
        placeholder: '',
        allowResizeY: true,
        allowResizeX: true,
        language: i18n.language,
        height: 400,
        width: '100%',
        readonly: disabled,
        buttons: buttons,
        buttonsMD: buttons,
        buttonsSM: buttons,
        buttonsXS: buttons,
        tabIndex: 0,
        controls,
        colorPickerDefaultTab: 'color',
        cleanHTML: {
          removeEmptyElements: false,
          fillEmptyParagraph: false,
        },
        pasteFromWord: {
          enable: true,
        },
        events: {
          afterGetValueFromEditor: (data) => {
            const titleCleanupRegex = /\s?data-relation-object-title="[^"]+"/g;
            const ctdCleanupRegex = /\s?data-relation-type-label="[^"]+"/g;
            const editableCleanupRegex = /\s?contenteditable="false"/g;
            data.value = data.value.replaceAll(titleCleanupRegex, '');
            data.value = data.value.replaceAll(ctdCleanupRegex, '');
            data.value = data.value.replaceAll(editableCleanupRegex, '');
          },
          change: (e) => {
            onChangeLocal(e);
            injectRelationTitles();
          },
          afterInit: () => {
            const updateValue = () => {
              if (editor.current && editor?.current?.value !== value) {
                editor.current.value = value;
                injectRelationTitles();
              }
            };

            if (editor.current) {
              editor.current.waitForReady().then(updateValue);
            }
          },
          afterSetMode: injectRelationTitles,
          changePlace: injectRelationTitles,
          blur: onBlur,
        },
        ...editorConfig,
      });
    }
  }, [
    buttons,
    controls,
    value,
    disabled,
    i18n.language,
    onBlur,
    onChangeLocal,
    injectRelationTitles,
    editorConfig,
  ]);

  useEffect(
    () => () => {
      if (!editor.current) return;

      if (editor.current.isFullSize) {
        editor.current.e.fire('toggleFullSize', false);

        // Jodit doesn't clean up after itself in fullsize mode
        Array.from(
          document.querySelectorAll('.jodit_fullsize-box_true'),
        ).forEach((el) => {
          el.classList.remove('jodit_fullsize-box_true');
        });
      }

      modal.close(`${modalId}-add-media`);
      modal.close(`${modalId}-add-relation`);

      editor.current.waitForReady().then(() => {
        editor.current.destruct();
        editor.current = null;
      });
    },
    [modal, modalId],
  );

  const resizeObserverRef = useRef(
    new ResizeObserver(() => {
      if (editor.current) {
        editor.current.events.fire('resize');
      }
    }),
  );

  useEffect(() => {
    const divElement = joditWrapperRef.current;
    const observer = resizeObserverRef.current;
    if (observer && divElement) observer.observe(divElement);
    return () => {
      if (observer && divElement) observer.unobserve(divElement);
    };
  }, []);

  return (
    <div className={additionalClasses}>
      <label
        className="text-sm text-slate-400 mb-1"
        {...getTestProps(testId, 'label')}
      >
        {label}
        {required && <RequiredTemplate />}
      </label>
      <div
        ref={joditWrapperRef}
        className={twMerge(
          'jodit-wrapper',
          error && 'error',
          disabled && 'disabled',
          'dark:text-indigo-950',
        )}
        {...getTestProps(testId, 'jodit-container')}
      >
        <textarea ref={textarea} id="editor" name="editor" />
      </div>
      <HelpErrorTextsTemplate helpText={helpText} error={error} />
    </div>
  );
};

export default RichText;

RichText.propTypes = {
  /**
   * Rich Text value
   */
  value: PropTypes.string,
  /**
   * On chanage handler
   */
  onChange: PropTypes.func,
  /**
   * On blur handler
   */
  onBlur: PropTypes.func,
  /**
   * Label above the editor
   */
  label: PropTypes.string,
  /**
   * Error to display under the editor
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * If value is required
   */
  required: PropTypes.bool,
  /**
   * If the editor is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Help text to display under the editor
   */
  helpText: PropTypes.string,
  /**
   * On media upload callback
   */
  onMediaUpload: PropTypes.func,
  /**
   * Additional classes for editor
   */
  additionalClasses: PropTypes.string,

  /**
   * Jodit Editor additional config
   */
  editorConfig: PropTypes.object,
  /**
   * Rich Text test id
   */
  testId: PropTypes.string,
};

RichText.defaultProps = {
  value: '',
  onChange: /* istanbul ignore next */ () => null,
  onBlur: /* istanbul ignore next */ () => null,
  label: '',
  error: null,
  required: false,
  disabled: false,
  helpText: '',
  onMediaUpload: /* istanbul ignore next */ () => null,
  additionalClasses: '',
  editorConfig: {},
  testId: '',
};
