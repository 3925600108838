import { useCallback, useContext, useMemo, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useDrag, useDrop } from 'react-dnd';
import Card from '../../../components/Card/Card';
import Button from '../../../components/Button/Button';
import { formatErrorToString, getTestProps } from '../../../lib/helpers';
import { findIcon } from '../../../lib/ctdIcons';
import {
  ArrowUpThinIcon,
  CopyIcon,
  DeleteIcon,
  PencilIcon,
} from '../../../images/shapes';
import Tooltip from '../../../components/Tooltip/Tooltip';
import ListProperties from '../ListProperties/ListProperties';
import ContentTypeFormContext from '../../../contexts/ContentTypeFormContext';
import ListPropertiesContext from '../../../contexts/ListPropertiesContext';

const generateDescription = (fieldProps, isRequired, t) => {
  const items = [];
  if (isRequired) items.push(t('ContentTypeForm.Descriptions.Required'));
  if (fieldProps.unique) items.push(t('ContentTypeForm.Descriptions.Unique'));
  if (fieldProps.showTime)
    items.push(t('ContentTypeForm.Descriptions.ShowTimePicker'));
  if (fieldProps.readonly)
    items.push(t('ContentTypeForm.Descriptions.Readonly'));
  if (fieldProps.isTitlePart)
    items.push(t('ContentTypeForm.Descriptions.PartOfTitle'));
  if (fieldProps.hidden) items.push(t('ContentTypeForm.Descriptions.Hidden'));
  if (fieldProps.validation?.relationMultiple)
    items.push(t('ContentTypeForm.Descriptions.Multiple'));
  if (fieldProps.inputType === 'datasource') {
    const relationType = fieldProps.validation?.relationContenttype;
    const relationLabel =
      relationType === '_media'
        ? t('ContentTypeForm.Descriptions.Media')
        : relationType;
    items.push(
      t('ContentTypeForm.Descriptions.Relation', {
        to: relationLabel
          ? relationLabel
          : t('ContentTypeForm.Descriptions.AnyType'),
      }),
    );
  }
  if (fieldProps.useOptionsWithLabels)
    items.push(t('ContentTypeForm.Descriptions.LabelOptions'));
  if (!items.length) return;
  return items.join(' • ');
};

const DraggableCard = ({
  fieldName,
  fieldProps,
  fieldSchema,
  isRequired,
  idx,
  currentOrder,
  onDrop,
  onDelete,
  onEdit,
  onUp,
  onDown,
  onDuplicate,
  additionalContainerClasses,
  testId,
  parentInputType,
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const formik = useFormikContext();
  const inputType = useMemo(() => fieldProps?.inputType, [fieldProps]);
  const { propertiesErrors, disabled } = useContext(ContentTypeFormContext);
  const { highListName } = useContext(ListPropertiesContext);

  const propertyErrors = useMemo(
    () => propertiesErrors[fieldName],
    [propertiesErrors, fieldName],
  );

  const [, drop] = useDrop({
    accept: highListName ? `${highListName}-card` : 'card',
    canDrop: () => true,
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      if (monitor.canDrop() && !monitor.didDrop() && !monitor.isOver()) {
        onDrop(item.fieldName, fieldName, formik);
      }
    },
  });

  const [{ cardIsDragging, anyCardIsDragging }, drag] = useDrag({
    item: { fieldName, idx },
    type: highListName ? `${highListName}-card` : 'card',
    canDrag: () => !disabled,
    collect: (monitor) => {
      return {
        cardIsDragging: monitor.isDragging(),
        anyCardIsDragging: !!monitor.getItem(),
      };
    },
  });

  drag(drop(ref));

  const handleDelete = useCallback(
    () => onDelete(fieldName, formik),
    [fieldName, formik, onDelete],
  );

  const handleEdit = useCallback(
    () =>
      onEdit(
        fieldName,
        fieldProps,
        fieldSchema,
        isRequired,
        formik,
        undefined,
        parentInputType,
      ),
    [
      fieldName,
      fieldProps,
      formik,
      fieldSchema,
      isRequired,
      onEdit,
      parentInputType,
    ],
  );

  const handleOrderUp = useCallback(
    () => onUp(idx, formik),
    [idx, formik, onUp],
  );

  const handleOrderDown = useCallback(
    () => onDown(idx, formik),
    [idx, formik, onDown],
  );

  const handleDuplicate = useCallback(
    () =>
      onDuplicate(fieldName, fieldProps, fieldSchema, isRequired, formik, idx),
    [fieldName, fieldProps, fieldSchema, formik, idx, isRequired, onDuplicate],
  );

  const defaultIconClasses = 'h-3.5 text-gray-700 opacity-50';

  const globalPropError = useMemo(() => {
    const error = [];
    if (propertyErrors?.global) {
      error.push(propertyErrors?.global);
    }
    if (propertyErrors?.config?.global) {
      error.push(propertyErrors?.config?.global);
    }
    if (propertyErrors?.schema?.global) {
      error.push(propertyErrors?.schema?.global);
    }
    return error;
  }, [propertyErrors]);

  return (
    <div>
      <div
        className={twMerge(
          'bg-slate-50 dark:bg-slate-800 w-full rounded-lg border border-slate-200 dark:border-slate-800',
          'duration-250 transition ease-in-out',
          !disabled && 'cursor-grab',
          cardIsDragging && 'opacity-50 !cursor-grabbing shadow-lg ',
          !(anyCardIsDragging && !cardIsDragging) && 'hover:shadow-lg',
          propertyErrors && 'border border-red',
          additionalContainerClasses,
        )}
        ref={ref}
      >
        <Card
          thumbnail={findIcon(fieldProps)}
          body={
            <div className="flex flex-wrap sm:flex-nowrap pl-3 text-slate-400 h-full justify-between items-center">
              <div className="overflow-hidden max-w-fit grid">
                <p
                  className={twMerge(
                    'text-indigo-950 dark:text-white text-lg font-medium leading-5',
                    'line-clamp-1 py-0.5 break-all pr-2',
                  )}
                  {...getTestProps(testId, 'card-label')}
                >
                  {fieldProps.label}
                </p>
                <div
                  className={'mt-1 empty:hidden'}
                  {...getTestProps(testId, 'card-description')}
                >
                  {generateDescription(fieldProps, isRequired, t)}
                </div>
              </div>
              <div className="flex items-center gap-2">
                {currentOrder.length > 1 && (
                  <div className="inline-flex items-center dark:text-white gap-2">
                    <Button
                      onClick={handleOrderUp}
                      iconImage={
                        <ArrowUpThinIcon
                          className={twMerge(
                            'dark:text-gray-50',
                            defaultIconClasses,
                            !(disabled || idx === 0) && 'hover:opacity-100',
                          )}
                        />
                      }
                      buttonColor="borderless"
                      noPaddings
                      disabled={disabled || idx === 0}
                      additionalClasses="h-fit w-fit"
                      {...getTestProps(
                        testId,
                        `${fieldName}-up-icon`,
                        'testId',
                      )}
                    />
                    <Button
                      onClick={handleOrderDown}
                      iconImage={
                        <ArrowUpThinIcon
                          className={twMerge(
                            'rotate-180 dark:text-gray-50',
                            defaultIconClasses,
                            !(disabled || idx === currentOrder.length - 1) &&
                              'hover:opacity-100',
                          )}
                        />
                      }
                      buttonColor="borderless"
                      noPaddings
                      disabled={disabled || idx === currentOrder.length - 1}
                      additionalClasses="h-fit w-fit"
                      {...getTestProps(
                        testId,
                        `${fieldName}-down-icon`,
                        'testId',
                      )}
                    />
                  </div>
                )}
                <div className="inline-flex items-center gap-2">
                  <Button
                    onClick={handleEdit}
                    iconImage={
                      <PencilIcon
                        className={twMerge(
                          'dark:text-gray-50',
                          defaultIconClasses,
                          !disabled && 'hover:opacity-100',
                        )}
                      />
                    }
                    buttonColor="borderless"
                    noPaddings
                    disabled={disabled}
                    additionalClasses="h-fit w-fit"
                    {...getTestProps(
                      testId,
                      `${fieldName}-edit-icon`,
                      'testId',
                    )}
                  />
                  <Button
                    onClick={handleDelete}
                    iconImage={
                      <DeleteIcon
                        className={twMerge(
                          'dark:text-red-300',
                          defaultIconClasses,
                          !disabled && 'hover:opacity-100',
                        )}
                      />
                    }
                    buttonColor="borderless"
                    noPaddings
                    disabled={disabled}
                    additionalClasses="h-fit w-fit"
                    {...getTestProps(
                      testId,
                      `${fieldName}-delete-icon`,
                      'testId',
                    )}
                  />
                  <Tooltip tooltip={t('ContentTypeForm.DuplicateProperty')}>
                    <Button
                      onClick={handleDuplicate}
                      iconImage={
                        <CopyIcon
                          className={twMerge(
                            'dark:text-gray-50',
                            defaultIconClasses,
                            !disabled && 'hover:opacity-100',
                          )}
                        />
                      }
                      buttonColor="borderless"
                      noPaddings
                      disabled={disabled}
                      additionalClasses="h-fit w-fit"
                      {...getTestProps(
                        testId,
                        `${fieldName}-duplicate-icon`,
                        'testId',
                      )}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          }
          darkenOnHover={false}
          additionalContainerClasses={twMerge(
            'bg-transparent w-full p-4 pr-7 overflow-visible !shadow-none',
          )}
          additionalImageContainerClasses="w-fit min-w-fit rounded"
          additionalBodyClasses="w-full"
          additionalThumbnailClasses="w-fit"
          horizontal
          {...getTestProps(testId, fieldName, 'testId')}
        />
        {inputType === 'object' && (
          <ListProperties
            additionalClasses="dark:bg-slate-950"
            listName={fieldName}
            listPropertiesItems={fieldProps.items}
            listSchemaItems={fieldSchema.items}
            parentInputType={
              parentInputType ? `${parentInputType}_${inputType}` : inputType
            }
            isOpen
            {...getTestProps(testId, `${fieldName}-properties`, 'testId')}
          />
        )}
      </div>
      {globalPropError?.length > 0 && (
        <div
          className="text-red text-sm mb-2"
          {...getTestProps(testId, 'global-prop-error')}
        >
          {formatErrorToString(globalPropError)}
        </div>
      )}
    </div>
  );
};

export default DraggableCard;

DraggableCard.propTypes = {
  /**
   * Name of the property
   */
  fieldName: PropTypes.string.isRequired,
  /**
   * Property config
   */
  fieldProps: PropTypes.shape({
    label: PropTypes.string,
    inputType: PropTypes.string,
    items: PropTypes.shape({}),
  }),
  /**
   * Property schema
   */
  fieldSchema: PropTypes.shape({ items: PropTypes.shape({}) }),
  /**
   * If property is required
   */
  isRequired: PropTypes.bool,
  /**
   * Index of the card
   */
  idx: PropTypes.number.isRequired,
  /**
   * Order of content type metaDefinition
   */
  currentOrder: PropTypes.array,
  /**
   * On card drop
   */
  onDrop: PropTypes.func.isRequired,
  /**
   * On delete icon click
   */
  onDelete: PropTypes.func,
  /**
   * On duplicate icon click
   */
  onDuplicate: PropTypes.func,
  /**
   * On edit icon click
   */
  onEdit: PropTypes.func,
  /**
   * On up icon click
   */
  onUp: PropTypes.func,
  /**
   * On down icon click
   */
  onDown: PropTypes.func,
  /**
   * Additional CSS classes for draggable card container
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Test id for property card
   */
  testId: PropTypes.string,
};

DraggableCard.defaultProps = {
  fieldProps: {},
  fieldSchema: {},
  isRequired: false,
  currentOrder: [],
  onDelete: /* istanbul ignore next */ () => null,
  onEdit: /* istanbul ignore next */ () => null,
  onUp: /* istanbul ignore next */ () => null,
  onDown: /* istanbul ignore next */ () => null,
  onDuplicate: /* istanbul ignore next */ () => null,
  additionalContainerClasses: '',
  testId: '',
};
