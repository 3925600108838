import { toast } from 'react-hot-toast';
import {
  getContentObjects,
  listContentTypes,
  postContentObject,
  postMedia,
  postOrder,
  putUserConfig,
  updateMedia,
} from './index';
import {
  formatErrorToString,
  getDefaultImageProperties,
  isImagePreviewSupported,
} from '../helpers';
import { ResponseError, checkResponseStatus } from './response-errors';
import { resetPasswordRequest } from './auth-requests';
import TagManager from 'react-gtm-module';

const apiUrl = process.env.REACT_APP_FLOTIQ_API_URL;

export const defaultToDo = {
  publicDoc: {
    title: 'Visit api documentation',
    status: 'todo',
  },
  contentType: {
    title: 'Add content type definition',
    status: 'todo',
  },
  contentObject: {
    title: 'Add content object',
    status: 'todo',
  },
  gatsbyStarters: {
    title: 'Try our starter projects',
    status: 'todo',
  },
};

/**
 * Generate url to media with specified parameters
 * @param {object} mediaData
 * @param {number} [desiredWidth=0]
 * @param {number} [desiredHeight=0]
 * @param {string} [variantName='']
 * @returns {string} url for the media
 */
export function getMediaUrl(
  mediaData,
  desiredWidth = 0,
  desiredHeight = 0,
  variantName = '',
) {
  const url = `${apiUrl.replace(/\/{1,10}$/, '')}/image`;
  const size =
    mediaData?.type !== 'image' ? '0x0' : `${desiredWidth}x${desiredHeight}`;

  let path = `${url}/${size}/${mediaData.id}`;

  if (
    process.env.REACT_APP_ADD_FILENAME_TO_MEDIA_URL.split(',').join(',') ===
      'true' &&
    !variantName
  ) {
    path += `/${encodeURIComponent(mediaData.fileName).replace(
      /\.(?=.*\.)/g,
      '-',
    )}`;
  } else {
    path += `.${mediaData.extension}`;
  }

  if (variantName) {
    path += `/variant/${variantName}`;
  }

  return path;
}

/**
 * Generate url to media with specified transform
 * @param {object} mediaData
 * @param left
 * @param top
 * @param transformWidth
 * @param transformHeight
 * @param {number} [desiredWidth=0]
 * @param {number} [desiredHeight=0]
 * @returns {string} url for the media
 */
export function getMediaTransformUrl(
  mediaData,
  left,
  top,
  transformWidth,
  transformHeight,
  desiredWidth = 0,
  desiredHeight = 0,
) {
  if (mediaData?.type !== 'image') return;

  const url = `${apiUrl.replace(/\/{1,10}$/, '')}/image`;
  const size = `${desiredWidth}x${desiredHeight}`;

  const path = `${url}/${size}/${mediaData.id}.${mediaData.extension}`;

  return `${path}/transform/trim/${top},${left},${transformWidth},${transformHeight}`;
}

/**
 * Update user tasks list
 * @param {string} taskName
 * @param {object} user
 * @param {(jwt: string,?string: space, params: object) => Promise<void>} updateUserConfig
 * @param {string} jwt
 * @param {string} [contentTypeSlug='']
 */
export async function markTaskAsDone(
  taskName,
  user,
  jwt,
  setUser,
  contentTypeSlug = '',
) {
  try {
    const toDoList = JSON.parse(JSON.stringify(defaultToDo));
    if (!user?.data) return;

    const newConfig =
      user.data.config && !Array.isArray(user.data.config)
        ? { ...user.data.config }
        : {};

    if (!newConfig.todo) {
      newConfig.todo = toDoList;
    }

    if (!newConfig.todo[taskName]) {
      if (!toDoList[taskName]) {
        throw new Error(`Task doesn't exists ${taskName}`);
      }
      newConfig.todo[taskName] = toDoList[taskName];
    }

    const status = newConfig.todo[taskName].status;
    if (status === 'done' && !contentTypeSlug) return;

    newConfig.todo[taskName].status = 'done';
    if (contentTypeSlug)
      newConfig.todo[taskName].contentTypeSlug = contentTypeSlug;

    const { ok } = await putUserConfig(jwt, null, {
      id: user.data.id,
      config: newConfig,
    });

    if (setUser && ok)
      setUser({ ...user, data: { ...user.data, config: newConfig } });
  } catch (e) {
    console.warn(e);
  }
}

/**
 * Uploads file and handles upload errors
 * @param {string} jwt User JWT token
 * @param {string} space
 * @param {File} file
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<[result: any, errors?: string]>} Array containing result on first element and errors on the second
 */
export async function uploadFile(jwt, space, file, t) {
  if (!file) return;
  try {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', file.type.includes('image') ? 'image' : 'file');
    const { body, ok } = await postMedia(
      jwt,
      space,
      {},
      {
        skipContentType: true,
        body: formData,
      },
    );
    if (!ok) {
      throw new Error(formatErrorToString(body));
    }
    const showImages = isImagePreviewSupported(body.extension);

    toast(t('Media.OnAddSuccess', { name: body.fileName }), {
      icon: (
        <div className="h-12 w-12 mr-2">
          {body.type === 'image' && showImages ? (
            <img
              src={getMediaUrl(body, 150)}
              alt={t('Media.OnAddSuccess', { name: body.fileName })}
              className="h-full w-full object-center object-cover"
              {...getDefaultImageProperties()}
            />
          ) : (
            <div className="flex items-center justify-center rounded-full h-full w-full bg-blue-300">
              {body.extension}
            </div>
          )}
        </div>
      ),
    });
    return [body, ''];
  } catch (e) {
    const error = e.message || t('Media.OnErrorMessage');
    toast.error(error);
    return [file, error];
  }
}

/**
 * Uploads array of files
 * @param {string} jwt User JWT token
 * @param space
 * @param {FileList} files
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<[result: any, errors?: string][]>} array of uploaded media
 */
export async function uploadFiles(jwt, space, files, t) {
  if (!files || !files.length) return;
  const uploadedMedia = [];
  await Promise.all(
    Array.from(files).map(async (file) => {
      const media = await uploadFile(jwt, space, file, t);
      uploadedMedia.push(media);
    }),
  );
  return uploadedMedia;
}

/**
 * Filter tags data that contains the query
 * @param {string} jwt User JWT token
 * @param {string} space
 * @param {string} query
 * @param {(key: string, options?: object) => string} t Translation function
 * @param {object} tagsParams
 * @returns {Promise.<object[]>} array of tags data
 */
export async function getTagsFromQuery(jwt, space, query, t, tagsParams) {
  const tagQueryParams = {
    ...tagsParams,
    contentTypeName: '_tag',
    filters: JSON.stringify({
      name: { filter: query, type: 'contains' },
    }),
  };
  let newOptions = [];
  try {
    const { body, status } = await getContentObjects(
      jwt,
      space,
      tagQueryParams,
    );
    checkResponseStatus(body, status);
    newOptions = body.data || [];
  } catch (error) {
    toast.error(
      t(
        error instanceof ResponseError
          ? 'TagsManagement.CouldntFetch'
          : 'Form.CommunicationErrorMessage',
      ),
    );
  }
  return newOptions;
}

/**
 * Handle create/update CTO errors
 * @param error
 * @param values
 * @param t
 * @param createCTO
 */
export const handleCTOErrors = (error, values, t, createCTO = false) => {
  if (!(error instanceof ResponseError)) {
    toast.error(t('Form.CommunicationErrorMessage'));
    return [[values, {}], true];
  }

  if (error.message?.includes('__webhook')) {
    toast.error(`${t('Webhooks.Errors.Webhook')}: ${error.message}`);
    return [[values, {}], true];
  }

  if (error.status === 413) {
    toast.error(t('ContentForm.Errors.ContentToLarge'));
    return [[values, error.errors], true];
  }

  if (createCTO) {
    toast.error(
      error.message
        ? t('ContentForm.Errors.TryAgain')
        : t('ContentForm.CouldntAdd'),
    );
    return [[values, error.errors], true];
  }

  toast.error(
    error.message
      ? t('ContentForm.Errors.TryAgain')
      : t('ContentForm.CouldntSave'),
  );
  return [[values, error.errors], true];
};

/**
 * Update content type
 * @param {object} values
 * @param {string} contentTypeName
 * @param {(contentObject: object) => Promise<void>} updateContentObject
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<[[result: object, erors: object], failed: boolean]>}
 * updating result that contains new ctd values, fetch errors and booleand value if request failed
 */
export async function updateCTO(
  values,
  contentTypeName,
  updateContentObject,
  t,
) {
  try {
    const { status, body } = await updateContentObject({
      ...values,
      contentTypeName,
    });
    checkResponseStatus(body, status);
    toast.success(t('ContentForm.Saved'));

    TagManager.dataLayer({
      dataLayer: {
        event: 'co_change',
      },
    });

    return [[body, {}], false];
  } catch (error) {
    return handleCTOErrors(error, values, t);
  }
}

/**
 * Save new content type
 * @param {string} jwt User JWT token
 * @param {string} space
 * @param {object} values
 * @param {string} contentTypeName
 * @param {object} user
 * @param {(jwt: string, ?string: space, user: object) => Promise<void>} updateUserConfig
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<[[result: object, erors: object], failed: boolean]>}
 * saving result that contains new ctd values, fetch errors and booleand value if request failed
 */
export async function saveNewCTO(
  jwt,
  space,
  values,
  contentTypeName,
  user,
  setUser,
  t,
) {
  try {
    const { body, status } = await postContentObject(jwt, space, {
      ...values,
      contentTypeName,
    });
    checkResponseStatus(body, status);
    toast.success(t('ContentForm.Added'));

    TagManager.dataLayer({
      dataLayer: {
        event: 'co_change',
      },
    });

    markTaskAsDone('contentObject', user, jwt, setUser);
    return [[body, {}], false];
  } catch (error) {
    return handleCTOErrors(error, values, t, true);
  }
}

/**
 * Send change password request
 * @param {string} email
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<object>} response errors
 */
export async function changePasswordRequest(email, t) {
  try {
    const { body, status } = await resetPasswordRequest(email);
    checkResponseStatus(body, status);
    toast.success(t('Form.FormChangePasswordRequestSuccess'), {
      duration: 10000,
    });
    return null;
  } catch (error) {
    if (!(error instanceof ResponseError)) {
      toast.error(t('Form.CommunicationErrorMessage'));
      return {
        general: t('Form.CommunicationErrorMessage'),
        ...error.errors,
      };
    }
    const errorMessage = error.errors?.form
      ? t('Form.FormErrorEmailSent')
      : error.message;
    const finalError = errorMessage ? errorMessage : t('Form.ValidationError');
    toast.error(finalError);
    return {
      general: finalError,
      ...error.errors,
    };
  }
}

/**
 * Get ctds data from query
 * @param {string} jwt User JWT token
 * @param {string} space
 * @param params
 * @param {(key: string, options?: object) => string} t Translation function
 * @returns {Promise.<Array.<object>>} A list of filtered content types
 */
export async function getCtdsFromQuery(jwt, space, params, t) {
  try {
    const { body, status } = await listContentTypes(jwt, space, params);
    checkResponseStatus(body, status);
    return body.data || [];
  } catch (error) {
    toast.error(
      t(
        error instanceof ResponseError
          ? 'ContentForm.CouldntFetch'
          : 'Form.CommunicationErrorMessage',
      ),
    );
    return [];
  }
}

/**
 * Patch media
 * @param {string} jwt User JWT token
 * @param {string} space
 * @param {string} id Media id
 * @param {object} values New media values
 * @param t
 * @param successText
 * @param errorText
 * @returns {Promise.<[[values: object, errors: object], hasErrors: boolean]>}
 * A list of values, errors and boolean value if contains errors
 */
export async function patchMedia(
  jwt,
  space,
  id,
  values,
  t,
  successText = '',
  errorText = '',
) {
  try {
    const { status, body } = await updateMedia(jwt, space, {
      mediaId: id,
      ...values,
    });
    checkResponseStatus(body, status);
    toast.success(successText || t('MediaEdit.Updated'));

    return [[body, {}], false];
  } catch (error) {
    if (!(error instanceof ResponseError)) {
      toast.error(t('Form.CommunicationErrorMessage'));
      return [[values, {}], true];
    }

    toast.error(error.message || errorText || t('MediaEdit.CouldntUpdate'));

    return [[values, error.errors], true];
  }
}

/**
 * Handle manage subscription button
 * @param token
 * @param t
 * @returns {Promise<void>}
 */
export async function manageSubscription(token, t) {
  try {
    const { body } = await postOrder(token, undefined, {
      uri: `${process.env.PUBLIC_URL || window.origin}/organization`,
    });
    if (body.url) {
      window.open(body.url, '_blank', 'noopener');
    }
  } catch (e) {
    toast.error(t('Form.CommunicationErrorMessage'));
  }
}
