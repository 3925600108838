import { getToken } from '../../hooks/useToken';
import {
  deleteContentObject,
  getContentObject,
  getContentObjects,
  getContentType,
  listContentTypes,
  patchContentObject,
  postContentObject,
  putContentObject,
} from '../flotiq-client';
import { RolePermissions } from '../rolePermissions';
import { getMediaUrl } from '../flotiq-client/api-helpers';

class FlotiqScopedApiClient {
  #permissions;
  #emptyPermissions;

  constructor(permissions) {
    this.#permissions = new RolePermissions(permissions);
    this.#emptyPermissions = !permissions || !permissions.length;
  }

  getObject(type, id) {
    if (this.#emptyPermissions || !this.#permissions.canCo(type))
      throw new Error(
        `Plugin does not have permission to read ${type} objects`,
      );

    return getContentObject(getToken(), undefined, {
      contentTypeName: type,
      id,
    });
  }

  listObjects(type, query) {
    if (this.#emptyPermissions || !this.#permissions.canCo(type))
      throw new Error(
        `Plugin does not have permission to read ${type} objects`,
      );

    return getContentObjects(getToken(), undefined, {
      contentTypeName: type,
      ...query,
    });
  }

  postObject(type, object) {
    if (
      this.#emptyPermissions ||
      !this.#permissions.canCo(type, RolePermissions.PERMISSIONS_TYPES.CREATE)
    )
      throw new Error(
        `Plugin does not have permission to create ${type} object`,
      );

    return postContentObject(getToken(), undefined, {
      contentTypeName: type,
      ...object,
    });
  }

  putObject(type, id, object) {
    if (
      this.#emptyPermissions ||
      !this.#permissions.canCo(type, RolePermissions.PERMISSIONS_TYPES.UPDATE)
    )
      throw new Error(
        `Plugin does not have permission to update ${type} objects`,
      );

    return putContentObject(getToken(), undefined, {
      id,
      contentTypeName: type,
      ...object,
    });
  }

  patchObject(type, id, object) {
    if (
      this.#emptyPermissions ||
      !this.#permissions.canCo(type, RolePermissions.PERMISSIONS_TYPES.UPDATE)
    )
      throw new Error(
        `Plugin does not have permission to update ${type} objects`,
      );

    return patchContentObject(getToken(), undefined, {
      id,
      contentTypeName: type,
      ...object,
    });
  }

  deleteObject(type, id) {
    if (
      this.#emptyPermissions ||
      !this.#permissions.canCo(type, RolePermissions.PERMISSIONS_TYPES.DELETE)
    )
      throw new Error(
        `Plugin does not have permission to delete ${type} objects`,
      );

    return deleteContentObject(getToken(), undefined, {
      id,
      contentTypeName: type,
    });
  }

  getContentTypes(params) {
    if (this.#emptyPermissions || !this.#permissions.canCtd('*'))
      throw new Error(
        'Plugin does not have permission to read all definition types',
      );

    return listContentTypes(getToken(), undefined, params);
  }

  getContentType(type) {
    if (this.#emptyPermissions || !this.#permissions.canCtd(type))
      throw new Error(
        `Plugin does not have permission to read ${type} definition type`,
      );

    return getContentType(getToken(), undefined, type);
  }

  getMediaUrl(mediaData, height = 0, width = 0) {
    if (this.#emptyPermissions || !this.#permissions.canCo('_media'))
      throw new Error(`Plugin does not have permission to read media`);

    return getMediaUrl(mediaData, width, height);
  }
}

export { FlotiqScopedApiClient };
