import PropTypes from 'prop-types';
import {
  checkboxRenderer,
  dateRenderer,
  defaultRenderer,
  geoRenderer,
  objectRenderer,
  webhookActionsRenderer,
  pillRenderer,
  relationRenderer,
} from './cellRenderer';
import ElementFromPlugin from '../../ElementFromPlugin/ElementFromPlugin';
import { GridRenderFieldEvent } from '../../../lib/flotiq-plugins/plugin-events/GridRenderFieldEvent';
import usePluginResults from '../../../hooks/usePluginResults';

export const INPUT_TYPES_TO_RENDER = [
  'checkbox',
  'geo',
  'radio',
  'select',
  'dateTime',
  'datePublished',
  'datasource',
  'text',
  'email',
  'number',
  'object',
  'webhookActions',
];

/**
 * @emits FlotiqPlugins."flotiq.grid.cell::render"
 */
const DataGridCell = ({
  inputType,
  data,
  onClickCallback,
  items,
  color,
  contentTypeName,
  accessor,
  contentObject,
  contentType,
  testId,
}) => {
  /**
   * @emits FlotiqPlugins."flotiq.grid.cell::render"
   */
  const results = usePluginResults(
    'flotiq.grid.cell::render',
    GridRenderFieldEvent,
    {
      inputType,
      accessor,
      data,
      contentTypeName,
      contentObject,
      contentType,
    },
  );

  if (results && results.length > 0) {
    return <ElementFromPlugin results={results} />;
  }

  switch (inputType) {
    case 'checkbox': {
      return checkboxRenderer(data);
    }
    case 'geo': {
      return geoRenderer(data);
    }
    case 'radio':
    case 'select': {
      return pillRenderer(data, color);
    }
    case 'webhookActions': {
      return webhookActionsRenderer(data, color, testId);
    }
    case 'dateTime': {
      return dateRenderer(data);
    }
    case 'datePublished': {
      return dateRenderer(data);
    }
    case 'datasource':
      return relationRenderer(data, onClickCallback);
    case 'object': {
      return objectRenderer(data, items);
    }
    case 'text':
    case 'email':
    case 'number':
    default:
      return defaultRenderer(data);
  }
};

export default DataGridCell;

DataGridCell.propTypes = {
  /**
   * Input type to render
   */
  inputType: PropTypes.string,
  /**
   * Data to render
   */
  data: PropTypes.any,
  /**
   * On data cell click callback
   */
  onClickCallback: PropTypes.func,
  /**
   * Items from properties config for cell data
   */
  items: PropTypes.shape({}),
  /**
   * Pill color for select and radio input types
   */
  color: PropTypes.string,
  /**
   * Cell content type name
   */
  contentTypeName: PropTypes.string,
  /**
   * Cell accessor
   */
  accessor: PropTypes.string,
  /**
   * Cell content object data
   */
  contentObject: PropTypes.shape({}),
  /**
   * Cell content type deifinition
   */
  contentType: PropTypes.shape({}),
};

DataGridCell.defaultProps = {
  inputType: '',
  data: '',
  onClickCallback: /* istanbul ignore next */ () => {},
  items: {},
  contentTypeName: '',
  accessor: '',
  contentObject: {},
  contentType: {},
};
