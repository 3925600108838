import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { ResponseError } from '../../lib/flotiq-client/response-errors';

/**
 * Decides whether or not to show api response error in toasts.
 *
 * @param {Error} errors
 */
const useApiErrorsToast = (errors) => {
  useEffect(() => {
    if (!errors || !errors.length > 0) return;
    errors
      .filter((er) => er instanceof ResponseError)
      .map((error) => {
        const toastId = toast.error(error.message);
        return toastId;
      });
  }, [errors]);
};

export default useApiErrorsToast;
