import { useCallback, useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Combobox } from '@headlessui/react';
import { toast } from 'react-hot-toast';

// :: Components
import Button from '../../../components/Button/Button';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Loader from '../../../components/Loader/Loader';

// :: Lib
import { createTag } from '../../../lib/flotiq-client';
import { getTagUrl, getTestProps } from '../../../lib/helpers';
import {
  checkResponseStatus,
  ResponseError,
} from '../../../lib/flotiq-client/response-errors';
import { getTagsFromQuery } from '../../../lib/flotiq-client/api-helpers';
import { RolePermissions } from '../../../lib/rolePermissions';

// :: Contexts
import { ModalInstanceContext } from '../../../contexts/ModalContext';
import UserContext from '../../../contexts/UserContext';

// :: Hooks
import useToken from '../../../hooks/useToken';
import { useMediaTags } from '../../../hooks/api';
import useSpace from '../../../hooks/useSpace';

const TagsManagementModal = ({ testId, media, handleTagUpdate }) => {
  const { t } = useTranslation();
  const jwt = useToken();
  const { space } = useSpace();
  const modalInstance = useContext(ModalInstanceContext);
  const { permissions } = useContext(UserContext);

  const [tagNameError, setTagNameError] = useState('');

  const canCreate = permissions.canCo(
    '_tag',
    RolePermissions.PERMISSIONS_TYPES.CREATE,
  );

  const tagsParams = useMemo(() => {
    return {
      page: 1,
      limit: 20,
      order_by: 'name',
    };
  }, []);

  const { data: tagData, isLoading, reload } = useMediaTags(tagsParams);
  const tagsData = useMemo(() => {
    return tagData.map((element) => ({
      value: element.id,
      label: element.name,
    }));
  }, [tagData]);

  const mediaTags = useMemo(
    () =>
      media.length === 1 && media[0]?.tags
        ? media[0]?.tags?.map((tag) => tag.id)
        : [],
    [media],
  );

  const extraOptions = useMemo(() => {
    return media.length === 1 && media[0]?.tags
      ? media[0]?.tags?.map((tag) => ({
          value: tag.id,
          label: tag.name,
        }))
      : [];
  }, [media]);

  const [selectedTags, setSelectedTags] = useState(mediaTags || []);
  const [isSavingTags, setIsSavingTags] = useState(false);

  const updateTags = useCallback(async () => {
    setIsSavingTags(true);
    const newTags = selectedTags.map((element) => ({
      dataUrl: getTagUrl(element),
      type: 'internal',
    }));
    await handleTagUpdate(media, newTags);
    setIsSavingTags(false);
    modalInstance.resolve();
  }, [handleTagUpdate, media, modalInstance, selectedTags]);

  const handleTagCreate = useCallback(
    async (newTag) => {
      setIsSavingTags(true);
      try {
        const { body, status } = await createTag(jwt, space, {
          id: newTag,
          name: newTag,
        });
        checkResponseStatus(body, status);
        toast.success(t('TagsManagement.AddTagSuccess'));
        reload();
      } catch (error) {
        if (error instanceof ResponseError) {
          toast.error(error.message);
        } else {
          toast.error(t('Form.CommunicationErrorMessage'));
        }
        setSelectedTags((prevSelected) => {
          const newSelected = [...prevSelected];
          const index = newSelected.findIndex((element) => element === newTag);
          if (index > -1) newSelected.splice(index, 1);
          return newSelected;
        });
      }
      setIsSavingTags(false);
      return null;
    },
    [jwt, space, reload, t],
  );

  const handleTagCreateOption = useCallback(
    (query) => {
      if (!canCreate) return;

      if (query.match(/^\w+$/)) {
        return (
          <Combobox.Option
            className="relative cursor-default select-none py-2 pl-4 hover:bg-blue-300"
            value={query}
            key={'createTag'}
            onClick={() => handleTagCreate(query)}
            {...getTestProps('test', 'create-tag-option')}
          >
            {t('TagsManagement.CreateTag', { query })}
          </Combobox.Option>
        );
      }
    },
    [canCreate, handleTagCreate, t],
  );

  const customFilters = useCallback(
    async (query, _, setIsLoading) => {
      if (!query.match(/^\w+$/)) {
        setTagNameError(t('TagsManagement.TagInvalidName'));
        return [];
      }
      setTagNameError('');
      setIsLoading(true);
      const newOptions = await getTagsFromQuery(
        jwt,
        space,
        query,
        t,
        tagsParams,
      );
      setIsLoading(false);
      return newOptions.map((element) => ({
        value: element.id,
        label: element.name,
      }));
    },
    [jwt, space, t, tagsParams],
  );

  return (
    <div className="pb-[7rem]" {...getTestProps(testId, `container`)}>
      <Dropdown
        placeholder={t('TagsManagement.SelectNewTags')}
        options={tagsData}
        emptyOptions={<div className="py-2 px-4">{t('Media.EmptyTags')}</div>}
        value={selectedTags}
        onChange={(event) => setSelectedTags(event.target.value)}
        onBlur={() => setTagNameError('')}
        renderEmpty={handleTagCreateOption}
        renderOnEmptyMatch={handleTagCreateOption}
        additionalOptionsClasses="!py-1.5 "
        debounceTime={500}
        nullable
        multiple
        ignoreNotFound
        isDataLoading={isLoading}
        filterCallback={customFilters}
        error={tagNameError}
        extraOptions={extraOptions}
        {...getTestProps(testId, `add-tag`, 'testId')}
      />

      <div
        className="w-full fixed left-0 bottom-0 flex items-center justify-center p-3 space-x-5
        border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900 rounded-b-lg"
      >
        <Button
          onClick={() => modalInstance.resolve(null)}
          disabled={isSavingTags}
          buttonSize="sm"
          buttonColor="grayBordered"
        >
          {t('Global.Cancel')}
        </Button>
        <Button
          onClick={updateTags}
          disabled={isSavingTags}
          buttonSize="sm"
          iconImage={
            isSavingTags ? <Loader type={'spinner-grid'} size={'tiny'} /> : null
          }
        >
          {t('Global.Save')}
        </Button>
      </div>
    </div>
  );
};

TagsManagementModal.propTypes = {
  /**
   * Test id for MediaManagementModal
   */
  testId: PropTypes.string,

  /**
   * Array with selected media data
   */
  media: PropTypes.array,

  /**
   * Handle tag update callback
   */
  handleTagUpdate: PropTypes.func,
};

export default TagsManagementModal;
