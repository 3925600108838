import { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Component
import Tooltip from '../Tooltip/Tooltip';

const formatDate = (date, dateFormat) => {
  if (date && !dateFormat) {
    return moment(date).fromNow();
  } else if (date && dateFormat) {
    return moment(date).format(dateFormat);
  } else {
    return '-';
  }
};

const DateContainer = ({
  date,
  dateFormat,
  icon,
  text,
  isSemibold,
  testId,
  additionalClasses,
  additionalTextClasses,
  additionalSemiboldTextClasses,
  isCompact,
}) => {
  const dateElement = useMemo(
    () => formatDate(date, dateFormat),
    [date, dateFormat],
  );

  const titleElement = useMemo(() => {
    if (isCompact) return null;
    if (date) {
      return moment(date).format('LLL');
    } else {
      return '-';
    }
  }, [date, isCompact]);

  return (
    <div
      className={twMerge(
        'flex items-center text-indigo-950 dark:text-gray-200',
        additionalClasses,
      )}
      title={titleElement}
      {...getTestProps(testId, `container`)}
    >
      {icon && (
        <Tooltip
          tooltip={`${text} ${dateElement}`}
          tooltipPlacement={'leftCenter'}
          phoneTooltipPlacement={'leftCenter'}
          additionalTooltipClasses={twMerge('hidden', isCompact && 'lg:block')}
          {...getTestProps(testId, `tootltip`, 'testId')}
        >
          <div className="w-fit" {...getTestProps(testId, 'icon')}>
            {icon}
          </div>
        </Tooltip>
      )}

      <span
        className={twMerge(isCompact && 'lg:hidden', additionalTextClasses)}
        {...getTestProps(testId, `text`)}
      >
        {text}
      </span>

      <span
        className={twMerge(
          isCompact && 'lg:hidden',
          isSemibold &&
            `font-semibold mx-1 truncate ${additionalSemiboldTextClasses}`,
        )}
        {...getTestProps(testId, `date-container`)}
      >
        {dateElement}
      </span>
    </div>
  );
};

export default DateContainer;

DateContainer.propTypes = {
  /**
   * Date to render
   */
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    momentPropTypes.momentObj,
  ]),
  /**
   * Icon to show before date
   */
  icon: PropTypes.node,
  /**
   * Text to show before date and after icon
   */
  text: PropTypes.node,
  /**
   * If date should be semibold
   */
  isSemibold: PropTypes.bool,
  /**
   * Test id for date container
   */
  testId: PropTypes.string,
  /**
   * Additional CSS classes to be applied to the date container
   */
  additionalClasses: PropTypes.string,
  /**
   * Additional CSS classes to be applied to the text shown before date
   */
  additionalTextClasses: PropTypes.string,
  /**
   * Component in compacted mode. No text, only icon with tooltip.
   */
  isCompact: PropTypes.bool,
};

DateContainer.defaultProps = {
  date: '',
  dateFormat: '',
  icon: '',
  text: '',
  isSemibold: true,
  testId: '',
  additionalClasses: '',
  additionalTextClasses: '',
  additionalSemiboldTextClasses: '',
  isCompact: false,
};
