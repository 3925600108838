import { deepFreeze } from '../../../lib/helpers';
import {
  MIN_PROPERTY,
  PROPERTIES_FIELDS,
} from '../PropertiesSettings/propertiesFields';
import moment from 'moment';

const TYPES = {
  text: 'string',
  textarea: 'string',
  textMarkdown: 'string',
  email: 'string',
  richtext: 'string',
  number: 'number',
  radio: 'string',
  checkbox: 'boolean',
  select: 'string',
  datasource: 'array',
  object: 'array',
  geo: 'object',
  dateTime: 'string',
  block: 'object',
};

export const datePattern =
  // eslint-disable-next-line max-len
  '^$|^([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))T?(([0-1]?[0-9]|2[0-3]):[0-5][0-9])?(:[0-5][0-9])?(\\.[0-9]{3})?(Z|([\\+-]\\d{2}(:\\d{2})?))?$';

export const DEFAULT_SCHEMA = deepFreeze({
  object: { items: { properties: {}, required: [], type: 'object' } },
  email: { pattern: '^$|^[a-zA-Z0-9_.+-]+@[a-zA-Z_.+-]+?\\.[a-zA-Z]{2,}$' },
  datasource: { items: { $ref: '#/components/schemas/DataSource' } },
  geo: {
    additionalProperties: false,
    properties: {
      lat: {
        type: 'number',
        maximum: 90,
        minimum: -90,
      },
      lon: {
        type: 'number',
        maximum: 180,
        minimum: -180,
      },
    },
  },
  dateTime: {
    pattern: datePattern,
  },
  block: {
    additionalProperties: false,
    properties: {
      time: {
        type: 'number',
      },
      blocks: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
            data: {
              type: 'object',
              properties: {
                text: {
                  type: 'string',
                },
              },
              additionalProperties: true,
            },
            type: {
              type: 'string',
            },
          },
        },
      },
      version: {
        type: 'string',
      },
    },
  },
});

/**
 * Parsing properties values to new input type, ex. text have 'field' and 'field2',
 * but block has only 'field2' -> this func will remove 'field' from new property,
 * but will leave 'field2' value.
 *
 * Due to every input type contains label, help text and unique, we are saving these values.
 * If input cannot cotrol unique value, this func sets it to false.
 *
 * @param {object} parsedProperty
 * @param {string} inputType
 */
const parseProperties = (parsedProperty, inputType) => {
  Object.keys(parsedProperty.config).forEach((configKey) => {
    if (['label', 'helpText'].indexOf(configKey) < 0) {
      if (!PROPERTIES_FIELDS[inputType].includes(configKey)) {
        if (
          configKey === 'optionsWithLabels' &&
          (inputType === 'radio' ||
            (inputType === 'select' &&
              !parsedProperty.config.useOptionsWithLabels))
        ) {
          parsedProperty.config.options =
            parsedProperty.config.optionsWithLabels.map(
              (option) => option.value,
            );
          delete parsedProperty.config.optionsWithLabels;
        }

        if (configKey === 'unique') parsedProperty.config[configKey] = false;
        else delete parsedProperty.config[configKey];
      }
    }
  });
};

const handleDateSchemaParse = (parsedProperty) => {
  if (!moment(parsedProperty.schema.default).isValid()) {
    delete parsedProperty.schema.default;
    return;
  }
  const date = moment(parsedProperty.schema.default);

  if (moment(date).startOf('day').isSame(date, 'second')) {
    parsedProperty.schema.default = date.format('yyyy-MM-DD');
  } else {
    parsedProperty.schema.default = date.format('yyyy-MM-DDTHH:mm');
    parsedProperty.config.showTime = true;
  }
};

/**
 * Parsing schema values to new input type, ex. text have 'field' and 'field2',
 * but block has only 'field2' -> this func will remove 'field' from new property,
 * but will leave 'field2' value.
 *
 * Default value is special case. Every input contains default value, but it cannot
 * be always parsed to new value. If typo of default value is different than schema type,
 * we are removing default value.
 *
 * @param {object} parsedProperty
 * @param {string} inputType
 * @param {string} schemaType
 */
const parseSchema = (parsedProperty, inputType, schemaType) => {
  Object.keys(parsedProperty.schema).forEach((schemaKey) => {
    if (
      /**
       * Actual required property for the ctd is not in the schema,
       * so if there is required it is a leftover from object input types.
       */
      ['pattern', 'required'].indexOf(schemaKey) > -1 ||
      !PROPERTIES_FIELDS[inputType].includes(schemaKey)
    ) {
      delete parsedProperty.schema[schemaKey];
    } else if (schemaKey === 'default') {
      const defaultType = Array.isArray(parsedProperty.schema.default)
        ? 'array'
        : typeof parsedProperty.schema.default;
      if (defaultType === 'object' || defaultType !== schemaType) {
        delete parsedProperty.schema.default;
      } else if (inputType === 'dateTime') {
        handleDateSchemaParse(parsedProperty);
      } else if (
        inputType === 'datasource' &&
        parsedProperty.config?.validation?.relationContenttype
      ) {
        parsedProperty.schema.default = parsedProperty.schema.default.filter(
          (value) => {
            const splited = value.dataUrl.split('/');
            const relationType = splited[splited.length - 2];
            return (
              relationType ===
              parsedProperty.config.validation.relationContenttype
            );
          },
        );
      }
    }
  });
};

/**
 * Adds special min value equal 1 to schema if field is required,
 * ex. text will gain minLenght, but block will gain minItems inside block properties
 * @param {string} inputType
 * @param {object} parsedProperty
 */
const parseRequired = (inputType, parsedProperty) => {
  const minProperty = MIN_PROPERTY[inputType];
  if (!minProperty?.key) return;
  if (inputType === 'block') {
    parsedProperty.schema.properties.blocks.minItems = 1;
  } else {
    parsedProperty.schema[minProperty.key] = minProperty.value || 1;
  }
};

/**
 * Parses validation field from datasource when changing from media to relation and the other way
 * @param {bool} isMedia
 * @param {object} parsedProperty
 */
const parseDatasourceConfig = (isMedia, parsedProperty) => {
  if (!parsedProperty.config.validation) {
    parsedProperty.config.validation = {
      relationContenttype: isMedia ? '_media' : '',
    };
  } else {
    parsedProperty.config.validation.relationContenttype = isMedia
      ? '_media'
      : '';
  }
};

/**
 * Parsing one property to another
 * @param {string} inputType
 * @param {object} newProperty
 * @returns {object} parsedProperty
 */
export const parseInputType = (inputType, newProperty) => {
  const isMedia = inputType === 'media' ? true : false;
  const finalInputType = isMedia ? 'datasource' : inputType;

  const parsedProperty = JSON.parse(JSON.stringify(newProperty));

  if (finalInputType === 'datasource') {
    parseDatasourceConfig(isMedia, parsedProperty);
  }

  parseProperties(parsedProperty, finalInputType);
  parseSchema(parsedProperty, finalInputType, TYPES[finalInputType]);

  parsedProperty.schema.type = TYPES[finalInputType];
  parsedProperty.config.inputType = finalInputType;

  if (DEFAULT_SCHEMA[finalInputType]) {
    parsedProperty.schema = {
      ...parsedProperty.schema,
      ...JSON.parse(JSON.stringify(DEFAULT_SCHEMA[finalInputType])),
    };
  }

  if (
    ['select', 'radio'].indexOf(inputType) > -1 &&
    !parsedProperty.config.options
  ) {
    parsedProperty.config.options = [];
  }

  if (inputType === 'select' && !parsedProperty.config.useOptionsWithLabels) {
    parsedProperty.config.useOptionsWithLabels = false;
  }

  if (inputType === 'object') {
    parsedProperty.config = {
      ...parsedProperty.config,
      items: { propertiesConfig: {}, order: [] },
    };
  }

  if (parsedProperty.required) {
    parseRequired(finalInputType, parsedProperty);
  }

  return parsedProperty;
};
